import { ComponentProps, ReactNode, useState } from "react";
import { css, styled, theme } from "src/style/theme";
import { Pet as PetType } from "src/types";
import dogHead from "./dog_head.svg";
import catHead from "./cat_head.svg";
import { formatAge } from "src/pages/pet-birthdate";
import { VariantProps } from "@stitches/react";

export function displayPrice(price: number) {
  return (+price).toFixed(2).replace(".", ",");
}

export function displayCents(price: number) {
  return price.toFixed(2).split(".")[1];
}

const Text = styled("div", {
  fontSize: "12px",
  lineHeight: "14px",
  color: theme.colors.gray800,
});

export function isPromotedPet(idx: number, pets: PetType[]) {
  if (pets.length === 1) {
    return false;
  }
  if (pets.some((p) => !p.selectedFormula)) {
    return false;
  }
  const nonPromotedPet = pets.reduce((acc, pet) => {
    if (acc.selectedFormula!.totalPrice < pet.selectedFormula!.totalPrice) {
      return pet;
    }
    return acc;
  });

  return nonPromotedPet.idx !== idx;
}

const SelectItem = styled("div", {
  padding: 5,
  "&:hover": {
    backgroundColor: "#C3D7F0",
  },
});

const rowWrapper = css({
  display: "flex",
  overflowX: "visible",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: 20,
});

const row = css({
  paddingLeft: "calc(50vw - 50%)",
  paddingBottom: 10,
  display: "flex",
  overflowY: "auto",
  width: "100vw",
  flex: "none",
  $$gap: "16px",
  marginBottom: "calc(-1 * $$gap)",
  "& > *": {
    marginRight: "$$gap",
  },

  "@bp1": {
    flexWrap: "wrap",
    overflowY: "initial",
    width: "calc(100% + $$gap)",
    justifyContent: "center",
    padding: 0,
    marginRight: "calc(-1 * $$gap)",
  },

  "&::after": {
    content: "",
    display: "block",
    flex: "none",
    marginLeft: "calc(-1 * $$gap)",
    width: "calc(100vw - 100%)",

    "@bp1": {
      content: "none",
    },
  },
});

export function PetRow({ children }: { children: ReactNode }) {
  return (
    <div className={rowWrapper()}>
      <div className={row()}>{children}</div>
    </div>
  );
}

const addPet = css({
  cursor: "pointer",
  width: 214,
  minHeight: 150,
  flex: "none",
  background: theme.colors.blueBackGround,
  color: "#144283",
  border: "1px dashed #C3D7F0",
  borderRadius: 8,
  fontSize: 14,
  lineHeight: "22px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 50,
  // Hack shrink space between pet cards
  marginBottom: 0,
  variants: {
    variant: {
      tribe: {
        width: 180,
      },
    },
  },
});

export function AddPet({
  onClick,
  variant,
}: Pick<ComponentProps<"button">, "onClick"> & VariantProps<typeof addPet>) {
  return (
    <button type="button" onClick={onClick} className={addPet({ variant })}>
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          width: 40,
          height: 40,

          /* Blue/Bright */
          background: "#DBEBFF",
          borderRadius: 24,

          /* Inside Auto Layout */
          flex: "none",
          margin: "16px 0px",
        }}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.333 9.16668H11.333V4.16668C11.333 3.94566 11.2452 3.7337 11.0889 3.57742C10.9326 3.42114 10.7207 3.33334 10.4997 3.33334C10.2787 3.33334 10.0667 3.42114 9.91042 3.57742C9.75414 3.7337 9.66634 3.94566 9.66634 4.16668V9.16668H4.66634C4.44533 9.16668 4.23337 9.25447 4.07709 9.41075C3.92081 9.56703 3.83301 9.779 3.83301 10C3.83301 10.221 3.92081 10.433 4.07709 10.5893C4.23337 10.7455 4.44533 10.8333 4.66634 10.8333H9.66634V15.8333C9.66634 16.0544 9.75414 16.2663 9.91042 16.4226C10.0667 16.5789 10.2787 16.6667 10.4997 16.6667C10.7207 16.6667 10.9326 16.5789 11.0889 16.4226C11.2452 16.2663 11.333 16.0544 11.333 15.8333V10.8333H16.333C16.554 10.8333 16.766 10.7455 16.9223 10.5893C17.0785 10.433 17.1663 10.221 17.1663 10C17.1663 9.779 17.0785 9.56703 16.9223 9.41075C16.766 9.25447 16.554 9.16668 16.333 9.16668Z"
            fill="#144283"
          />
        </svg>
      </span>
      <span>Ajouter un animal</span>
    </button>
  );
}

export function formatLabel(label: string | undefined) {
  if (!label) {
    return null;
  }
  if (label.indexOf(" (") > 0) {
    return label.slice(0, label.indexOf(" ("));
  }
  return label;
}

const petClass = css({
  flex: "none",
  width: 214,
  border: "1px solid #C3D7F0",
  borderRadius: 12,
  position: "relative",
  zIndex: 0,
  marginTop: 50,
  // Hack shrink space between pet cards
  marginBottom: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  variants: {
    variant: {
      tribe: {
        width: 180,
      },
    },
  },
});

export function Pet({
  pet,
  editPet,
  deletePet,
  children,
  variant,
}: {
  pet: PetType;
  editPet: () => void;
  deletePet: () => void;
  children?: React.ReactNode;
} & VariantProps<typeof petClass>) {
  const [showAction, setShowAction] = useState<boolean>(false);

  if (!pet.breed) {
    // This pet is invalid at this time ?
    return null;
  }

  return (
    <div className={petClass({ variant })}>
      <div style={{ padding: "16px", paddingBottom: "0px" }}>
        <div
          style={{
            position: "absolute",
            top: -40,
            left: "50%",
            transform: "translateX(-50%)",
            width: 80,
            zIndex: -1,
          }}
        >
          <span
            style={{
              display: "inline-block",
              backgroundColor: "white",
              borderRadius: "50%",
              width: "100%",
            }}
          >
            <img
              style={{ width: "100%" }}
              src={pet.type === "dog" ? dogHead : catHead}
              alt=""
            />
          </span>
        </div>
        <div style={{ display: " flex", justifyContent: "flex-end" }}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setShowAction(!showAction)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="gray"
              viewBox="0 0 24 24"
            >
              <path d="M12 18c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z" />
            </svg>
            {showAction && (
              <div
                style={{
                  position: "absolute",
                  transform: "translateX(-80%)",
                  backgroundColor: "white",
                  border: "1px solid #C3D7F0",
                  borderRadius: 5,
                }}
              >
                <SelectItem onClick={editPet}>Éditer</SelectItem>
                <SelectItem onClick={deletePet}>Supprimer</SelectItem>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                fontFamily: "Roslindale",
                textAlign: "center",
                fontSize: 18,
                margin: "10px 0px 0px 0px",
                lineHeight: "28px",
              }}
            >
              {pet.name}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 10,
                color: "#757575",
                lineHeight: "22px",
              }}
            >
              <div
                style={{
                  marginBottom: "10px",
                  textAlign: "center",
                  fontSize: 14,
                }}
              >
                {pet.type === "dog" ? "Chien " : "Chat "}
                {pet.sex === "male" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                    fill="#757575"
                  >
                    <path d="M16 2v2h3.586l-3.972 3.972c-1.54-1.231-3.489-1.972-5.614-1.972-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9c0-2.125-.741-4.074-1.972-5.614l3.972-3.972v3.586h2v-7h-7zm-6 20c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                    fill="#757575"
                  >
                    <path d="M21 9c0-4.97-4.03-9-9-9s-9 4.03-9 9c0 4.632 3.501 8.443 8 8.941v2.059h-3v2h3v2h2v-2h3v-2h-3v-2.059c4.499-.498 8-4.309 8-8.941zm-16 0c0-3.86 3.14-7 7-7s7 3.14 7 7-3.14 7-7 7-7-3.14-7-7z" />
                  </svg>
                )}{" "}
                • {formatAge(pet.birthdate!)}
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "15px 8px",
          background: "rgba(197, 205, 252, 0.12)",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <svg
            width="25"
            height="25"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z"
              fill="#4E68F6"
            />
          </svg>
          <Text>Fonds d'urgence de 2000€/an</Text>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <svg
              width="25"
              height="25"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z"
                fill="#4E68F6"
              />
            </svg>
          </div>
          <Text>Vétérinaires en ligne en illimité 24h/24</Text>
        </div>
      </div>
    </div>
  );
}
