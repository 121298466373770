import { Form, Formik } from "formik";
import { SubmitButton } from "src/components/submit-button";
import { DalmaText } from "src/components/dalma-text";
import { Select } from "src/components/input";
import { differenceInCalendarMonths } from "date-fns";
import { Container } from "src/components/container";
import { Pet } from "src/types";
import { Stack } from "src/components/stack";
import { DalmaInfo } from "src/components/dalma-info";
import { Location } from "src/utils/url-builder";

type BirthDate = {
  month: string;
  year: string;
};

export function getMonthsSince(birthdate: string) {
  return differenceInCalendarMonths(new Date(), new Date(birthdate));
}

export function formatAge(birthdate: string) {
  if (!birthdate) {
    return null;
  }
  const age = getMonthsSince(birthdate);
  const year = Math.floor(age / 12);
  const month = age % 12;
  const output = [];
  if (year > 0) {
    output.push(`${year} an${year === 1 ? "" : "s"}`);
  }
  if (month > 0) {
    output.push(`${month} mois`);
  }
  return output.join(" et ");
}

function getPetAge(values: BirthDate) {
  return formatAge(`${values.year}-${values.month}-01`);
}

function getMonth(value: Pet["birthdate"]) {
  if (!value) {
    return "";
  }

  return (new Date(value).getMonth() + 1).toString().padStart(2, "0");
}

function getYear(value: Pet["birthdate"]) {
  if (!value) {
    return "";
  }
  return new Date(value).getFullYear().toString();
}

type PetBirthdateProps = {
  petIdx: number;
  nextStep: (birthdate: Pet["birthdate"]) => Promise<void>;
  backLink: Location;
} & Pick<Pet, "type" | "name" | "birthdate" | "sex">;

const validate = (values: BirthDate) => {
  const errors = {} as BirthDate;

  const petMonth = Number.parseInt(values.month, 10);
  const petYear = Number.parseInt(values.year, 10);
  const age = differenceInCalendarMonths(
    new Date(),
    new Date(`${petYear}-${petMonth}`)
  );

  if (!values.month) {
    errors.month = "Mois manquant";
  }
  if (!values.year || values.year.toString().length < 4) {
    errors.year = "Année manquante";
  }

  if (age < 2) {
    errors.year = "too young";
  } else if (age >= 12 * 12) {
    errors.year = "too old";
  }
  return errors;
};

export function PetBirthdate({
  petIdx,
  type,
  name,
  nextStep,
  birthdate,
  backLink,
  sex,
}: PetBirthdateProps) {
  const currentYear = new Date().getFullYear();

  return (
    <Container backLink={backLink} stepPercentage={25}>
      <Formik
        initialValues={{ month: getMonth(birthdate), year: getYear(birthdate) }}
        validateOnMount
        onSubmit={async (values) => {
          await nextStep(`${values.year}-${values.month}-01`);
        }}
        validate={validate}
      >
        {({ values, isValid, isSubmitting, errors }) => {
          const ageInMonths = getMonthsSince(
            `${values.year}-${values.month}-01`
          );
          return (
            <Form>
              <Stack direction="column">
                <DalmaText image>
                  {`${petIdx === 0 ? "Et q" : "Q"}uand est né${
                    sex === "male" ? "" : "e"
                  } ${name} ?`}
                </DalmaText>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "47.5%" }}>
                    <Select label="Mois" name="month">
                      {new Array(12).fill(0).map((_, i) => (
                        <option
                          key={i}
                          value={(i + 1).toString().padStart(2, "0")}
                        >
                          {
                            [
                              "Janvier",
                              "Février",
                              "Mars",
                              "Avril",
                              "Mai",
                              "Juin",
                              "Juillet",
                              "Août",
                              "Septembre",
                              "Octobre",
                              "Novembre",
                              "Décembre",
                            ][i]
                          }
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div style={{ width: "47.5%" }}>
                    <Select label="Année" name="year">
                      {new Array(21).fill(0).map((_, i) => (
                        <option key={i} value={(currentYear - i).toString()}>
                          {currentYear - i}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>

                {isValid && values.month && values.year && (
                  <DalmaInfo>
                    <div style={{ whiteSpace: "pre-line" }}>
                      <span style={{ display: "inline-block" }}>
                        {ageInMonths < 12 ? (
                          `Ooooh, un petit ${
                            type === "cat" ? "chaton" : "chiot"
                          } de ${getPetAge(values)}, c’est si mignon !`
                        ) : ageInMonths > 48 ? (
                          <>
                            {getPetAge(values)}, en voilà{" "}
                            {sex === "female" ? "une" : "un"} qui va bien
                            s’amuser dans notre club des seniors !
                          </>
                        ) : (
                          <>
                            {getPetAge(values)},{" "}
                            {sex === "female" ? "elle" : "il"} doit être au top
                            de sa forme !
                          </>
                        )}
                      </span>{" "}
                      <span style={{ display: "inline-block" }}>
                        Sachez qu’avec dalma.blue, {name} sera{" "}
                        {sex === "female" ? "protégée" : "protégé"} à vie
                        {"\u00a0"}❤️
                      </span>
                    </div>
                  </DalmaInfo>
                )}
                {!isValid &&
                  values.month &&
                  values.year &&
                  (errors.year === "too old" ||
                    errors.year === "too young") && (
                    <div>
                      <DalmaText>
                        {" "}
                        Désolé mais nous ne pouvons pas souscrire les animaux de{" "}
                        {errors.year === "too old"
                          ? "plus de 12 ans"
                          : "moins de 2 mois"}
                      </DalmaText>
                    </div>
                  )}
                <SubmitButton
                  isValid={isValid}
                  isLoading={isSubmitting}
                  backLink={backLink}
                >
                  Suivant
                </SubmitButton>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
}
