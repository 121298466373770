import { theme, styled, css } from "src/style/theme";

type Props = {
  animated?: boolean;
  percent: number;
};

const Container = css({
  padding: "19px 0px 11px 0px",
  boxShadow: "0 2px 4px 0px rgba(20, 66, 131, 0.1)",
  marginBottom: 32,
  display: "flex",
  justifyContent: "center",
  "@bp1": {
    top: 66,
    backgroundColor: "#FFFFFF",
  },
});

const ProgressDiv = styled("div", {
  position: "relative",
  backgroundColor: "#E1E5FD",
  borderRadius: 100,
  width: "66.66%",
  height: "4px",
});

const Progress = styled("div", {
  backgroundColor: theme.colors.night,
  height: "4px",
  borderRadius: 100,

  variants: {
    animated: {
      true: {
        transition: "1s ease",
        transitionDelay: "0.5s",
      },
    },
  },
});

const StepBubbleText = styled("p", {
  margin: 0,
  fontSize: 15,
  fontWeight: "bold",
});

const StepBubble = (completed: boolean) =>
  css({
    backgroundColor: completed ? "#030C6C" : "#E1E5FD",
    color: completed ? "#FFFFFF" : "#030C6C",
    fontWeight: completed ? "bold" : "normal",
    position: "absolute",
    borderRadius: "100%",
    top: -14, // (Progressbar height(4) / 2) + (24 / 2)
    left: -12,
    width: 24,
    height: 24,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  });

const StepTitle = (completed: boolean) =>
  css({
    fontWeight: completed ? "bold" : "normal",
    margin: 0,
    lineHeight: "16px",
    fontSize: "10px",
    color: "#030C6C",
  });

export const ProgressBar = ({ animated, percent }: Props) => {
  return (
    <div className={Container()}>
      <div style={{ maxWidth: "400px" }}>
        <table
          style={{
            width: "100%",
            textAlign: "center",
            tableLayout: "fixed",
            marginTop: 10,
          }}
          cellSpacing={0}
        >
          <tbody>
            <tr>
              <td colSpan={3}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "20px",
                  }}
                >
                  <ProgressDiv id="progressBar">
                    <Progress
                      animated={animated}
                      style={{ width: `${percent <= 100 ? percent : 1}%` }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        <div className={StepBubble(percent >= 0)()}>
                          <StepBubbleText>1</StepBubbleText>
                        </div>
                      </div>
                      <div style={{ position: "relative" }}>
                        <div className={StepBubble(percent >= 50)()}>
                          <StepBubbleText>2</StepBubbleText>
                        </div>
                      </div>
                      <div style={{ position: "relative" }}>
                        <div className={StepBubble(percent >= 100)()}>
                          <StepBubbleText>3</StepBubbleText>
                        </div>
                      </div>
                    </div>
                  </ProgressDiv>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p className={StepTitle(percent >= 0)()}>Mes animaux</p>
              </td>
              <td>
                <p className={StepTitle(percent >= 50)()}>Mon profil</p>
              </td>
              <td>
                <p className={StepTitle(percent >= 100)()}>Paiement</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
