import { styled } from "@stitches/react";
import { ProgressBar } from "./progress-bar";
import { Header } from "./header";
import { Location } from "src/utils/url-builder";

type Props = {
  children: React.ReactNode;
  backLink?: Location;
  hideLink?: boolean;
  hideLogo?: boolean;
  stepPercentage?: number;
  largePadding?: boolean;
};

export const Content = styled("div", {
  width: "90vw",
  maxWidth: 600,
  margin: "auto",
  variants: {
    largePadding: {
      true: {
        paddingBottom: 300,
      },
    },
    withPadding: {
      true: {
        paddingBottom: 200,
      },
    },
  },
});

export const Container = ({
  children,
  backLink,
  hideLink,
  hideLogo,
  stepPercentage,
  largePadding = false,
}: Props) => {
  return (
    <div>
      <Header backLink={backLink} hideLink={hideLink} hideLogo={hideLogo} />
      {stepPercentage != null && <ProgressBar percent={stepPercentage} />}
      <Content withPadding largePadding={largePadding}>
        {children}
      </Content>
    </div>
  );
};
