export const CAT_BREEDS = [
  {
    key: "abyssin",
    label: "Abyssin",
  },
  {
    key: "american_bobtail",
    label: "American bobtail",
  },
  {
    key: "american_bobtail_a_poil_court",
    label: "American Bobtail à poil court",
  },
  {
    key: "american_bobtail_a_poil_long",
    label: "American Bobtail à poil long",
  },
  {
    key: "american_curl",
    label: "American curl",
  },
  {
    key: "american_curl_a_poil_court",
    label: "American Curl à poil court",
  },
  {
    key: "american_curl_a_poil_long",
    label: "American Curl à poil long",
  },
  {
    key: "american_shorthair",
    label: "American Shorthair",
  },
  {
    key: "american_whitehair",
    label: "American Whitehair",
  },
  {
    key: "american_wirehair",
    label: "American Wirehair",
  },
  {
    key: "angora_turc",
    label: "Angora turc",
  },
  {
    key: "asian",
    label: "Asian",
  },
  {
    key: "asian_longhair",
    label: "Asian Longhair",
  },
  {
    key: "asian_shorthair",
    label: "Asian Shorthair",
  },
  {
    key: "australian_mist",
    label: "Australian Mist",
  },
  {
    key: "balinais",
    label: "Balinais",
  },
  {
    key: "bengal",
    label: "Bengal",
  },
  {
    key: "birman",
    label: "Birman",
  },
  {
    key: "bleu_russe",
    label: "Bleu russe",
  },
  {
    key: "bobtail_des_kouriles",
    label: "Bobtail des Kouriles",
  },
  {
    key: "bombay",
    label: "Bombay",
  },
  {
    key: "brazilian_shorthair",
    label: "Brazilian shorthair",
  },
  {
    key: "british_longhair",
    label: "British Longhair",
  },
  {
    key: "british_shorthair",
    label: "British Shorthair",
  },
  {
    key: "burmese",
    label: "Burmese",
  },
  {
    key: "burmese_americain",
    label: "Burmese Américain",
  },
  {
    key: "burmese_anglais",
    label: "Burmese anglais",
  },
  {
    key: "burmilla",
    label: "Burmilla",
  },
  {
    key: "california_spangled",
    label: "California Spangled",
  },
  {
    key: "california_toyger",
    label: "California Toyger",
  },
  {
    key: "californian_rex",
    label: "Californian Rex",
  },
  {
    key: "caracat",
    label: "Caracat",
  },
  {
    key: "ceylan",
    label: "Ceylan",
  },
  {
    key: "chartreux",
    label: "Chartreux",
  },
  {
    key: "chat_caniche",
    label: "Chat Caniche",
  },
  {
    key: "chat_chinois",
    label: "Chat chinois",
  },
  {
    key: "chat_de_cafrerie",
    label: "Chat de Cafrerie",
  },
  {
    key: "chat_de_pallas",
    label: "Chat de Pallas",
  },
  {
    key: "chat_des_forets_norvegiennes",
    label: "Chat des Forêts Norvégiennes",
  },
  {
    key: "chat_du_sri-lanka",
    label: "Chat du Sri-Lanka",
  },
  {
    key: "chat_haret",
    label: "Chat Haret",
  },
  {
    key: "chat_jaune",
    label: "Chat Jaune",
  },
  {
    key: "chat_nu",
    label: "Chat nu",
  },
  {
    key: "chat_rubigineux",
    label: "Chat rubigineux",
  },
  {
    key: "chat_sauvage_d_europe",
    label: "Chat Sauvage d'Europe",
  },
  {
    key: "chat_tigre",
    label: "Chat Tigre",
  },
  {
    key: "chausie",
    label: "Chausie",
  },
  {
    key: "colourpoint_shorthair",
    label: "Colourpoint Shorthair",
  },
  {
    key: "cornish_rex",
    label: "Cornish rex",
  },
  {
    key: "cymric",
    label: "Cymric",
  },
  {
    key: "devon_rex",
    label: "Devon rex",
  },
  {
    key: "donskoy",
    label: "Donskoy",
  },
  {
    key: "european_shorthair",
    label: "European shorthair",
  },
  {
    key: "europeen",
    label: "Européen",
  },
  {
    key: "exotic_shorthair",
    label: "Exotic shorthair",
  },
  {
    key: "foldex",
    label: "Foldex",
  },
  {
    key: "german_rex",
    label: "German rex",
  },
  {
    key: "havana",
    label: "Havana",
  },
  {
    key: "havana_brown",
    label: "Havana brown",
  },
  {
    key: "highland",
    label: "Highland",
  },
  {
    key: "highland_fold",
    label: "Highland Fold",
  },
  {
    key: "highland_straight",
    label: "Highland Straight",
  },
  {
    key: "highlander",
    label: "Highlander",
  },
  {
    key: "highlander_lynx",
    label: "Highlander Lynx",
  },
  {
    key: "himalayen",
    label: "Himalayen",
  },
  {
    key: "japanese_bobtail",
    label: "Japanese bobtail",
  },
  {
    key: "japanese_bobtail_a_poil_court",
    label: "Japanese Bobtail à poil court",
  },
  {
    key: "japanese_bobtail_a_poil_long",
    label: "Japanese Bobtail à poil long",
  },
  {
    key: "javanais",
    label: "Javanais",
  },
  {
    key: "kanaani",
    label: "Kanaani",
  },
  {
    key: "karelian_bobtail",
    label: "Karelian Bobtail",
  },
  {
    key: "khao_manee",
    label: "Khao Manee",
  },
  {
    key: "korat",
    label: "Korat",
  },
  {
    key: "kurilian_bobtail",
    label: "Kurilian bobtail",
  },
  {
    key: "kurilian_bobtail_a_poil_court",
    label: "Kurilian Bobtail à poil court",
  },
  {
    key: "kurilian_bobtail_a_poil_long",
    label: "Kurilian Bobtail à poil long",
  },
  {
    key: "laperm",
    label: "Laperm",
  },
  {
    key: "laperm_a_poil_court",
    label: "Laperm à poil court",
  },
  {
    key: "laperm_a_poil_long",
    label: "Laperm à poil long",
  },
  {
    key: "lykoi",
    label: "Lykoï",
  },
  {
    key: "lynx_domestique",
    label: "Lynx Domestique",
  },
  {
    key: "maine_coon",
    label: "Maine coon",
  },
  {
    key: "malayen",
    label: "Malayen",
  },
  {
    key: "mandarin",
    label: "Mandarin",
  },
  {
    key: "manul",
    label: "Manul",
  },
  {
    key: "manx",
    label: "Manx",
  },
  {
    key: "margay",
    label: "Margay",
  },
  {
    key: "mau_egyptien",
    label: "Mau egyptien",
  },
  {
    key: "mekong_bobtail",
    label: "Mekong Bobtail",
  },
  {
    key: "minskin",
    label: "Minskin",
  },
  {
    key: "munchkin",
    label: "Munchkin",
  },
  {
    key: "munchkin_a_poil_court",
    label: "Munchkin à poil court",
  },
  {
    key: "munchkin_a_poil_long",
    label: "Munchkin à poil long",
  },
  {
    key: "nebelung",
    label: "Nebelung",
  },
  {
    key: "norvegien",
    label: "Norvegien",
  },
  {
    key: "ocicat",
    label: "Ocicat",
  },
  {
    key: "ojos_azules",
    label: "Ojos Azules",
  },
  {
    key: "oriental",
    label: "Oriental",
  },
  {
    key: "persan",
    label: "Persan",
  },
  {
    key: "peterbald",
    label: "Peterbald",
  },
  {
    key: "pixie_bob",
    label: "Pixie bob",
  },
  {
    key: "pixiebob_a_poil_court",
    label: "Pixiebob à poil court",
  },
  {
    key: "pixiebob_a_poil_long",
    label: "Pixiebob à poil long",
  },
  {
    key: "poodle_cat_(pudelkatze)",
    label: "Poodle Cat (Pudelkatze)",
  },
  {
    key: "ragamuffin",
    label: "Ragamuffin",
  },
  {
    key: "ragdoll",
    label: "Ragdoll",
  },
  {
    key: "rex_devon",
    label: "Rex Devon",
  },
  {
    key: "russe",
    label: "Russe",
  },
  {
    key: "russian_bobtail",
    label: "Russian Bobtail",
  },
  {
    key: "sacre_de_birmanie",
    label: "Sacre de birmanie",
  },
  {
    key: "safari",
    label: "Safari",
  },
  {
    key: "savannah",
    label: "Savannah",
  },
  {
    key: "scottish_fold",
    label: "Scottish Fold",
  },
  {
    key: "scottish_fold_long_hair",
    label: "Scottish Fold Long Hair",
  },
  {
    key: "scottish_straight",
    label: "Scottish Straight",
  },
  {
    key: "selkirk_rex_a_poil_court",
    label: "Selkirk Rex à poil court",
  },
  {
    key: "selkirk_rex_a_poil_long",
    label: "Selkirk Rex à poil long",
  },
  {
    key: "selkirk_straight_a_poil_court",
    label: "Selkirk Straight à poil court",
  },
  {
    key: "selkirk_straight_a_poil_long",
    label: "Selkirk Straight à poil long",
  },
  {
    key: "selkrirk_rex",
    label: "Selkrirk Rex",
  },
  {
    key: "serengeti",
    label: "Serengeti",
  },
  {
    key: "seychellois",
    label: "Seychellois",
  },
  {
    key: "siamois",
    label: "Siamois",
  },
  {
    key: "siberien",
    label: "Siberien",
  },
  {
    key: "singapura",
    label: "Singapura",
  },
  {
    key: "skogkatt",
    label: "Skogkatt",
  },
  {
    key: "skookum",
    label: "Skookum",
  },
  {
    key: "snowshoe",
    label: "Snowshoe",
  },
  {
    key: "sokoke",
    label: "Sokoke",
  },
  {
    key: "somali",
    label: "Somali",
  },
  {
    key: "sphynx",
    label: "Sphynx",
  },
  {
    key: "spotted_mist",
    label: "Spotted Mist",
  },
  {
    key: "thai",
    label: "Thai",
  },
  {
    key: "tiffany",
    label: "Tiffany",
  },
  {
    key: "tiffany_americain",
    label: "Tiffany Américain",
  },
  {
    key: "tonkinois",
    label: "Tonkinois",
  },
  {
    key: "tonkinois_a_poil_court",
    label: "Tonkinois à poil court",
  },
  {
    key: "tonkinois_a_poil_long",
    label: "Tonkinois à poil long",
  },
  {
    key: "toyger",
    label: "Toyger",
  },
  {
    key: "turc_du_lac_de_van",
    label: "Turc du lac de van",
  },
  {
    key: "type_oriental",
    label: "Type oriental",
  },
  {
    key: "ural_rex",
    label: "Ural rex",
  },
  {
    key: "york_chocolate",
    label: "York Chocolate",
  },
  {
    key: "american_shorthair_wirehair",
    label: "American shorthair et wirehair",
  },
  {
    key: "balinais_mandarin",
    label: "Balinais et Mandarin",
  },
  {
    key: "bombay_burmese_americain",
    label: "Bombay et Burmese americain",
  },
  {
    key: "british",
    label: "British",
  },
  {
    key: "burmilla_asian",
    label: "Burmilla et Asian",
  },
  {
    key: "manx_cymric",
    label: "Manx et Cymric",
  },
  {
    key: "russe_nebelung",
    label: "Russe et Nebelung",
  },
  {
    key: "scottish_highland",
    label: "Scottish et Highland",
  },
  {
    key: "selkirk_rex",
    label: "Selkirk rex",
  },
  {
    key: "york_chocolat",
    label: "York chocolat",
  },
];

export const DOG_BREEDS = [
  {
    key: "affenpinscher",
    label: "Affenpinscher",
  },
  {
    key: "african_lion_hound",
    label: "African Lion Hound",
  },
  {
    key: "aidi",
    label: "Aïdi",
  },
  {
    key: "airedale",
    label: "Airedale",
  },
  {
    key: "akita_americain",
    label: "Akita americain (American akita)",
  },
  {
    key: "akita_inu",
    label: "Akita Inu",
  },
  {
    key: "alano_espagnol",
    label: "Alano espagnol",
  },
  {
    key: "alaskan_klee_kai",
    label: "Alaskan Klee Kai",
  },
  {
    key: "alaskan_malamute",
    label: "Alaskan Malamute",
  },
  {
    key: "altdeutsche_schaferhunde",
    label: "Altdeutsche Schaferhunde",
  },
  {
    key: "americain_foxhound",
    label: "Américain Foxhound",
  },
  {
    key: "americain_staffordshire_terrier",
    label: "Americain Staffordshire Terrier",
  },
  {
    key: "american_bully",
    label: "American Bully",
  },
  {
    key: "american_hairless_terrier",
    label: "American hairless terrier",
  },
  {
    key: "american_shepherd",
    label: "American shepherd",
  },
  {
    key: "american-staffordshire-terrier",
    label: "American-Staffordshire-Terrier",
  },
  {
    key: "american-water-spaniel",
    label: "American-Water-Spaniel",
  },
  {
    key: "ancien_chien_d_arret_danois",
    label: "Ancien chien d'arret Danois",
  },
  {
    key: "anglo-français_de_petite_venerie",
    label: "Anglo-Français de Petite Vènerie",
  },
  {
    key: "anglo-français_tricolore",
    label: "Anglo-Français Tricolore",
  },
  {
    key: "ariegeois",
    label: "Ariegeois",
  },
  {
    key: "aussie",
    label: "Aussie",
  },
  {
    key: "aussie_bulldog",
    label: "Aussie Bulldog",
  },
  {
    key: "australian_cobberdog",
    label: "Australian cobberdog",
  },
  {
    key: "australian_kelpie",
    label: "Australian Kelpie",
  },
  {
    key: "australian_silky_terrier",
    label: "Australian Silky Terrier",
  },
  {
    key: "azawakh",
    label: "Azawakh",
  },
  {
    key: "bangkaew_de_thailande",
    label: "Bangkaew de thailande",
  },
  {
    key: "barak",
    label: "Barak",
  },
  {
    key: "barbet",
    label: "Barbet",
  },
  {
    key: "barbu_tcheque",
    label: "Barbu tcheque (Cesky fousek)",
  },
  {
    key: "barzoi",
    label: "Barzoï (Levrier russe / Russkaya psovaya borzaya)",
  },
  {
    key: "bas-rouge",
    label: "Bas-rouge",
  },
  {
    key: "basset_artesien-normand",
    label: "Basset Artésien-Normand",
  },
  {
    key: "basset_bleu_de_gascogne",
    label: "Basset Bleu de Gascogne",
  },
  {
    key: "basset_de_westphalie",
    label: "Basset de Westphalie",
  },
  {
    key: "basset_des_alpes",
    label: "Basset des Alpes",
  },
  {
    key: "basset_fauve_de_bretagne",
    label: "Basset Fauve de Bretagne",
  },
  {
    key: "basset_hound",
    label: "Basset hound",
  },
  {
    key: "basset_suedois",
    label: "Basset suedois (Drever)",
  },
  {
    key: "beagle",
    label: "Beagle",
  },
  {
    key: "beagle-harrier",
    label: "Beagle-Harrier",
  },
  {
    key: "bearded_collie",
    label: "Bearded collie (Collie barbu)",
  },
  {
    key: "beauceron",
    label: "Beauceron",
  },
  {
    key: "bedlington-terrier",
    label: "Bedlington-Terrier",
  },
  {
    key: "bergamasque",
    label: "Bergamasque",
  },
  {
    key: "berger_allemand",
    label: "Berger allemand (Ba / Deutscher schäferhund)",
  },
  {
    key: "berger_americain",
    label: "Berger Américain",
  },
  {
    key: "berger_americain_miniature",
    label: "Berger americain miniature (American sheperd miniature)",
  },
  {
    key: "berger_australien",
    label: "Berger australien (Australian shepherd)",
  },
  {
    key: "berger_australien_miniature",
    label: "Berger australien miniature",
  },
  {
    key: "berger_belge",
    label: "Berger Belge",
  },
  {
    key: "berger_belge_groenendal",
    label: "Berger Belge Groenendal",
  },
  {
    key: "berger_belge_laekenois",
    label: "Berger belge laekenois",
  },
  {
    key: "berger_belge_malinois",
    label: "Berger Belge Malinois",
  },
  {
    key: "berger_belge_tervueren",
    label: "Berger Belge Tervueren",
  },
  {
    key: "berger_bergamasque",
    label:
      "Berger bergamasque (Berger de bergame / Cane da pastore bergamasco)",
  },
  {
    key: "berger_blanc_suisse",
    label:
      "Berger blanc suisse (Berger blanc suisse / weisser schweizer schaferhund)",
  },
  {
    key: "berger_canadien",
    label: "Berger Canadien",
  },
  {
    key: "berger_catalan",
    label: "Berger Catalan",
  },
  {
    key: "berger_creole",
    label: "Berger Créole",
  },
  {
    key: "berger_croate",
    label: "Berger Croate",
  },
  {
    key: "berger_d_alsace",
    label: "Berger d'Alsace",
  },
  {
    key: "berger_d_anatolie",
    label: "Berger d'Anatolie",
  },
  {
    key: "berger_d_asie_centrale",
    label: "Berger d'Asie Centrale",
  },
  {
    key: "berger_d_atura",
    label: "Berger d'Atura",
  },
  {
    key: "berger_d_islande",
    label: "Berger d'Islande",
  },
  {
    key: "berger_de_beauce",
    label: "Berger de Beauce",
  },
  {
    key: "berger_de_bergame",
    label: "Berger de Bergame",
  },
  {
    key: "berger_de_boheme",
    label: "Berger de Bohème",
  },
  {
    key: "berger_de_bosnie_herzegovine_et_de_croatie",
    label: "Berger de Bosnie Herzegovine et de Croatie",
  },
  {
    key: "berger_de_brie",
    label: "Berger de Brie",
  },
  {
    key: "berger_de_crau",
    label: "Berger de Crau",
  },
  {
    key: "berger_de_croatie",
    label: "Berger de Croatie",
  },
  {
    key: "berger_de_hollande",
    label: "Berger de Hollande",
  },
  {
    key: "berger_de_karst",
    label: "Berger de Karst",
  },
  {
    key: "berger_de_l_europe_du_sud-est",
    label: "Berger de l'Europe du Sud-Est",
  },
  {
    key: "berger_de_la_serra_de_aires",
    label: "Berger de la serra de aires",
  },
  {
    key: "berger_de_maremme_et_abruzzes",
    label: "Berger de Maremme et abruzzes",
  },
  {
    key: "berger_de_picardie",
    label: "Berger de Picardie",
  },
  {
    key: "berger_de_podhale",
    label: "Berger de Podhale",
  },
  {
    key: "berger_de_russie_meridionale",
    label: "Berger de russie méridionale",
  },
  {
    key: "berger_de_savoie",
    label: "Berger de Savoie",
  },
  {
    key: "berger_des_abruzzes_et_maremme",
    label: "Berger des Abruzzes et Maremme",
  },
  {
    key: "berger_des_alpes",
    label: "Berger des Alpes",
  },
  {
    key: "berger_des_pyrenees",
    label: "Berger des Pyrénées",
  },
  {
    key: "berger_des_shetland",
    label: "Berger des Shetland",
  },
  {
    key: "berger_du_bassin_de_karst",
    label: "Berger du bassin de Karst",
  },
  {
    key: "berger_du_caucase",
    label: "Berger du Caucase",
  },
  {
    key: "berger_du_karst",
    label: "Berger du karst",
  },
  {
    key: "berger_ecossais",
    label: "Berger écossais",
  },
  {
    key: "berger_espagnol_de_majorque",
    label: "Berger Espagnol de Majorque",
  },
  {
    key: "berger_finnois_de_laponie",
    label: "Berger finnois de laponie",
  },
  {
    key: "berger_hollandais",
    label: "Berger hollandais (Hollandse herdershond)",
  },
  {
    key: "berger_hongrois",
    label: "Berger Hongrois",
  },
  {
    key: "berger_islandais",
    label: "Berger Islandais",
  },
  {
    key: "berger_polonais",
    label: "Berger Polonais",
  },
  {
    key: "berger_polonais_de_plaine",
    label: "Berger polonais de plaine",
  },
  {
    key: "berger_polonais_de_podhale",
    label: "Berger polonais de Podhale",
  },
  {
    key: "berger_portugais",
    label: "Berger Portugais",
  },
  {
    key: "berger_roumain_des_carpates",
    label: "Berger Roumain des Carpates",
  },
  {
    key: "berger_yougoslave",
    label: "Berger Yougoslave",
  },
  {
    key: "bichon_a_poil_droit",
    label: "Bichon à poil droit",
  },
  {
    key: "bichon_a_poil_frise",
    label: "Bichon à poil frisé",
  },
  {
    key: "bichon_bolognais",
    label: "Bichon Bolognais",
  },
  {
    key: "bichon_havanais",
    label: "Bichon havanais (Havanais)",
  },
  {
    key: "bichon_maltais",
    label: "Bichon maltais (Maltais / Maltese)",
  },
  {
    key: "biewer_yorkshire",
    label: "Biewer Yorkshire",
  },
  {
    key: "billy",
    label: "Billy",
  },
  {
    key: "bloodhound",
    label: "Bloodhound",
  },
  {
    key: "bobtail",
    label: "Bobtail",
  },
  {
    key: "bodeguero",
    label: "Bodeguero",
  },
  {
    key: "boerbull",
    label: "Boerbull",
  },
  {
    key: "boerenfox",
    label: "Boerenfox",
  },
  {
    key: "bolonka",
    label: "Bolonka",
  },
  {
    key: "boomer",
    label: "Boomer",
  },
  {
    key: "border_collie",
    label: "Border collie",
  },
  {
    key: "border-terrier",
    label: "Border-Terrier",
  },
  {
    key: "boston-terrier",
    label: "Boston-Terrier",
  },
  {
    key: "bouledogue_americain",
    label: "Bouledogue Américain",
  },
  {
    key: "bouledogue_anglais",
    label: "Bouledogue Anglais",
  },
  {
    key: "bouledogue_français",
    label: "Bouledogue Français",
  },
  {
    key: "bouvier_australien",
    label: "Bouvier australien (Bouvier d'australie / Australian cattle dog)",
  },
  {
    key: "bouvier_australien_courte_queue",
    label:
      "Bouvier australien courte queue (Australian stumpy tail cattle dog)",
  },
  {
    key: "bouvier_bernois",
    label: "Bouvier bernois (Berner sennenhund , dürrbächler)",
  },
  {
    key: "bouvier_d_appenzell",
    label: "Bouvier d'Appenzell",
  },
  {
    key: "bouvier_de_l_entlebuch",
    label: "Bouvier de L entlebuch",
  },
  {
    key: "bouvier_de_l_alentejo",
    label: "Bouvier de l'alentejo",
  },
  {
    key: "bouvier_des_alpes",
    label: "Bouvier des Alpes",
  },
  {
    key: "bouvier_des_ardennes",
    label: "Bouvier des Ardennes",
  },
  {
    key: "bouvier_des_flandres",
    label: "Bouvier des Flandres",
  },
  {
    key: "boxer",
    label: "Boxer",
  },
  {
    key: "boxer_nain",
    label: "Boxer nain",
  },
  {
    key: "brabançon",
    label: "Brabançon",
  },
  {
    key: "brachet_allemand",
    label: "Brachet allemand (Deutsche bracke)",
  },
  {
    key: "brachet_de_styrie_a_poil_dur",
    label: "Brachet de styrie a poil dur",
  },
  {
    key: "brachet_hongrois",
    label: "Brachet Hongrois",
  },
  {
    key: "brachet_noir_et_feu",
    label: "Brachet noir et feu",
  },
  {
    key: "brachet_polonais",
    label: "Brachet polonais (Ogar polski)",
  },
  {
    key: "brachet_tyrolien",
    label:
      "Brachet tyrolien (Brandlbracke brachet feu autrichien a poil lisse / Tiroler bracke)",
  },
  {
    key: "braque_allemand",
    label: "Braque Allemand",
  },
  {
    key: "braque_allemand_a_poil_court",
    label: "Braque allemand à poil court",
  },
  {
    key: "braque_d_auvergne",
    label: "Braque d'Auvergne",
  },
  {
    key: "braque_de_burgos",
    label: "Braque de burgos",
  },
  {
    key: "braque_de_l_ariege",
    label: "Braque de l ariege",
  },
  {
    key: "braque_de_weimar",
    label: "Braque de Weimar",
  },
  {
    key: "braque_du_bourbonnais",
    label: "Braque du Bourbonnais",
  },
  {
    key: "braque_dupuy",
    label: "Braque Dupuy",
  },
  {
    key: "braque_français",
    label: "Braque Français",
  },
  {
    key: "braque_francais_type_gascogne",
    label: "Braque francais, type gascogne",
  },
  {
    key: "braque_francais_type_pyrenees",
    label: "Braque francais, type pyrenees",
  },
  {
    key: "braque_hongrois",
    label: "Braque Hongrois",
  },
  {
    key: "braque_hongrois_a_poil_court",
    label: "Braque Hongrois à poil court",
  },
  {
    key: "braque_hongrois_a_poil_dur",
    label: "Braque hongrois a poil dur",
  },
  {
    key: "braque_italien",
    label: "Braque italien (Bracco italiano)",
  },
  {
    key: "braque_saint-germain",
    label: "Braque Saint-Germain",
  },
  {
    key: "braque_slovaque_a_poil_dur",
    label: "Braque slovaque à poil dur",
  },
  {
    key: "braque_suedois",
    label: "Braque Suedois",
  },
  {
    key: "briard",
    label: "Briard",
  },
  {
    key: "briquet_d_artois",
    label: "Briquet d'Artois",
  },
  {
    key: "briquet_de_provence",
    label: "Briquet de Provence",
  },
  {
    key: "briquet_griffon_vendeen",
    label: "Briquet griffon vendeen",
  },
  {
    key: "broholmer",
    label: "Broholmer",
  },
  {
    key: "bruno_du_jura",
    label: "Bruno du Jura",
  },
  {
    key: "buhund_norvegien",
    label: "Buhund norvegien (Norsk buhund)",
  },
  {
    key: "bull_terrier",
    label: "Bull terrier",
  },
  {
    key: "bull_terrier_miniature",
    label: "Bull terrier miniature (Miniature bull terrier)",
  },
  {
    key: "bulldog_francais",
    label: "Bulldog français",
  },
  {
    key: "bulldog_anglais",
    label: "Bulldog anglais",
  },
  {
    key: "new_english_bulldog",
    label: "New English Bulldog",
  },
  {
    key: "bullmastiff",
    label: "Bullmastiff",
  },
  {
    key: "ca_de_bestiar",
    label: "Ca de bestiar",
  },
  {
    key: "ca_de_bou",
    label: "Ca de Bou",
  },
  {
    key: "cairn_terrier",
    label: "Cairn terrier",
  },
  {
    key: "cane_corso",
    label: "Cane Corso",
  },
  {
    key: "caniche",
    label: "Caniche",
  },
  {
    key: "caniche_moyen",
    label: "Caniche moyen",
  },
  {
    key: "caniche_nain",
    label: "Caniche nain",
  },
  {
    key: "caniche_toy",
    label: "Caniche toy",
  },
  {
    key: "cans_bou",
    label: "Cans Bou",
  },
  {
    key: "cao_da_serra_de_aires",
    label: "Cão da serra de Aires",
  },
  {
    key: "carlin",
    label: "Carlin (Pug)",
  },
  {
    key: "cavalier",
    label: "Cavalier",
  },
  {
    key: "cavalier_king_charles",
    label: "Cavalier king charles (Cavalier king charles spaniel)",
  },
  {
    key: "cavalier_king_charles_spaniel",
    label: "Cavalier King Charles Spaniel",
  },
  {
    key: "cesky_terrier",
    label: "Cesky Terrier",
  },
  {
    key: "charplaninatz",
    label: "Charplaninatz",
  },
  {
    key: "chien_a_loutre",
    label: "Chien a loutre",
  },
  {
    key: "chien_a_peau_nue",
    label: "Chien à Peau Nue",
  },
  {
    key: "chien_chinois",
    label: "Chien Chinois",
  },
  {
    key: "chien_chinois_a_crete",
    label: "Chien Chinois à crête",
  },
  {
    key: "chien_corse",
    label: "Chien Corse",
  },
  {
    key: "chien_courant",
    label: "Chien courant",
  },
  {
    key: "chien_courant_d_illyrie",
    label: "Chien courant d'Illyrie",
  },
  {
    key: "chien_courant_d_istrie_a_poil_dur",
    label: "Chien courant d'istrie a poil dur",
  },
  {
    key: "chien_courant_d_istrie_a_poil_ras",
    label: "Chien courant d'Istrie à poil ras",
  },
  {
    key: "chien_courant_de_bosnie",
    label: "Chien courant de Bosnie",
  },
  {
    key: "chien_courant_de_halden",
    label: "Chien courant de halden",
  },
  {
    key: "chien_courant_de_hamilton",
    label: "Chien courant de hamilton",
  },
  {
    key: "chien_courant_de_hygen",
    label: "Chien courant de hygen",
  },
  {
    key: "chien_courant_de_la_vallee_de_la_save",
    label: "Chien courant de la vallee de la save",
  },
  {
    key: "chien_courant_de_montagne_du_montenegr",
    label: "Chien courant de montagne du montenegr",
  },
  {
    key: "chien_courant_de_posavatz",
    label: "Chien courant de Posavatz",
  },
  {
    key: "chien_courant_de_schiller",
    label: "Chien courant de schiller",
  },
  {
    key: "chien_courant_de_transylvanie",
    label: "Chien courant de transylvanie",
  },
  {
    key: "chien_courant_des_balkans",
    label: "Chien courant des Balkans",
  },
  {
    key: "chien_courant_du_smaland",
    label: "Chien courant du smaland",
  },
  {
    key: "chien_courant_espagnol",
    label: "Chien courant espagnol (Sabueso español)",
  },
  {
    key: "chien_courant_finlandais",
    label: "Chien courant finlandais (Chien courant finnois / Suomenajokoira)",
  },
  {
    key: "chien_courant_grec",
    label:
      "Chien courant grec (Chien courant hellenique / Hellinikos ichnilatis)",
  },
  {
    key: "chien_courant_hongrois",
    label: "Chien courant hongrois",
  },
  {
    key: "chien_courant_italien",
    label: "Chien courant Italien",
  },
  {
    key: "chien_courant_italien_a_poil_ras",
    label: "Chien courant italien a poil ras",
  },
  {
    key: "chien_courant_norvegien",
    label: "Chien courant norvegien (Dunker)",
  },
  {
    key: "chien_courant_polonais",
    label: "Chien courant polonais (Gonzcy polski)",
  },
  {
    key: "chien_courant_portugais",
    label: "Chien courant Portugais",
  },
  {
    key: "chien_courant_serbe",
    label: "Chien courant serbe (Balkanski gonic / Serbski gonic)",
  },
  {
    key: "chien_courant_slovaque",
    label: "Chien courant slovaque (Slovensky kopov)",
  },
  {
    key: "chien_courant_slovene_de_montagne",
    label: "Chien courant Slovene de montagne",
  },
  {
    key: "chien_courant_suisse",
    label: "Chien courant suisse (Schweizer laufhund)",
  },
  {
    key: "chien_courant_tricolore_serbe",
    label:
      "Chien courant tricolore serbe (Chien courant yougoslave tricolore / Serpski trobojni gonic)",
  },
  {
    key: "chien_courant_yougoslave",
    label: "Chien courant yougoslave",
  },
  {
    key: "chien_creole",
    label: "Chien créole",
  },
  {
    key: "chien_d_arret_allemand",
    label: "Chien d'arrêt Allemand",
  },
  {
    key: "chien_d_arret_allemand_a_poil_dur",
    label: "Chien d'arrêt Allemand à poil dur",
  },
  {
    key: "chien_d_arret_allemand_a_poil_raide",
    label: "Chien d'arret Allemand a poil raide",
  },
  {
    key: "chien_d_arret_danois_ancestral",
    label: "Chien d'arrêt danois ancestral",
  },
  {
    key: "chien_d_arret_frison",
    label: "Chien d'arret Frison",
  },
  {
    key: "chien_d_arret_portugais",
    label: "Chien d'arrêt portugais",
  },
  {
    key: "chien_d_artois",
    label: "Chien d'Artois",
  },
  {
    key: "chien_d_eau_americain",
    label: "Chien d'eau americain",
  },
  {
    key: "chien_d_eau_espagnol",
    label: "Chien d'eau espagnol",
  },
  {
    key: "chien_d_eau_frison",
    label: "Chien d'eau frison",
  },
  {
    key: "chien_d_eau_irlandais",
    label: "Chien d'eau irlandais",
  },
  {
    key: "chien_d_eau_italien",
    label: "Chien d'eau Italien",
  },
  {
    key: "chien_d_eau_portugais",
    label: "Chien d'Eau Portugais",
  },
  {
    key: "chien_d_eau_romagnol",
    label: "Chien d'eau Romagnol",
  },
  {
    key: "chien_d_elan_gris_mineur",
    label: "Chien d'Elan Gris Mineur",
  },
  {
    key: "chien_d_elan_norvegien_gris",
    label: "Chien d'élan Norvégien gris",
  },
  {
    key: "chien_d_elan_norvegien_noir",
    label: "Chien d'elan norvegien noir",
  },
  {
    key: "chien_d_elan_suedois",
    label: "Chien d'elan suedois",
  },
  {
    key: "chien_d_ours_de_carelie",
    label: "Chien d'ours de carelie",
  },
  {
    key: "chien_d_oysel_allemand",
    label: "Chien d'Oysel Allemand",
  },
  {
    key: "chien_de_berge_croate",
    label: "Chien de berge Croate",
  },
  {
    key: "chien_de_berger_anglais_ancestral",
    label: "Chien de berger anglais ancestral",
  },
  {
    key: "chien_de_berger_belge",
    label: "Chien de berger belge",
  },
  {
    key: "chien_de_berger_britannique",
    label: "Chien de Berger Britannique",
  },
  {
    key: "chien_de_berger_catalan",
    label: "Chien de berger catalan",
  },
  {
    key: "chien_de_berger_d_anatolie",
    label: "Chien de berger d'Anatolie",
  },
  {
    key: "chien_de_berger_de_majorque",
    label: "Chien de berger de majorque",
  },
  {
    key: "chien_de_berger_des_pyrenees_a_face_rase",
    label: "Chien de berger des Pyrénées à face rase",
  },
  {
    key: "chien_de_berger_des_pyrenees_a_poil_long",
    label: "Chien de berger des Pyrénées à poil long",
  },
  {
    key: "chien_de_berger_des_shetland",
    label: "Chien de berger des Shetland",
  },
  {
    key: "chien_de_berger_des_tatras",
    label: "Chien de berger des tatras",
  },
  {
    key: "chien_de_berger_des_tatras_/_podhale",
    label: "Chien de berger des Tatras / Podhale",
  },
  {
    key: "chien_de_berger_du_caucase",
    label: "Chien de berger du Caucase",
  },
  {
    key: "chien_de_berger_d_auvergne",
    label: "Chien de Berger d'Auvergne",
  },
  {
    key: "chien_de_berger_islandais",
    label: "Chien de berger islandais",
  },
  {
    key: "chien_de_berger_roumain_de_bucovine",
    label: "Chien de Berger roumain de Bucovine",
  },
  {
    key: "chien_de_berger_roumain_de_mioritza",
    label: "Chien de berger roumain de mioritza",
  },
  {
    key: "chien_de_berger_roumain_des_carpathes",
    label: "Chien de berger roumain des Carpathes",
  },
  {
    key: "chien_de_berger_yougoslave_de_charplan",
    label: "Chien de berger yougoslave de charplan",
  },
  {
    key: "chien_de_canaan",
    label: "Chien de Canaan",
  },
  {
    key: "chien_de_castro_laboreiro",
    label: "Chien de castro laboreiro",
  },
  {
    key: "chien_de_combat_japonais",
    label: "Chien de combat Japonais",
  },
  {
    key: "chien_de_combat_majorquin",
    label: "Chien de combat majorquin",
  },
  {
    key: "chien_de_cour_italien",
    label: "Chien de cour italien",
  },
  {
    key: "chien_de_ferme_dano-suedois",
    label: "Chien de ferme dano-suedois",
  },
  {
    key: "chien_de_franche-comte",
    label: "Chien de Franche-Comté",
  },
  {
    key: "chien_de_garde_turc",
    label: "Chien de garde Turc",
  },
  {
    key: "chien_de_garenne_des_canaries",
    label: "Chien de garenne des canaries",
  },
  {
    key: "chien_de_garenne_portugais",
    label: "Chien de garenne Portugais",
  },
  {
    key: "chien_de_groenland",
    label: "Chien de Groenland",
  },
  {
    key: "chien_de_kheops",
    label: "Chien de Kheops",
  },
  {
    key: "chien_de_l_atlas_marocain",
    label: "Chien de l Atlas Marocain",
  },
  {
    key: "chien_de_l_ile_de_la_reunion",
    label: "Chien de l'Ile de la Reunion",
  },
  {
    key: "chien_de_la_serra_da_estrela",
    label: "Chien de la serra da estrela",
  },
  {
    key: "chien_de_montagne_de_l_atlas",
    label: "Chien de montagne de l'atlas",
  },
  {
    key: "chien_de_montagne_des_pyrenees",
    label: "Chien de montagne des Pyrénées",
  },
  {
    key: "chien_de_montagne_du_tibet",
    label: "Chien de montagne du Tibet",
  },
  {
    key: "chien_de_montagne_portugais",
    label: "Chien de montagne portugais",
  },
  {
    key: "chien_de_recherche_au_sang_de_hanovre",
    label: "Chien de recherche au sang de Hanovre",
  },
  {
    key: "chien_de_rhodesie_a_crete_dorsale",
    label: "Chien de Rhodésie à crête dorsale",
  },
  {
    key: "chien_de_rouge_de_baviere",
    label: "Chien de rouge de baviere",
  },
  {
    key: "chien_de_rouge_du_hanovre",
    label: "Chien de rouge du Hanovre",
  },
  {
    key: "chien_de_saint_hubert",
    label: "Chien de saint hubert",
  },
  {
    key: "chien_de_taiwan",
    label: "Chien de taiwan",
  },
  {
    key: "chien_de_teneriffe",
    label: "Chien de Teneriffe",
  },
  {
    key: "chien_de_terre-neuve",
    label: "Chien de Terre-Neuve",
  },
  {
    key: "chien_des_canaries",
    label: "Chien des Canaries",
  },
  {
    key: "chien_des_goths",
    label: "Chien des goths",
  },
  {
    key: "chien_des_pharaons",
    label: "Chien des pharaons",
  },
  {
    key: "chien_du_groenland",
    label: "Chien du Groënland",
  },
  {
    key: "chien_du_perou",
    label: "Chien du perou",
  },
  {
    key: "chien_du_pharaon",
    label: "Chien du pharaon",
  },
  {
    key: "chien_finnois_de_laponie",
    label: "Chien finnois de laponie",
  },
  {
    key: "chien_japonais_d_akita",
    label: "Chien Japonais d'Akita",
  },
  {
    key: "chien_leopard_catahoula",
    label: "Chien leopard catahoula",
  },
  {
    key: "chien_loup_de_saarloos",
    label: "Chien loup de Saarloos",
  },
  {
    key: "chien_loup_de_saarlos",
    label: "Chien loup de saarlos",
  },
  {
    key: "chien_loup_tchecoslovaque",
    label: "Chien loup tchecoslovaque (Ceskoslovensky veciak)",
  },
  {
    key: "chien_n._et_f._pour_la_chasse_au_raton",
    label: "Chien n. et f. pour la chasse au raton",
  },
  {
    key: "chien_noir_et_feu_pour_la_chasse_au_raton_laveur",
    label: "Chien noir et feu pour la chasse au raton laveur",
  },
  {
    key: "chien_norvegien_de_macareux",
    label: "Chien norvegien de macareux",
  },
  {
    key: "chien_nu_argentin",
    label: "Chien nu argentin",
  },
  {
    key: "chien_nu_du_perou",
    label: "Chien nu du Pérou",
  },
  {
    key: "chien_nu_mexicain",
    label: "Chien nu Mexicain",
  },
  {
    key: "chien_rouge_de_baviere",
    label: "Chien Rouge de Bavière",
  },
  {
    key: "chien_rouge_de_hanovre",
    label: "Chien rouge de Hanovre",
  },
  {
    key: "chien_suedois_de_laponie",
    label: "Chien suedois de laponie",
  },
  {
    key: "chien_thailandais_a_crete_dorsale",
    label: "Chien thailandais a crete dorsale",
  },
  {
    key: "chihuahua",
    label: "Chihuahua",
  },
  {
    key: "chow_chow",
    label: "Chow chow (Chow-chow)",
  },
  {
    key: "cimarron_uruguayen",
    label: "Cimarron uruguayen (Cimarron / Cimarron uruguayo)",
  },
  {
    key: "cirneco_de_l_etna",
    label: "Cirneco de l etna",
  },
  {
    key: "clumber-spaniel",
    label: "Clumber-Spaniel",
  },
  {
    key: "coban_kopegi",
    label: "Coban Kopegi",
  },
  {
    key: "cocker",
    label: "Cocker",
  },
  {
    key: "cocker_americain",
    label: "Cocker Américain",
  },
  {
    key: "cocker_anglais",
    label: "Cocker Anglais",
  },
  {
    key: "cocker_spaniel",
    label: "Cocker Spaniel",
  },
  {
    key: "cocker_spaniel_americain",
    label:
      "Cocker spaniel americain (Cocker americain / American cocker spaniel)",
  },
  {
    key: "cocker_spaniel_anglais",
    label: "Cocker spaniel anglais (Cocker anglais / English cocker spaniel)",
  },
  {
    key: "colley",
    label: "Colley",
  },
  {
    key: "colley_a_poil_court",
    label: "Colley a poil court",
  },
  {
    key: "colley_a_poil_long",
    label: "Colley à poil long",
  },
  {
    key: "colley_barbu",
    label: "Colley barbu",
  },
  {
    key: "colley_nain",
    label: "Colley nain",
  },
  {
    key: "colley_shetland",
    label: "Colley Shetland",
  },
  {
    key: "collie_a_poil_court",
    label: "Collie à poil court",
  },
  {
    key: "collie_a_poil_long",
    label: "Collie à poil long",
  },
  {
    key: "continental_bulldog",
    label: "Continental Bulldog",
  },
  {
    key: "coonhound_noir_et_feu",
    label: "Coonhound noir et feu",
  },
  {
    key: "coton_de_tulear",
    label: "Coton de Tulear",
  },
  {
    key: "curly_coated_retriever",
    label: "Curly Coated Retriever",
  },
  {
    key: "cursinu",
    label: "Cursinu (Chien corse / U'cursinu)",
  },
  {
    key: "dalmatien",
    label: "Dalmatien",
  },
  {
    key: "dandie-dinmont-terrier",
    label: "Dandie-Dinmont-Terrier",
  },
  {
    key: "danish_swedish_farmdog",
    label: "Danish Swedish farmdog",
  },
  {
    key: "danois",
    label: "Danois",
  },
  {
    key: "deerhound",
    label: "Deerhound",
  },
  {
    key: "deutsch_langhaar",
    label: "Deutsch langhaar",
  },
  {
    key: "doberman",
    label: "Doberman",
  },
  {
    key: "dobermann",
    label: "Dobermann",
  },
  {
    key: "dogo",
    label: "Dogo",
  },
  {
    key: "dogo_canario",
    label: "Dogo Canario",
  },
  {
    key: "dogue_allemand",
    label: "Dogue allemand (Danois / Deutsche dogge)",
  },
  {
    key: "dogue_anglais",
    label: "Dogue Anglais",
  },
  {
    key: "dogue_argentin",
    label: "Dogue argentin (Dogo argentino)",
  },
  {
    key: "dogue_d_aquitaine",
    label: "Dogue d'Aquitaine",
  },
  {
    key: "dogue_de_bordeaux",
    label: "Dogue de Bordeaux",
  },
  {
    key: "dogue_de_majorque",
    label: "Dogue de majorque",
  },
  {
    key: "dogue_des_canaries",
    label: "Dogue des Canaries",
  },
  {
    key: "dogue_du_tibet",
    label: "Dogue du Tibet",
  },
  {
    key: "drahthaar",
    label: "Drahthaar",
  },
  {
    key: "drever",
    label: "Drever",
  },
  {
    key: "dunker",
    label: "Dunker",
  },
  {
    key: "english_bull_terrier",
    label: "English Bull Terrier",
  },
  {
    key: "english_foxhound",
    label: "English foxhound",
  },
  {
    key: "english_toy_spaniel",
    label: "English Toy Spaniel",
  },
  {
    key: "english-springer-spaniel",
    label: "English-Springer-Spaniel",
  },
  {
    key: "epagneul_a_perdrix_de_drente",
    label: "Epagneul a perdrix de drente",
  },
  {
    key: "epagneul_allemand",
    label: "Epagneul allemand",
  },
  {
    key: "epagneul_bleu_de_picardie",
    label: "Epagneul Bleu de Picardie",
  },
  {
    key: "epagneul_breton",
    label: "Epagneul breton",
  },
  {
    key: "epagneul_d_eau_irlandais",
    label: "Epagneul d'eau irlandais",
  },
  {
    key: "epagneul_de_munster",
    label: "Epagneul de Munster",
  },
  {
    key: "epagneul_de_pont-audemer",
    label: "Epagneul de Pont-Audemer",
  },
  {
    key: "epagneul_de_saint-usuge",
    label: "Epagneul de Saint-Usuge",
  },
  {
    key: "epagneul_français",
    label: "Epagneul Français",
  },
  {
    key: "epagneul_hollandais_de_la_drenthe",
    label: "Epagneul hollandais de la Drenthe",
  },
  {
    key: "epagneul_japonais",
    label: "Epagneul japonais (Chin)",
  },
  {
    key: "epagneul_king_charles",
    label: "Epagneul king charles (King charles / King charles spaniel)",
  },
  {
    key: "epagneul_nain_continental",
    label: "Epagneul nain continental",
  },
  {
    key: "epagneul_nain_phalene",
    label: "Epagneul nain Phalène",
  },
  {
    key: "epagneul_papillon",
    label: "Epagneul Papillon",
  },
  {
    key: "epagneul_pekinois",
    label: "Epagneul pekinois (Pekinois / Pekingese)",
  },
  {
    key: "epagneul_phalene",
    label: "Epagneul Phalène",
  },
  {
    key: "epagneul_picard",
    label: "Epagneul picard",
  },
  {
    key: "epagneul_tibetain",
    label: "Epagneul tibetain (Epagneul du tibet / Tibetan spaniel)",
  },
  {
    key: "esquimau_du_canada",
    label: "Esquimau du Canada",
  },
  {
    key: "esquimau_du_groenland",
    label: "Esquimau du Groenland",
  },
  {
    key: "eurasier",
    label: "Eurasier (Eurasien)",
  },
  {
    key: "exotic_bull_dog",
    label: "Exotic Bull Dog",
  },
  {
    key: "fauve_de_bretagne",
    label: "Fauve de Bretagne",
  },
  {
    key: "field-spaniel",
    label: "Field-Spaniel",
  },
  {
    key: "fila_brasileiro",
    label: "Fila brasileiro",
  },
  {
    key: "fila_de_sao_miguel",
    label: "Fila de Sao Miguel",
  },
  {
    key: "flat_coated_retriever",
    label: "Flat coated retriever",
  },
  {
    key: "fox-hound_americain",
    label: "Fox-Hound Américain",
  },
  {
    key: "fox-hound_anglais",
    label: "Fox-Hound Anglais",
  },
  {
    key: "fox-terrier",
    label: "Fox-Terrier",
  },
  {
    key: "fox-terrier_a_poil_dur",
    label: "Fox-Terrier à poil dur",
  },
  {
    key: "fox-terrier_a_poil_lisse",
    label: "Fox-Terrier à poil lisse",
  },
  {
    key: "francais_blanc_et_noir",
    label: "Francais blanc et noir",
  },
  {
    key: "francais_blanc_et_orange",
    label: "Francais blanc et orange",
  },
  {
    key: "francais_tricolore",
    label: "Francais tricolore",
  },
  {
    key: "galgo",
    label: "Galgo",
  },
  {
    key: "gardiaan_boerboel",
    label: "Gardiaan Boerboel",
  },
  {
    key: "gascon-saintongeais",
    label: "Gascon-Saintongeais",
  },
  {
    key: "glen_of_imaal-terrier",
    label: "Glen of Imaal-Terrier",
  },
  {
    key: "golden-retriever",
    label: "Golden-Retriever",
  },
  {
    key: "gos_d_atura",
    label: "Gos d'Atura",
  },
  {
    key: "grand_anglo_francais_blanc_et_noir",
    label: "Grand anglo francais blanc et noir",
  },
  {
    key: "grand_anglo_francais_tricolore",
    label: "Grand anglo-francais tricolore (Grand anglo francais)",
  },
  {
    key: "grand_anglo-francais_blanc_et_orange",
    label: "Grand anglo-francais blanc et orange",
  },
  {
    key: "grand_basset_griffon_vendeen",
    label: "Grand basset griffon vendeen",
  },
  {
    key: "grand_bleu_de_gascogne",
    label: "Grand Bleu de Gascogne",
  },
  {
    key: "grand_bouvier_suisse",
    label: "Grand bouvier suisse (Grosser schweizer sennenhund)",
  },
  {
    key: "grand_chien_japonais",
    label: "Grand Chien Japonais",
  },
  {
    key: "grand_danois",
    label: "Grand Danois",
  },
  {
    key: "grand_epagneul_de_munster",
    label: "Grand Epagneul de Munster",
  },
  {
    key: "grand_griffon_vendeen",
    label: "Grand griffon vendeen",
  },
  {
    key: "grand_munsterlander",
    label: "Grand münsterländer",
  },
  {
    key: "greyhound",
    label: "Greyhound",
  },
  {
    key: "griffon",
    label: "Griffon",
  },
  {
    key: "griffon_a_poil_dur_korthal",
    label: "Griffon à poil dur korthal",
  },
  {
    key: "griffon_a_poil_laineux",
    label: "Griffon a poil laineux",
  },
  {
    key: "griffon_belge",
    label: "Griffon belge",
  },
  {
    key: "griffon_bleu_de_gascogne",
    label: "Griffon bleu de Gascogne",
  },
  {
    key: "griffon_bruxellois",
    label: "Griffon bruxellois",
  },
  {
    key: "griffon_d_arret_slovaque_a_poil_dur",
    label: "Griffon d'arret slovaque a poil dur",
  },
  {
    key: "griffon_fauve_de_bretagne",
    label: "Griffon Fauve de Bretagne",
  },
  {
    key: "griffon_korthals",
    label: "Griffon Korthals",
  },
  {
    key: "griffon_nivernais",
    label: "Griffon nivernais",
  },
  {
    key: "griffon_vendeen",
    label: "Griffon Vendéen",
  },
  {
    key: "groenendael",
    label: "Groenendael",
  },
  {
    key: "harrier",
    label: "Harrier",
  },
  {
    key: "hokkaido",
    label: "Hokkaido",
  },
  {
    key: "hokkaido_inu",
    label: "Hokkaido Inu",
  },
  {
    key: "hovawart",
    label: "Hovawart",
  },
  {
    key: "hug",
    label: "Hug",
  },
  {
    key: "husky_de_siberie",
    label: "Husky de Sibérie",
  },
  {
    key: "husky_siberien",
    label: "Husky Sibérien",
  },
  {
    key: "inuit_canadien",
    label: "Inuit Canadien",
  },
  {
    key: "irish_water_spaniel",
    label: "Irish water spaniel",
  },
  {
    key: "irish_wolfhound",
    label: "Irish Wolfhound",
  },
  {
    key: "irish-terrier",
    label: "Irish-Terrier",
  },
  {
    key: "jack-russell-terrier",
    label: "Jack-Russell-Terrier",
  },
  {
    key: "jagdterrier",
    label: "Jagdterrier",
  },
  {
    key: "jindo_coreen",
    label: "Jindo coreen (Korea jindo dog)",
  },
  {
    key: "kai",
    label: "Kai",
  },
  {
    key: "kangal",
    label: "Kangal",
  },
  {
    key: "kelpie",
    label: "Kelpie",
  },
  {
    key: "kerry-blue-terrier",
    label: "Kerry-Blue-Terrier",
  },
  {
    key: "king_charles",
    label: "King Charles",
  },
  {
    key: "king_charles_spaniel",
    label: "King Charles Spaniel",
  },
  {
    key: "king_shepherd",
    label: "King Shepherd",
  },
  {
    key: "kishu",
    label: "Kishu",
  },
  {
    key: "kokoni",
    label: "Kokoni",
  },
  {
    key: "komondor",
    label: "Komondor",
  },
  {
    key: "kooikerhondje",
    label: "Kooikerhondje",
  },
  {
    key: "korea_jindo_dog",
    label: "Korea jindo dog",
  },
  {
    key: "kromfohrlander",
    label: "Kromfohrlander (Kromfohrländer)",
  },
  {
    key: "kuvasz",
    label: "Kuvasz",
  },
  {
    key: "kuwatz",
    label: "Kuwatz",
  },
  {
    key: "kyi_leo",
    label: "Kyi leo",
  },
  {
    key: "labernois",
    label: "Labernois",
  },
  {
    key: "labradoodle",
    label: "Labradoodle",
  },
  {
    key: "labrador-retriever",
    label: "Labrador-Retriever",
  },
  {
    key: "labrit",
    label: "Labrit",
  },
  {
    key: "lagotto_romagnolo",
    label: "Lagotto Romagnolo",
  },
  {
    key: "laika_de_iakoutie",
    label: "Laïka de Iakoutie",
  },
  {
    key: "laika_de_siberie_occidentale",
    label: "Laika de siberie occidentale",
  },
  {
    key: "laika_de_siberie_orientale",
    label: "Laika de siberie orientale",
  },
  {
    key: "laika_russo_europeen",
    label:
      "Laika russo europeen (Laika russo-europeen / Russko evropeïskaïa laïka)",
  },
  {
    key: "lakeland-terrier",
    label: "Lakeland-Terrier",
  },
  {
    key: "lancashire_heeler",
    label: "Lancashire heeler",
  },
  {
    key: "landseer",
    label: "Landseer (Landseer",
  },
  {
    key: "langhaar",
    label: "Langhaar",
  },
  {
    key: "lapphund_suedois",
    label: "Lapphund suedois (Lapphund / Svensk lapphund)",
  },
  {
    key: "leonberg",
    label: "Leonberg",
  },
  {
    key: "leonberger",
    label: "Léonberger",
  },
  {
    key: "levrier_afghan",
    label: "Levrier afghan (Afghan / Afghan hound)",
  },
  {
    key: "levrier_anglais",
    label: "Lévrier anglais",
  },
  {
    key: "levrier_arabe",
    label: "Lévrier Arabe",
  },
  {
    key: "levrier_azawakh",
    label: "Lévrier Azawakh",
  },
  {
    key: "levrier_ecossais",
    label: "Levrier ecossais (Deerhound / Deerhound",
  },
  {
    key: "levrier_espagnol",
    label: "Levrier espagnol (Galgo / Galgo español)",
  },
  {
    key: "levrier_hongrois",
    label: "Levrier hongrois (Magyar agar)",
  },
  {
    key: "levrier_irlandais",
    label: "Levrier irlandais (Irish wolfhound)",
  },
  {
    key: "levrier_polonais",
    label: "Levrier polonais (Chart polski)",
  },
  {
    key: "levrier_russe",
    label: "Lévrier russe",
  },
  {
    key: "lhassa_apso",
    label: "Lhassa apso",
  },
  {
    key: "lion_d_occitanie",
    label: "Lion d'occitanie",
  },
  {
    key: "loulou_de_pomeranie",
    label: "Loulou de Poméranie",
  },
  {
    key: "lundehund",
    label: "Lundehund",
  },
  {
    key: "magyar_agar",
    label: "Magyar Agar",
  },
  {
    key: "malamute_d_alaska",
    label: "Malamute d'Alaska",
  },
  {
    key: "malinois",
    label: "Malinois",
  },
  {
    key: "manchester_terrier",
    label: "Manchester terrier (Terrier de manchester)",
  },
  {
    key: "mastiff",
    label: "Mastiff",
  },
  {
    key: "mastiff_danois",
    label: "Mastiff Danois",
  },
  {
    key: "mastiff_du_tibet",
    label: "Mastiff du Tibet",
  },
  {
    key: "matin_de_l_alentejo",
    label: "Matin de l'alentejo",
  },
  {
    key: "matin_de_naples",
    label: "Mâtin de Naples",
  },
  {
    key: "matin_des_pyrenees",
    label: "Mâtin des pyrénées",
  },
  {
    key: "matin_espagnol",
    label: "Matin espagnol (Mastin espanol)",
  },
  {
    key: "matin_napolitain",
    label: "Matin napolitain (Matin de naples / Mastino napoletano)",
  },
  {
    key: "mini_aussie_bulldog",
    label: "Mini aussie Bulldog",
  },
  {
    key: "mini_berger_australien",
    label: "mini berger australien",
  },
  {
    key: "montagne_des_pyrenees",
    label: "Montagne des Pyrénées",
  },
  {
    key: "morkie",
    label: "Morkie",
  },
  {
    key: "mudi",
    label: "Mudi",
  },
  {
    key: "nederlandse_kooikerhondje",
    label: "Nederlandse kooikerhondje",
  },
  {
    key: "nihon_suppitsu",
    label: "Nihon suppitsu",
  },
  {
    key: "nihon_teria",
    label: "Nihon Teria",
  },
  {
    key: "nizinny",
    label: "Nizinny",
  },
  {
    key: "nordic_timber",
    label: "Nordic Timber",
  },
  {
    key: "norfolk-terrier",
    label: "Norfolk-Terrier",
  },
  {
    key: "norwich-terrier",
    label: "Norwich-Terrier",
  },
  {
    key: "old_english_bouledogue",
    label: "Old English Bouledogue",
  },
  {
    key: "old_inuit_dog",
    label: "Old Inuit Dog",
  },
  {
    key: "otterhound",
    label: "Otterhound",
  },
  {
    key: "owczarek_nizinny",
    label: "Owczarek Nizinny",
  },
  {
    key: "papillon",
    label: "Papillon",
  },
  {
    key: "parson_jack_russell",
    label: "Parson Jack Russell",
  },
  {
    key: "patou",
    label: "Patou",
  },
  {
    key: "patterdale-terrier",
    label: "Patterdale-Terrier",
  },
  {
    key: "pekinois",
    label: "Pékinois",
  },
  {
    key: "perdiguero_de_burgos",
    label: "Perdiguero de Burgos",
  },
  {
    key: "petit_basset_griffon_vendeen",
    label: "Petit basset griffon vendeen",
  },
  {
    key: "petit_bleu_de_gascogne",
    label: "Petit Bleu de Gascogne",
  },
  {
    key: "petit_brabancon",
    label: "Petit brabancon (Brabancon)",
  },
  {
    key: "petit_chien_courant_bernois",
    label: "Petit chien courant Bernois",
  },
  {
    key: "petit_chien_courant_de_schwyz",
    label: "Petit chien courant de Schwyz",
  },
  {
    key: "petit_chien_courant_du_jura",
    label: "Petit chien courant du Jura",
  },
  {
    key: "petit_chien_courant_suisse",
    label: "Petit chien courant suisse (Schweizerischer niederlaufhund)",
  },
  {
    key: "petit_chien_domestique_grecque",
    label: "Petit Chien domestique Grecque",
  },
  {
    key: "petit_chien_hollandais_de_chasse_a_gibier_d_eau",
    label: "Petit chien Hollandais de chasse à gibier d'eau",
  },
  {
    key: "petit_chien_lion",
    label: "Petit chien lion (Lowchen)",
  },
  {
    key: "petit_chien_russe",
    label: "Petit chien russe (Russkiy toy)",
  },
  {
    key: "petit_epagneul_de_munster",
    label: "Petit Epagneul de Munster",
  },
  {
    key: "petit_levrier_italien",
    label: "Petit levrier italien (Pli / Piccolo levriero italiano)",
  },
  {
    key: "petit_spitz",
    label: "Petit Spitz",
  },
  {
    key: "pila",
    label: "Pila",
  },
  {
    key: "pinscher",
    label: "Pinscher",
  },
  {
    key: "pinscher_allemand",
    label: "Pinscher allemand (Pinscher moyen / Deutscher pinscher)",
  },
  {
    key: "pinscher_australien",
    label: "Pinscher australien",
  },
  {
    key: "pinscher_autrichien",
    label: "Pinscher autrichien (österreichischer pinscher)",
  },
  {
    key: "pinscher_moyen",
    label: "Pinscher moyen",
  },
  {
    key: "pinscher_nain",
    label: "Pinscher nain (Zwergpinscher)",
  },
  {
    key: "pit_bull_terrier",
    label: "Pit Bull Terrier",
  },
  {
    key: "pitbull",
    label: "Pitbull",
  },
  {
    key: "plott_hound",
    label: "Plott hound",
  },
  {
    key: "podenco",
    label: "Podenco",
  },
  {
    key: "podenco_canario",
    label: "Podenco canario",
  },
  {
    key: "podenco_d_ibiza",
    label: "Podenco d'ibiza",
  },
  {
    key: "podengo_portugais",
    label: "Podengo portugais",
  },
  {
    key: "pointer",
    label: "Pointer",
  },
  {
    key: "pointer_anglais",
    label: "Pointer anglais (Pointer / English pointer)",
  },
  {
    key: "poitevin",
    label: "Poitevin",
  },
  {
    key: "pomsky",
    label: "Pomsky",
  },
  {
    key: "porcelaine",
    label: "Porcelaine",
  },
  {
    key: "pudelpointer",
    label: "Pudelpointer",
  },
  {
    key: "pug",
    label: "Pug",
  },
  {
    key: "puli",
    label: "Puli",
  },
  {
    key: "pumi",
    label: "Pumi",
  },
  {
    key: "qimmiq",
    label: "Qimmiq",
  },
  {
    key: "rafeiro_de_l_alentejo",
    label: "Rafeiro de l'Alentejo",
  },
  {
    key: "ratier_de_prague",
    label: "Ratier de Prague",
  },
  {
    key: "ratier_pragois",
    label: "Ratier pragois",
  },
  {
    key: "redbone_coonhound",
    label: "Redbone coonhound",
  },
  {
    key: "retriever_a_poil_boucles",
    label: "Retriever à poil bouclés",
  },
  {
    key: "retriever_a_poil_plat",
    label: "Retriever à poil plat",
  },
  {
    key: "retriever_de_la_baie_de_cheasapeake",
    label: "Retriever de la baie de cheasapeake",
  },
  {
    key: "retriever_de_nouvelle_ecosse",
    label: "Retriever de nouvelle écosse",
  },
  {
    key: "retriever_flat_coated",
    label: "Retriever Flat Coated",
  },
  {
    key: "rhodesian-ridgeback",
    label: "Rhodesian-Ridgeback",
  },
  {
    key: "rottweiler",
    label: "Rottweiler (Rott)",
  },
  {
    key: "rottweiler_miniature",
    label: "Rottweiler miniature",
  },
  {
    key: "rouge_de_baviere",
    label: "Rouge de Baviere",
  },
  {
    key: "royal_bourbon",
    label: "Royal Bourbon",
  },
  {
    key: "russkiy_toy",
    label: "Russkiy Toy",
  },
  {
    key: "saint-bernard",
    label: "Saint-Bernard",
  },
  {
    key: "saint-pierre",
    label: "Saint-Pierre",
  },
  {
    key: "saluki",
    label: "Saluki",
  },
  {
    key: "samoyede",
    label: "Samoyede (Samoiedskaïa sabaka)",
  },
  {
    key: "sarplaninac",
    label: "Sarplaninac",
  },
  {
    key: "schapendoes",
    label: "Schapendoes",
  },
  {
    key: "schapendoes_neerlandais",
    label: "Schapendoes neerlandais (Schapendoes / Nederlandse schapendoes)",
  },
  {
    key: "schipperke",
    label: "Schipperke",
  },
  {
    key: "schnauzer_geant",
    label: "Schnauzer geant (Riesenschnauzer)",
  },
  {
    key: "schnauzer_moyen",
    label: "Schnauzer Moyen",
  },
  {
    key: "schnauzer_nain",
    label: "Schnauzer nain (Zwergschnauzer)",
  },
  {
    key: "scottish-terrier",
    label: "Scottish-Terrier",
  },
  {
    key: "sealyham-terrier",
    label: "Sealyham-Terrier",
  },
  {
    key: "setter_anglais",
    label: "Setter anglais (English setter)",
  },
  {
    key: "setter_gordon",
    label: "Setter gordon (Gordon setter)",
  },
  {
    key: "setter_irlandais",
    label: "Setter Irlandais",
  },
  {
    key: "setter_irlandais_rouge",
    label: "Setter irlandais rouge (Irish red setter)",
  },
  {
    key: "setter_irlandais_rouge_et_blanc",
    label: "Setter Irlandais rouge et blanc",
  },
  {
    key: "shar-pei",
    label: "Shar-Pei",
  },
  {
    key: "shiba-inu",
    label: "Shiba-Inu",
  },
  {
    key: "shikoku",
    label: "Shikoku",
  },
  {
    key: "shitzu",
    label: "Shitzu",
  },
  {
    key: "silky_terrier",
    label: "Silky Terrier",
  },
  {
    key: "skye",
    label: "Skye",
  },
  {
    key: "skye_terrier",
    label: "Skye terrier (Terrier de l'ile de skye)",
  },
  {
    key: "sloughi",
    label: "Sloughi",
  },
  {
    key: "smous_des_pays_bas",
    label: "Smous des pays bas",
  },
  {
    key: "spinone",
    label: "Spinone (Spinone italiano)",
  },
  {
    key: "spitz",
    label: "Spitz",
  },
  {
    key: "spitz_allemand",
    label: "Spitz Allemand",
  },
  {
    key: "spitz_allemand_nain",
    label: "Spitz Allemand nain",
  },
  {
    key: "spitz_de_norrbotten",
    label: "Spitz de norrbotten",
  },
  {
    key: "spitz_de_pomeranie",
    label: "Spitz de poméranie",
  },
  {
    key: "spitz_des_visigoths",
    label: "Spitz des visigoths",
  },
  {
    key: "spitz_finlandais",
    label: "Spitz finlandais (Suomenpystykorva",
  },
  {
    key: "spitz_finnois_de_laponie",
    label: "Spitz finnois de Laponie",
  },
  {
    key: "spitz_français",
    label: "Spitz Français",
  },
  {
    key: "spitz_japonais",
    label: "Spitz japonais (Nihon supittsu)",
  },
  {
    key: "spitz_loup",
    label: "Spitz loup",
  },
  {
    key: "spitz_moyen",
    label: "Spitz moyen",
  },
  {
    key: "spitz_nain",
    label: "Spitz nain",
  },
  {
    key: "springer_anglais",
    label: "Springer Anglais",
  },
  {
    key: "springer_spaniel",
    label: "Springer Spaniel",
  },
  {
    key: "stabyhoun",
    label: "Stabyhoun",
  },
  {
    key: "staffordshire_bull_terrier",
    label: "Staffordshire bull terrier (Staffie)",
  },
  {
    key: "staffordshire_terrier_americain",
    label:
      "Staffordshire terrier americain (Amstaff / American staffordshire terrier)",
  },
  {
    key: "sussex-spaniel",
    label: "Sussex-Spaniel",
  },
  {
    key: "syndney_terrier",
    label: "Syndney Terrier",
  },
  {
    key: "tchiorny_terrier",
    label: "Tchiorny Terrier",
  },
  {
    key: "tchouvatch_slovaque",
    label: "Tchouvatch slovaque (Slovensky cuvac)",
  },
  {
    key: "teckel",
    label: "Teckel (Dachshund",
  },
  {
    key: "teckel_a_poil_dur",
    label: "Teckel à poil dur",
  },
  {
    key: "teckel_a_poil_long",
    label: "Teckel à poil long",
  },
  {
    key: "teckel_a_poil_ras",
    label: "Teckel à poil ras",
  },
  {
    key: "teckel_kaninchen",
    label: "Teckel Kaninchen",
  },
  {
    key: "teckel_nain_a_poil_dur",
    label: "Teckel Nain à poil dur",
  },
  {
    key: "teckel_nain_a_poil_long",
    label: "Teckel Nain à poil long",
  },
  {
    key: "teckel_nain_a_poil_ras",
    label: "Teckel Nain à poil ras",
  },
  {
    key: "telomian",
    label: "Telomian",
  },
  {
    key: "terre_neuve",
    label: "Terre neuve (Newfoundland)",
  },
  {
    key: "terrier_anglais_d_agrement_(noir_et_feu)",
    label: "Terrier anglais d'agrément (noir et feu)",
  },
  {
    key: "terrier_australien",
    label: "Terrier australien (Australian terrier)",
  },
  {
    key: "terrier_australien_a_poil_soyeux",
    label: "Terrier Australien à poil soyeux",
  },
  {
    key: "terrier_bresilien",
    label: "Terrier bresilien (Terrier brasileiro)",
  },
  {
    key: "terrier_d_agrement_anglais_noir_et_feu",
    label: "Terrier d'agrement anglais noir et feu",
  },
  {
    key: "terrier_de_boston",
    label: "Terrier de Boston",
  },
  {
    key: "terrier_de_chasse_allemand",
    label: "Terrier de chasse Allemand",
  },
  {
    key: "terrier_de_manchester",
    label: "Terrier de Manchester",
  },
  {
    key: "terrier_du_congo",
    label: "Terrier du congo",
  },
  {
    key: "terrier_du_reverend_russell",
    label: "Terrier du Révérend Russell",
  },
  {
    key: "terrier_du_tibet",
    label: "Terrier du Tibet",
  },
  {
    key: "terrier_ecossais",
    label: "Terrier ecossais (Scottish / Scottish terrier)",
  },
  {
    key: "terrier_gallois",
    label: "Terrier Gallois",
  },
  {
    key: "terrier_irlandais",
    label: "Terrier irlandais (Irish terrier)",
  },
  {
    key: "terrier_irlandais_-_glen_of_imaal-terrier",
    label: "Terrier irlandais - Glen of Imaal-Terrier",
  },
  {
    key: "terrier_irlandais_a_poil_doux",
    label: "Terrier irlandais a poil doux",
  },
  {
    key: "terrier_jack_russell",
    label: "Terrier jack russell (Jack russell / Jack russell terrier)",
  },
  {
    key: "terrier_japonais",
    label: "Terrier japonais (Japanese terrier / Nihon teria)",
  },
  {
    key: "terrier_kerry_blue",
    label: "Terrier kerry blue (Kerry blue / Kerry blue terrier)",
  },
  {
    key: "terrier_noir_russe",
    label: "Terrier noir russe (Tchiorny / Tchiorny terrier)",
  },
  {
    key: "terrier_tcheque",
    label: "Terrier tcheque (Cesky terrier)",
  },
  {
    key: "terrier_tibetain",
    label: "Terrier tibetain (Terrier du tibet / Tibetan terrier)",
  },
  {
    key: "tervueren",
    label: "Tervueren",
  },
  {
    key: "timber_shepherd",
    label: "Timber Shepherd",
  },
  {
    key: "tornjak",
    label: "Tornjak",
  },
  {
    key: "tosa",
    label: "Tosa (Tosa inu)",
  },
  {
    key: "toy_terrier",
    label: "Toy terrier",
  },
  {
    key: "toy_terrier_noir_et_feu",
    label: "Toy terrier noir et feu",
  },
  {
    key: "u_cursinu",
    label: "U Cursinu",
  },
  {
    key: "vallhund_suedois_/_spitz_des_visigoths",
    label: "Vallhund Suédois / Spitz des Visigoths",
  },
  {
    key: "vastgotaspets",
    label: "Vastgotaspets",
  },
  {
    key: "vizla",
    label: "Vizla",
  },
  {
    key: "volpino_italien",
    label: "Volpino italien (Spitz italien / Volpino italiano)",
  },
  {
    key: "welsh_corgi",
    label: "Welsh Corgi",
  },
  {
    key: "welsh_corgi_cardigan",
    label: "Welsh corgi cardigan (Cardigan)",
  },
  {
    key: "welsh_corgi_pembroke",
    label: "Welsh corgi pembroke (Pembroke)",
  },
  {
    key: "welsh_springer_spaniel",
    label: "Welsh springer spaniel (Welsh springer)",
  },
  {
    key: "welsh-terrier",
    label: "Welsh-Terrier",
  },
  {
    key: "west_highland_white_terrier",
    label: "West highland white terrier (Westie)",
  },
  {
    key: "westhy",
    label: "Westhy",
  },
  {
    key: "westie",
    label: "Westie",
  },
  {
    key: "wheaten_terrier",
    label: "Wheaten Terrier",
  },
  {
    key: "whippet",
    label: "Whippet",
  },
  {
    key: "xoloitzcuintle",
    label: "Xoloitzcuintle",
  },
  {
    key: "yorkipoo",
    label: "Yorkipoo",
  },
  {
    key: "yorkshire_terrier",
    label: "Yorkshire terrier (Yorkie)",
  },
  {
    key: "ysterberg_mastiff",
    label: "Ysterberg Mastiff",
  },
  {
    key: "airedale_terrier",
    label: "Airedale terrier (Airedale)",
  },
  {
    key: "akita",
    label: "Akita (Akita inu)",
  },
  {
    key: "anglo_francais_petite_venerie",
    label: "Anglo francais de petite venerie",
  },
  {
    key: "bangkaew_thailande",
    label: "Bangkaew de thailande (Thai bangkaew dog)",
  },
  {
    key: "barbado_terceira",
    label: "Barbado de terceira (Bouvier portugais)",
  },
  {
    key: "basenji",
    label: "Basenji",
  },
  {
    key: "basset_artesien_normand",
    label: "Basset artesien normand",
  },
  {
    key: "basset_bleu_gascogne",
    label: "Basset bleu de gascogne",
  },
  {
    key: "basset_westphalie",
    label: "Basset de westphalie (Westfälische dachsbracke)",
  },
  {
    key: "basset_alpes",
    label: "Basset des alpes (Alpenländische dachsbracke)",
  },
  {
    key: "basset_fauve_bretagne",
    label: "Basset fauve de bretagne",
  },
  {
    key: "beagle_harrier",
    label: "Beagle harrier",
  },
  {
    key: "bedlington_terrier",
    label: "Bedlington terrier (Bedlington)",
  },
  {
    key: "berger_asie_centrale",
    label: "Berger d'asie centrale (Sredneasiatskaïa ovtcharka)",
  },
  {
    key: "berger_beauce",
    label: "Berger de beauce (Beauceron",
  },
  {
    key: "berger_bosnie_herzegovine_croatie",
    label: "Berger de bosnie-herzegovine et de croatie (Tornjak)",
  },
  {
    key: "berger_brie",
    label: "Berger de brie (Briard)",
  },
  {
    key: "berger_europe_sud_est",
    label: "Berger de l'europe du sud-est (Ciobanesc romanesc de bucovina)",
  },
  {
    key: "berger_maremme_abruzzes",
    label:
      "Berger de la maremme et des abruzzes (Berger des abruzzes et de maremme / Cane da pastore maremmano-abruzzese)",
  },
  {
    key: "berger_serra_aires",
    label:
      "Berger de la serra de aires (Berger portugais / Cão da serra de aires)",
  },
  {
    key: "berger_picardie",
    label: "Berger de picardie (Berger picard)",
  },
  {
    key: "berger_russie_meridionale",
    label: "Berger de russie meridionale (Ioujnorousskaïa ovtcharka)",
  },
  {
    key: "berger_pyrenees_face_rase",
    label: "Berger des pyrenees a face rase (Labrit)",
  },
  {
    key: "berger_pyrenees_poil_long",
    label: "Berger des pyrenees a poil long (Labrit)",
  },
  {
    key: "berger_caucase",
    label: "Berger du caucase (Kavkazskaïa ovtcharka)",
  },
  {
    key: "berger_karst",
    label: "Berger du karst (Berger du bassin de kras / Kraszki ovcar)",
  },
  {
    key: "berger_finnois_laponie",
    label: "Berger finnois de laponie (Lapinporokoira)",
  },
  {
    key: "berger_polonais_plaine",
    label: "Berger polonais de plaine (Nizinny / Polski owczarek nizinny)",
  },
  {
    key: "bichon_poil_frise",
    label: "Bichon a poil frise (Bichon frise)",
  },
  {
    key: "bichon_bolonais",
    label: "Bichon bolonais (Bolognese)",
  },
  {
    key: "border_terrier",
    label: "Border terrier",
  },
  {
    key: "bouledogue_francais",
    label: "Bouledogue francais",
  },
  {
    key: "bouvier_appenzell",
    label: "Bouvier de l'appenzell (Appenzeller sennenhund)",
  },
  {
    key: "bouvier_entlebuch",
    label: "Bouvier de l'entlebuch (Entlebucher sennenhund)",
  },
  {
    key: "bouvier_ardennes",
    label: "Bouvier des ardennes",
  },
  {
    key: "bouvier_flandres",
    label: "Bouvier des flandres (Vlamse koehond)",
  },
  {
    key: "brachet_styrie_poil_dur",
    label:
      "Brachet de styrie a poil dur (Peintinger brachet de styrie a poil dur / Steirische rauhhaarbracke)",
  },
  {
    key: "brachet_noir_feu",
    label: "Brachet noir et feu (Brachet tirolien / Brandlbracke)",
  },
  {
    key: "braque_allemand_poil_court",
    label: "Braque allemand a poil court (Braque allemand / Deutsch kurzhaar)",
  },
  {
    key: "braque_auvergne",
    label: "Braque d'auvergne (Bleu d'auvergne / Braque d'auvergne)",
  },
  {
    key: "braque_burgos",
    label: "Braque de burgos (Perdiguero de burgos)",
  },
  {
    key: "braque_ariege",
    label: "Braque de l'ariege (Braque de l'ariege)",
  },
  {
    key: "braque_weimar",
    label: "Braque de weimar (Weimaraner)",
  },
  {
    key: "braque_bourbonnais",
    label: "Braque du bourbonnais",
  },
  {
    key: "braque_hongrois_poil_court",
    label:
      "Braque hongrois a poil court (Vizsla a poil court / Rövidszöru magyar vizsla)",
  },
  {
    key: "braque_hongrois_poil_dur",
    label: "Braque hongrois a poil dur (Vizsla / Drötzörü magyar vizsla)",
  },
  {
    key: "braque_saint_germain",
    label: "Braque saint germain",
  },
  {
    key: "braque_slovaque_poil_dur",
    label: "Braque slovaque a poil dur (Slovenski hrubosrsty stavac (ohar))",
  },
  {
    key: "briquet_provence",
    label: "Briquet de provence",
  },
  {
    key: "bruno_saint_hubert_francais",
    label:
      "Bruno saint-hubert francais (Bruno du jura type saint hubert / Jura laufhund",
  },
  {
    key: "bulldog",
    label: "Bulldog (Bulldog anglais)",
  },
  {
    key: "bulldog_continental",
    label: "Bulldog continental (Conti)",
  },
  {
    key: "cao_gado_transmontano",
    label: "Cao de gado transmontano",
  },
  {
    key: "chien_loutre",
    label: "Chien a loutre (Chien de loutre / Otterhound)",
  },
  {
    key: "chien_chinois_crete",
    label: "Chien chinois a crete (Chinese crested dog)",
  },
  {
    key: "chien_courant_istrie_poil_dur",
    label: "Chien courant d'istrie a poil dur (Istarski ostrodlaki gonic)",
  },
  {
    key: "chien_courant_istrie_poil_ras",
    label: "Chien courant d'istrie a poil ras (Istarski kratkodlaki gonic)",
  },
  {
    key: "chien_courant_bosnie_poil_dur",
    label: "Chien courant de bosnie a poil dur (Bosanski ostrodlaki gonic",
  },
  {
    key: "chien_courant_halden",
    label: "Chien courant de halden (Haldenstover)",
  },
  {
    key: "chien_courant_hamilton",
    label: "Chien courant de hamilton (Hamiltonstovare)",
  },
  {
    key: "chien_courant_hygen",
    label: "Chien courant de hygen (Hygenhund)",
  },
  {
    key: "chien_courant_valle_save",
    label:
      "Chien courant de la valle de la save (Chien courant de posavatz / Posavski gonic)",
  },
  {
    key: "chien_courant_montagne_montenegro",
    label:
      "Chien courant de montagne du montenegro (Chien courant yougoslave / Crnogorski planinski gonic)",
  },
  {
    key: "chien_courant_schiller",
    label: "Chien courant de schiller (Schillerstövare)",
  },
  {
    key: "chien_courant_transylvanie",
    label: "Chien courant de transylvanie (Erdelyi kopo)",
  },
  {
    key: "chien_courant_smaland",
    label: "Chien courant du smaland (Smalandsstovare)",
  },
  {
    key: "chien_courant_italien_poil_dur",
    label:
      "Chien courant italien a poil dur (Segugio a poil dur / Segugio italiano a pelo forte)",
  },
  {
    key: "chien_courant_italien_poil_ras",
    label:
      "Chien courant italien a poil ras (Segugio a poil ras / Segugio italiano a pelo razo)",
  },
  {
    key: "chien_arret_allemand_poil_dur",
    label: "Chien d'arret allemand a poil dur (Drahthaar / Deutsch drahthaar)",
  },
  {
    key: "chien_arret_allemand_poil_long",
    label: "Chien d'arret allemand a poil long (Langhaar / deutsch langhaar)",
  },
  {
    key: "chien_arret_allemand_poil_raide",
    label:
      "Chien d'arret allemand a poil raide (Stichelhaar / Deutsch stichelhaar)",
  },
  {
    key: "chien_arret_danois_ancestral",
    label:
      "Chien d'arret danois ancestral (Ancien chien d'arret danois / Gammel dansk honshund)",
  },
  {
    key: "chien_arret_frison",
    label: "Chien d'arret frison (Stabyhoun)",
  },
  {
    key: "chien_arret_portugais",
    label: "Chien d'arret portugais (Braque portugais / Perdigueiro portugues)",
  },
  {
    key: "chien_artois",
    label: "Chien d'artois",
  },
  {
    key: "chien_eau_americain",
    label: "Chien d'eau americain (American water spaniel)",
  },
  {
    key: "chien_eau_espagnol",
    label: "Chien d'eau espagnol (Perro de agua español)",
  },
  {
    key: "chien_eau_frison",
    label: "Chien d'eau frison (Wetterhoun)",
  },
  {
    key: "chien_eau_portugais",
    label: "Chien d'eau portugais (Cao de agua / Cão de agua português)",
  },
  {
    key: "chien_eau_romagnol",
    label: "Chien d'eau romagnol (Lagotto romagnolo)",
  },
  {
    key: "chien_elan_norvegien_gris",
    label: "Chien d'elan norvegien gris (Elkhound gris / Norsk elkhund gra)",
  },
  {
    key: "chien_elan_norvegien_noir",
    label: "Chien d'elan norvegien noir (Elkhound noir / Norsk elkhund sort)",
  },
  {
    key: "chien_elan_suedois",
    label: "Chien d'elan suedois / jämthund (Jamthund / Jämthund)",
  },
  {
    key: "chien_ours_carelie",
    label: "Chien d'ours de carelie (Karjalankarhukoira)",
  },
  {
    key: "chien_oysel_allemand",
    label: "Chien d'oysel allemand (Deutscher wachtelhund)",
  },
  {
    key: "chien_berger_anglais_ancestral",
    label: "Chien de berger anglais ancestral (Bobtail / Old english sheepdog)",
  },
  {
    key: "chien_berger_belge",
    label: "Chien de berger belge (Berger belge / Belgische herdershond)",
  },
  {
    key: "chien_berger_catalan",
    label: "Chien de berger catalan (Gos d'atura / Perro de pastor catalan",
  },
  {
    key: "chien_berger_croate",
    label: "Chien de berger croate (Berger de croatie / Hrvatski ovcar)",
  },
  {
    key: "chien_berger_anatolie",
    label: "Chien de berger d'anatolie (Berger d'anatolie / Coban kopegi)",
  },
  {
    key: "chien_berger_majorque",
    label:
      "Chien de berger de majorque (Ca de bestiar / Perro de pastor mallorquin",
  },
  {
    key: "chien_berger_shetland",
    label: "Chien de berger des shetland (Shetland",
  },
  {
    key: "chien_berger_tatras",
    label:
      "Chien de berger des tatras (Berger de podhale / Polski owczarek podhalanski)",
  },
  {
    key: "chien_berger_islandais",
    label:
      "Chien de berger islandais (Iceland sheepdog / Islenskur fjarhundur)",
  },
  {
    key: "chien_berger_roumain_mioritza",
    label: "Chien de berger roumain de mioritza (Ciobanesc românesc mioritic)",
  },
  {
    key: "chien_berger_roumain_carpathes",
    label:
      "Chien de berger roumain des carpathes (Carpatin / Ciobanesc românesc carpatin)",
  },
  {
    key: "chien_berger_yougoslave_charplanina",
    label:
      "Chien de berger yougoslave de charplanina (Sarplaninac / Jugoslovenski ovcarski pas)",
  },
  {
    key: "chien_canaan",
    label: "Chien de canaan (Canaan dog)",
  },
  {
    key: "chien_castro_laboreiro",
    label: "Chien de castro laboreiro (Cao de castro laboreiro)",
  },
  {
    key: "chien_cour_italien",
    label: "Chien de cour italien (Cane corso)",
  },
  {
    key: "chien_ferme_dano_suedois",
    label: "Chien de ferme dano-suedois (Dansk-svensk gardshund)",
  },
  {
    key: "chien_garenne_canaries",
    label: "Chien de garenne des canaries (Podenco canario)",
  },
  {
    key: "chien_garenne_portugais",
    label: "Chien de garenne portugais (Podengo portuguès)",
  },
  {
    key: "chien_serra_da_estrela",
    label: "Chien de la serra da estrela (Estrela / Cao da serra da estrela)",
  },
  {
    key: "chien_leonberg",
    label: "Chien de leonberg (Leonberg / Leonberger)",
  },
  {
    key: "chien_montagne_atlas",
    label: "Chien de montagne de l'atlas (Aïdi)",
  },
  {
    key: "chien_montagne_pyrenees",
    label: "Chien de montagne des pyrenees (Patou)",
  },
  {
    key: "chien_perdrix_drente",
    label:
      "Chien de perdrix de drente (Epagneul de drente / Drentsche patrijshond)",
  },
  {
    key: "chien_rhodesie_crete_dorsale",
    label: "Chien de rhodesie a crete dorsale (Rhodesian ridgeback)",
  },
  {
    key: "chien_rouge_baviere",
    label:
      "Chien de rouge de baviere (Rouge de baviere / Bayrischer gebirgsschweisshund)",
  },
  {
    key: "chien_rouge_hanovre",
    label:
      "Chien de rouge du hanovre (Rouge du hanovre / Hannoverscher schweisshund)",
  },
  {
    key: "chien_saint_hubert",
    label: "Chien de saint hubert (Saint hubert)",
  },
  {
    key: "chien_taiwan",
    label: "Chien de taïwan (Taïwan dog)",
  },
  {
    key: "chien_groenland",
    label: "Chien du groenland (Esquimau du groenland / Gronlandshund)",
  },
  {
    key: "chien_pharaon",
    label: "Chien du pharaon (Pharaon / Pharaoh hound)",
  },
  {
    key: "chien_finnois_laponie",
    label: "Chien finnois de laponie (Lapinkoira / Suomenlapinkoira)",
  },
  {
    key: "chien_fonnese",
    label: "Chien fonnese (Cane fonnese)",
  },
  {
    key: "chien_loup_saarloos",
    label: "Chien loup de saarloos (Saarloos / Saarloos wolfhond)",
  },
  {
    key: "chien_n_f_chasse_raton",
    label:
      "Chien n. et f. pour la chasse au raton (Coonhound noir et feu / Black and tan coonhound)",
  },
  {
    key: "chien_norvegien_macareux",
    label: "Chien norvegien de macareux (Norsk lundehund)",
  },
  {
    key: "chien_nu_mexique",
    label: "Chien nu du mexique (Xolo / Xoloitzcuintle)",
  },
  {
    key: "chien_nu_perou",
    label: "Chien nu du perou (Perro sin pelo del peru)",
  },
  {
    key: "chien_particolore_poil_frise",
    label: "Chien particolore a poil frise (Particolore a poil frise)",
  },
  {
    key: "chien_thailandais_crete_dorsale",
    label:
      "Chien thailandais a crete dorsale (Thai ridgeback / Thai ridgeback dog)",
  },
  {
    key: "cirneco_etna",
    label: "Cirneco de l'etna (Cirneco de l'etna / Cirneco dell'etna)",
  },
  {
    key: "clumber_spaniel",
    label: "Clumber spaniel (Clumber)",
  },
  {
    key: "collie_poil_court",
    label: "Collie a poil court (Colley a poil court / Smooth colley)",
  },
  {
    key: "collie_poil_long",
    label: "Collie a poil long (Colley / Rough colley)",
  },
  {
    key: "coton_tulear",
    label: "Coton de tulear",
  },
  {
    key: "dandie_dinmont_terrier",
    label: "Dandie dinmont terrier (Dandie dinmont)",
  },
  {
    key: "dogue_bordeaux",
    label: "Dogue de bordeaux",
  },
  {
    key: "dogue_majorque",
    label: "Dogue de majorque (Ca de bou / Perro dogo mallorquin)",
  },
  {
    key: "dogue_canaries",
    label: "Dogue des canaries (Presa canario / Dogo canario)",
  },
  {
    key: "dogue_tibet",
    label: "Dogue du tibet (Do-khyi)",
  },
  {
    key: "english_springer_spaniel",
    label: "English springer spaniel (Springer anglais)",
  },
  {
    key: "epagneul_bleu_picardie",
    label: "Epagneul bleu de picardie (Bleu de picardie)",
  },
  {
    key: "epagneul_eau_irlandais",
    label:
      "Epagneul d'eau irlandais (Chien d'eau irlandais / Irish water spaniel)",
  },
  {
    key: "epagneul_pont_audemer",
    label: "Epagneul de pont audemer (Pont audemer)",
  },
  {
    key: "epagneul_saint_usuge",
    label: "Epagneul de saint usuge (Epagneul saint usuge)",
  },
  {
    key: "epagneul_francais",
    label: "Epagneul francais",
  },
  {
    key: "field_spaniel",
    label: "Field spaniel",
  },
  {
    key: "fila_saint_miguel",
    label:
      "Fila de saint miguel (Fila de sao miguel / Cao de fila de sao miguel)",
  },
  {
    key: "fox_hound_anglais",
    label: "Fox hound anglais (Foxhound anglais / English foxhound)",
  },
  {
    key: "fox_terrier_poil_dur",
    label: "Fox terrier poil dur (Fox a poil dur / Fox terrier wire)",
  },
  {
    key: "fox_terrier_poil_lisse",
    label: "Fox terrier poil lisse (Fox a poil lisse / Fox terrier smooth)",
  },
  {
    key: "foxhound_americain",
    label: "Foxhound americain (American foxhound)",
  },
  {
    key: "francais_blanc_noir",
    label: "Francais blanc et noir",
  },
  {
    key: "francais_blanc_orange",
    label: "Francais blanc et orange",
  },
  {
    key: "gascon_saintongeois",
    label: "Gascon saintongeois",
  },
  {
    key: "golden_retriever",
    label: "Golden retriever (Golden)",
  },
  {
    key: "grand_anglo_francais_blanc_noir",
    label: "Grand anglo francais blanc et noir (Grand anglo francais)",
  },
  {
    key: "grand_anglo_francais_blanc_orange",
    label: "Grand anglo-francais blanc et orange (Grand anglo-francais)",
  },
  {
    key: "grand_bleu_gascogne",
    label: "Grand bleu de gascogne",
  },
  {
    key: "grand_epagneul_munster",
    label:
      "Grand epagneul de munster (Grand munsterlander / Grosser münsterländer vorstehhund)",
  },
  {
    key: "griffon_poil_dur_korthals",
    label: "Griffon a poil dur korthals (Korthals)",
  },
  {
    key: "griffon_bleu_gascogne",
    label: "Griffon bleu de gascogne",
  },
  {
    key: "griffon_fauve_bretagne",
    label: "Griffon fauve de bretagne",
  },
  {
    key: "husky_siberie",
    label: "Husky de siberie (Husky siberien / Siberian husky)",
  },
  {
    key: "kelpie_australien",
    label: "Kelpie australien (Kelpie / Australian kelpie)",
  },
  {
    key: "laika_siberie_occidentale",
    label: "Laika de siberie occidentale (Zapadno sibirskaïa laïka)",
  },
  {
    key: "laika_siberie_orientale",
    label: "Laika de siberie orientale (Vostotchno sibirskaïa laïka)",
  },
  {
    key: "lakeland_terrier",
    label: "Lakeland terrier (Lakeland)",
  },
  {
    key: "malamute_alaska",
    label: "Malamute de l'alaska (Alaskan malamute)",
  },
  {
    key: "matin_alentejo",
    label: "Matin de l'alentejo (Rafeiro do alentejo)",
  },
  {
    key: "matin_pyrenees",
    label: "Matin des pyrenees (Mastin del pirineo)",
  },
  {
    key: "norfolk_terrier",
    label: "Norfolk terrier (Terrier de norfolk)",
  },
  {
    key: "norwich_terrier",
    label: "Norwich terrier (Terrier de norwich)",
  },
  {
    key: "petit_bleu_gascogne",
    label: "Petit bleu de gascogne",
  },
  {
    key: "petit_chien_hollandais_chasse_gibier_eau",
    label:
      "Petit chien hollandais de chasse au gibier d'eau (Chien hollandais de canardiere / Kooikerhondje)",
  },
  {
    key: "petit_epagneul_munster",
    label:
      "Petit epagneul de munster (Petit munsterlander / Kleiner münsterländer vorstehhund)",
  },
  {
    key: "podenco_ibiza",
    label: "Podenco d'ibiza (Podenco ibicenco)",
  },
  {
    key: "ratier_prague",
    label: "Ratier de prague (Prazsky krysarik)",
  },
  {
    key: "retriever_poil_boucle",
    label: "Retriever a poil boucle (Curly coated retriever)",
  },
  {
    key: "retriever_poil_plat",
    label: "Retriever a poil plat (Flat coated / Flat coated retriever)",
  },
  {
    key: "retriever_baie_chesapeake",
    label: "Retriever de la baie de chesapeake (Chesapeake bay retriever)",
  },
  {
    key: "retriever_nouvelle_ecosse",
    label:
      "Retriever de la nouvelle ecosse (Nova scotia / Nova scotia duck tolling retriever)",
  },
  {
    key: "retriever_labrador",
    label: "Retriever du labrador (Labrador / Labrador retriever)",
  },
  {
    key: "saint_bernard",
    label: "Saint bernard (St. bernhardshund)",
  },
  {
    key: "schnauzer",
    label: "Schnauzer (Schnauzer moyen)",
  },
  {
    key: "sealyham_terrier",
    label: "Sealyham terrier (Terrier de sealyham)",
  },
  {
    key: "setter_irlandais_rouge_blanc",
    label: "Setter irlandais rouge et blanc (Irish red and white setter)",
  },
  {
    key: "shar_pei",
    label: "Shar pei",
  },
  {
    key: "shiba",
    label: "Shiba (Shiba inu)",
  },
  {
    key: "shih_tzu",
    label: "Shih tzu",
  },
  {
    key: "smous_pays_bas",
    label: "Smous des pays bas (Smous / Hollandse smoushond)",
  },
  {
    key: "spitz_norrbotten",
    label: "Spitz de norrbotten (Norrbottenspets)",
  },
  {
    key: "spitz_visigoths",
    label: "Spitz des visigoths (Chiens des goths de l'ouest",
  },
  {
    key: "sussex_spaniel",
    label: "Sussex spaniel",
  },
  {
    key: "terrier_australien_poil_soyeux",
    label:
      "Terrier australien a poil soyeux (Silky terrier / Australian silky terrier)",
  },
  {
    key: "terrier_agrement_anglais_noir_feu",
    label:
      "Terrier d'agrement anglais noir et feu (Toy terrier / English toy terrier black and tan)",
  },
  {
    key: "terrier_boston",
    label: "Terrier de boston (Boston terrier)",
  },
  {
    key: "terrier_chasse_allemand",
    label: "Terrier de chasse allemand (Jagd terrier / Deutscher jagdterrier)",
  },
  {
    key: "terrier_reverend_russell",
    label: "Terrier du reverend russell (Parson / Parson russell terrier)",
  },
  {
    key: "terrier_irlandais_poil_doux",
    label:
      "Terrier irlandais a poil doux (Soft coated / Irish soft coated wheaten terrier)",
  },
  {
    key: "terrier_irlandais_glen_of_imaal",
    label:
      "Terrier irlandais glen of imaal (Glen of imaal / Irish glen of imaal terrier)",
  },
  {
    key: "welsh_terrier",
    label: "Welsh terrier (Terrier gallois)",
  },
];
