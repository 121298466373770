import { Form, Formik } from "formik";
import { Container } from "src/components/container";
import { DalmaInfo } from "src/components/dalma-info";
import { DalmaText } from "src/components/dalma-text";
import { Input } from "src/components/input";
import { InputCheckbox } from "src/components/input-checkbox";
import { Stack } from "src/components/stack";
import { SubmitButton } from "src/components/submit-button";
import { legal } from "src/config";

function isValidEmail(email: string) {
  const mailCheck =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return mailCheck.test(String(email).toLowerCase());
}

function isValidPhone(phoneNumber: string) {
  const numCheck =
    /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;
  return numCheck.test(String(phoneNumber).toLowerCase());
}

function validate(values: {
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  userCondition: boolean;
}) {
  const errors: {
    email?: string;
    phoneNumber?: string;
    firstName?: string;
    lastName?: string;
    userConditions?: string;
  } = {};
  if (!values.firstName) {
    errors.firstName = "Prénom manquant";
  }
  if (!values.lastName) {
    errors.lastName = "Prénom manquant";
  }
  if (!isValidEmail(values.email)) {
    errors.email = "Veuillez entrer un email valide";
  }
  if (!isValidPhone(values.phoneNumber)) {
    errors.phoneNumber = "Veuillez entrer un numéro de téléphone valide";
  }
  if (!values.userCondition) {
    errors.userConditions = "Veuillez cocher cette case";
  }
  return errors;
}

export function OwnerInfo({
  petsName = [],
  firstName = "",
  lastName = "",
  email = "",
  phoneNumber = "",
  userCondition = false,
  nextStep,
  backLink,
}: any) {
  let petsNames = "";

  if (petsName.length === 1) {
    petsNames = petsName[0];
  } else if (petsName.length === 2) {
    petsNames = petsName[0] + " et " + petsName[1];
  } else {
    for (let i = 0; i < petsName.length; i++) {
      if (i === 0) {
        petsNames = petsName[i];
      } else if (i + 1 === petsName.length) {
        petsNames = petsNames + " et " + petsName[i];
      } else {
        petsNames = petsNames + ", " + petsName[i];
      }
    }
  }
  return (
    <Container backLink={backLink} stepPercentage={50}>
      <Formik
        initialValues={{
          firstName,
          lastName,
          email,
          phoneNumber,
          userCondition,
        }}
        validate={validate}
        validateOnMount
        onSubmit={nextStep}
      >
        {({ values, isValid, isSubmitting }) => (
          <Form>
            <Stack>
              <div
                style={{
                  opacity: values.firstName && values.lastName ? "70%" : "100%",
                }}
              >
                <Stack>
                  <DalmaText image>
                    Très bien. Passons à vous. À quel nom dois-je mettre
                    l'abonnement ?
                  </DalmaText>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      boxSizing: "border-box",
                      columnGap: "5%",
                    }}
                  >
                    <Input name="firstName" label="Votre prénom *" />
                    <Input name="lastName" label="Votre nom *" />
                  </div>
                </Stack>
              </div>

              {values.firstName && values.lastName && (
                <div
                  style={{
                    opacity:
                      values.email && values.phoneNumber ? "70%" : "100%",
                  }}
                >
                  <Stack>
                    <DalmaText image>
                      Où puis-je envoyer votre contrat ?
                    </DalmaText>
                    <Input
                      name="email"
                      label="Votre adresse email *"
                      type="email"
                    />
                    <Input
                      name="phoneNumber"
                      label="Votre numéro de téléphone *"
                      inputMode="tel"
                    />
                    <DalmaInfo>
                      Les données recueillies via ce formulaire sont
                      enregistrées par OLLIE (« Dalma ») afin de vous fournir un
                      devis personnalisé et d’être contacté par un conseiller
                      pour vous accompagner dans la construction de votre
                      formule.
                    </DalmaInfo>
                  </Stack>
                </div>
              )}

              {values.email &&
                values.phoneNumber &&
                isValidEmail(values.email) &&
                isValidPhone(values.phoneNumber) && (
                  <Stack>
                    <DalmaText image>
                      Il ne manque plus que votre autorisation.
                    </DalmaText>

                    <InputCheckbox
                      name="userCondition"
                      checked={values.userCondition}
                    >
                      <div style={{ marginLeft: 8, fontSize: 14 }}>
                        <span style={{ display: "block", marginBottom: 8 }}>
                          J'accepte de partager mes informations à dalma.blue,
                          et de recevoir des conseils d'experts pour mieux
                          prendre soin de mon compagnon (
                          <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href={legal.v2.policy}
                          >
                            en savoir plus
                          </a>{" "}
                          sur la gestion de vos données et vos droits). *
                        </span>
                      </div>
                    </InputCheckbox>
                  </Stack>
                )}

              <SubmitButton
                isLoading={isSubmitting}
                isValid={isValid}
                backLink={backLink}
              >
                Suivant
              </SubmitButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
