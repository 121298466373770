import { CalculatedPrice } from "src/types";

export function getPrice({
  prices,
  price,
  prevention,
  death,
}: CalculatedPrice) {
  let total = price;

  const { prevention: preventionPrice, death: deathPrice } =
    prices.prices[prices.recommended];
  if (prevention) {
    total += preventionPrice;
  }
  if (death) {
    total += deathPrice;
  }

  return total;
}

export type PriceValueType = {
  value: string;
  recommended: boolean;
};

export function getPriceValues(data: any) {
  const rates: PriceValueType[] = [];
  const coverages: PriceValueType[] = [];

  const { prices = {}, recommended = "" } = data;
  const [recommendedRate, recommendedCoverage] = recommended.split("-");
  const keys = Object.keys(prices);

  for (const key of keys) {
    const [currentRate, currentCoverage] = key.split("-");

    const rateExist = rates.find(
      (rate: PriceValueType) => rate.value === currentRate
    );
    if (!rateExist) {
      rates.push({
        value: currentRate,
        recommended: currentRate === recommendedRate,
      });
    }

    const coverageExist = coverages.find(
      (coverage: PriceValueType) => coverage.value === currentCoverage
    );
    if (!coverageExist) {
      coverages.push({
        value: currentCoverage,
        recommended: currentCoverage === recommendedCoverage,
      });
    }
  }

  coverages.sort(({ value: valueA }, { value: valueB }) =>
    valueA.padStart(4, "0").localeCompare(valueB.padStart(4, "0"))
  );
  rates.sort(({ value: valueA }, { value: valueB }) =>
    valueA.padStart(4, "0").localeCompare(valueB.padStart(4, "0"))
  );
  return { rates, coverages };
}
