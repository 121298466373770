export const SANDBOX = process.env.REACT_APP_PRODUCTION !== "true";

export const STRIPE_PUB_KEY = SANDBOX
  ? "pk_test_51JhZqeJCpXVJLyaUwKGJq3MSmkR4QUWVI46hsGZAa7ZmtB1PfaxJFPjRRZHmAlsaHjtjSz4MCdNhdPfJEMq2VFgl00cMPSJKGe" // sandbox key
  : "pk_live_51JhZqeJCpXVJLyaUXpAf2u7RbgzxVqwRaEZzW0aM7ygigBP3nX46AKkhnZnuJauJI0umC7NgNou23OUl5kV4bwiN00JLt71OW4"; // prod env

export const CustomerSpaceUrl = SANDBOX
  ? "https://app.dev.dalma.blue"
  : "https://app.dalma.blue";

export const legal = {
  v1: {
    cgu: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma_blue/v1/dalma.blue+-+Conditions+Generales+d'Utilisation.pdf",
    policy:
      "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma_blue/v1/dalma.blue+-+Politique+de+Confidentialite.pdf",
    ipid: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma_blue/v1/dalma.blue+-+IPID.pdf",
    cg: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma_blue/v1/dalma.blue+-+Conditions+Generales.pdf",
  },
  v2: {
    ipid: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma_blue/v2/dalma.blue+-+IPID.pdf",
    policy:
      "https://editique.dalma.co/formule_dalma_blue/v2/dalma.blue-Politique-de-confidentialite.pdf",
  },
};

export const BOOK_MEETING =
  "https://calendly.com/expert-dalma/tout-comprendre-sur-dalma-blue";

export const DISABLE_BANNER = false;
