import { Form, Formik } from "formik";
import { Container } from "src/components/container";
import { DalmaInfo } from "src/components/dalma-info";
import { DalmaText } from "src/components/dalma-text";
import { Input } from "src/components/input";
import { Stack } from "src/components/stack";
import { SubmitButton } from "src/components/submit-button";

function validate({ petName }: { petName: string }) {
  const errors: { petName?: string } = {};
  if (!petName || petName.length < 2) {
    errors.petName = "Veuillez renseigner un nom";
  }
  return errors;
}

function getCount(index: number) {
  if (index === 1) {
    return "deuxième";
  }
  if (index === 2) {
    return "troisième";
  }
  return `${index + 1}-ième`;
}

export function PetName({
  name = "",
  nextStep,
  index,
}: {
  index: number;
  name?: string;
  nextStep: (petName: string) => void;
}) {
  return (
    <Container stepPercentage={0}>
      <Formik
        initialValues={{ petName: name }}
        validate={validate}
        validateOnMount
        onSubmit={async (values) => {
          await nextStep(values.petName);
        }}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <Stack direction="column">
              {index === 0 ? (
                <>
                  <DalmaText image>
                    Bonjour, je suis Salomé de dalma.blue. Je vais vous
                    accompagner dans votre inscription à dalma.blue !
                  </DalmaText>
                  <DalmaText>
                    Comment s'appelle votre boule de poils ?
                  </DalmaText>
                  <Input label="Nom de votre animal" name="petName" />
                  <SubmitButton isLoading={isSubmitting} isValid={isValid}>
                    Commencer
                  </SubmitButton>
                  <DalmaInfo>
                    Vous avez plusieurs animaux ? Aucun problème, vous pourrez
                    nous parler d’eux ensuite.
                  </DalmaInfo>
                </>
              ) : (
                <>
                  <DalmaText image>
                    Comment s’appelle votre {getCount(index)} animal ?
                  </DalmaText>
                  <Input label="Nom de votre animal" name="petName" />
                  <SubmitButton isLoading={isSubmitting} isValid={isValid}>
                    Suivant
                  </SubmitButton>
                  <DalmaInfo>Vous bénéficierez de 10% de réduction !</DalmaInfo>
                </>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
