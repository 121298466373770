import React from "react";
import { DownloadButton } from "../components/DownloadButton";
import { legal, CustomerSpaceUrl } from "../config";
import LogoDalmaBlue from "src/components/blue-logo_dalma_blue.svg";

import { Button } from "src/components/button";
import { DalmaInfo } from "src/components/dalma-info";

export const Welcome = () => {
  const params = new URLSearchParams(window.location.search);
  const email = params.get("email");

  return (
    <div style={{ padding: 5 }}>
      <img src={LogoDalmaBlue} alt="" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: 552,
            textAlign: "center",
          }}
        >
          <h1
            style={{ fontSize: 32, color: "#4E68F6", fontFamily: "Roslindale" }}
          >
            Bienvenue chez dalma.blue
          </h1>
          <p>{`Nous sommes ravis de vous accueillir parmi nous !`}</p>
          <p>
            Nous vous avons envoyé un email récapitulatif {email && "à "}{" "}
            <span style={{ color: "#4E68F6" }}>{email}</span> avec votre contrat
            en pièce jointe et un lien de connexion vers votre espace client.
          </p>
          <p
            style={{
              color: "#4E68F6",
              fontWeight: "bold",
              marginBottom: 50,
              fontFamily: "Roslindale",
            }}
          >
            <DalmaInfo>
              Pas de trace de mail ? Pensez à vérifier dans vos spams.
            </DalmaInfo>
          </p>
          <Button
            onClick={() =>
              window.open(`${CustomerSpaceUrl}/first-connection`, "_blank")
            }
            style={{ backgroundColor: "#4E68F6" }}
          >
            <div
              style={{
                display: "flex",
                minWidth: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ margin: 0, color: "#FFFFFF", fontWeight: 400 }}>
                {"Me connecter à mon espace client"}
              </p>
            </div>
          </Button>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: 100 }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1 style={{ textAlign: "center", fontSize: 25, color: "#212121" }}>
            Autre documents
          </h1>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <DownloadButton
              href={legal.v2.ipid}
              title="Fiche IPID"
              description="PDF"
            />
            <DownloadButton
              href={legal.v1.cg}
              title="Conditions générales"
              description="PDF"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
