import { Form, Formik } from "formik";
import { DalmaText } from "src/components/dalma-text";
import { SubmitButton } from "src/components/submit-button";
import { Home, Pet } from "src/types";
import { Input } from "src/components/input";
import { DalmaInfo } from "src/components/dalma-info";
import { Container } from "src/components/container";
import { Stack } from "src/components/stack";
import { Location } from "src/utils/url-builder";

type Errors = {
  habitat?: string;
  number?: string;
  address?: string;
  zipcode?: string;
  city?: string;
  country?: string;
};

function validate(home: Home) {
  const errors: Errors = {};
  if (!home.number) {
    errors.number = "Veuillez indiquer votre numéro de rue";
  }
  if (!home.address) {
    errors.address = "Veuillez indiquer votre adresse";
  }
  if (!home.city) {
    errors.city = "Veuillez indiquer votre ville";
  }
  if (!home.zipcode) {
    errors.zipcode = "Veuillez indiquer votre code postale";
  }
  return errors;
}

export function HomeAddress({
  pets,
  number,
  address = "",
  zipcode = "",
  city = "",
  country = "",
  nextStep,
  backLink,
}: {
  pets: Pet[];
  number?: number;
  address?: string;
  zipcode?: string;
  city?: string;
  country?: string;
  nextStep: (home: Home) => void;
  backLink: Location;
}) {
  return (
    <Container backLink={backLink} largePadding stepPercentage={75}>
      <Formik
        initialValues={{
          number,
          address,
          zipcode,
          city,
          country,
        }}
        validate={validate}
        validateOnMount
        onSubmit={(values) => {
          nextStep(values);
        }}
      >
        {({ values, isValid, isSubmitting }) => (
          <Form>
            <Stack>
              <DalmaText image>
                Dernière question ! Où vivez-vous actuellement ?
              </DalmaText>
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    boxSizing: "border-box",
                    columnGap: "5%",
                  }}
                >
                  <div style={{ flex: 1.5, maxWidth: 125 }}>
                    <Input
                      name="number"
                      label="Nº de rue *"
                      inputMode="tel"
                      alt="Numéro de rue"
                    />
                  </div>
                  <div style={{ flex: 2 }}>
                    <Input name="address" label="Votre addresse *" />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    boxSizing: "border-box",
                    columnGap: "5%",
                  }}
                >
                  <div style={{ flex: 1.5, maxWidth: 125 }}>
                    <Input
                      name="zipcode"
                      label="Code postal *"
                      inputMode="numeric"
                    />
                  </div>
                  <div style={{ flex: 2 }}>
                    <Input name="city" label="Votre ville *" />
                  </div>
                </div>
                <DalmaInfo>
                  On vous rassure, ça ne change rien au prix. C'est simplement
                  pour établir le contrat... et vous envoyer des petites
                  surprises 🎁
                </DalmaInfo>
              </>
            </Stack>

            <SubmitButton
              isLoading={isSubmitting}
              isValid={isValid}
              backLink={backLink}
            >
              Suivant
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
