import { useEffect, useState } from "react";
import { Location } from "history";
import { Redirect, useHistory, useLocation } from "react-router";
import { fetchQuoteInfo, getQuoteId, sendUtmToBack } from "src/utils/backend";
import * as localStorageUtils from "src/utils/storage";
import { Data } from "src/types";
import * as urlBuilder from "src/utils/url-builder";
import { Error } from "src/components/error";
import { INIT } from "./quote";

function hasUtm(utmParams: any) {
  if (
    utmParams.utm_source ||
    utmParams.utm_medium ||
    utmParams.utm_campaign ||
    utmParams.utm_content ||
    utmParams.utm_term
  )
    return true;
  return false;
}

async function redirectMarketing(location: Location<any>, petName?: string) {
  const searchParams = new URLSearchParams(location.search);

  const referrer = searchParams.get("utm_referrer") || document.referrer || "";
  console.log("referrer url :: ", referrer);

  const utmParams = {
    utm_source: searchParams.get("utm_source") || "",
    utm_medium: searchParams.get("utm_medium") || "",
    utm_campaign: searchParams.get("utm_campaign") || "",
    utm_content: searchParams.get("utm_content") || "",
    utm_term: searchParams.get("utm_term") || "",
  };

  const response = await getQuoteId();
  const dealId = response.data.data.quote_id;
  const data = {
    ...INIT,
    quoteId: dealId,
  };
  if (petName && data.pets[0]) {
    data.pets[0].name = petName;
  }
  localStorageUtils.setItem("quote", JSON.stringify(data));

  if (hasUtm(utmParams) || referrer) {
    await sendUtmToBack(dealId, utmParams, referrer);
  }
  return;
}

export function RedirectMarketing() {
  const location = useLocation();
  const [state, setState] = useState("loading");
  const searchParams = new URLSearchParams(location.search);
  const petName = searchParams.get("petName") || "";
  useEffect(() => {
    // TODO partial clean
    localStorageUtils.clear();
    redirectMarketing(location, petName).then(
      () => setState("success"),
      () => setState("error")
    );
  }, [location, petName]);
  if (state === "success") {
    return <Redirect to="/quote" />;
  }
  return null;
}

export function RedirectEmail() {
  let history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const quoteIdParams = searchParams.get("quote_id") || "";
  const [error, setError] = useState(false);

  useEffect(() => {
    localStorageUtils.clear();
    fetchQuoteInfo(quoteIdParams).then(
      (values: Data) => {
        localStorage.setItem("quote", JSON.stringify(values));
        history.push(urlBuilder.tribeQuote());
      },
      (e) => {
        console.error(e);
        setError(true);
      }
    );
  }, [quoteIdParams, history]);

  if (error) {
    return <Error />;
  }

  return <Loader />;
}

export const RedirectMeilleurTaux = RedirectEmail;

export const RedirectHyperassur = RedirectEmail;

function Loader() {
  return null;
}
