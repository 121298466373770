import { Form, Formik } from "formik";
import { Container } from "src/components/container";
import { DalmaInfo } from "src/components/dalma-info";
import { DalmaText } from "src/components/dalma-text";
import { Input } from "src/components/input";
import { InputRadio } from "src/components/input-radio";
import { Stack } from "src/components/stack";
import { SubmitButton } from "src/components/submit-button";
import { Pet } from "src/types";
import { Location } from "src/utils/url-builder";

type Values = {
  uuid_type: string;
  uuid: string;
};
type Errors = {
  uuid_type?: string;
  uuid?: string;
};

function validate(values: Values, pets: Pet[], petIdx: number): Errors {
  const errors: Errors = {};

  if (values.uuid_type === "later") {
    errors.uuid_type = "Veuillez selectionner une option valide";
  } else if (!values.uuid_type) {
    errors.uuid_type = "Veuillez selectionner une option";
  } else if (!values.uuid) {
    errors.uuid = "Veuillez entrer le numéro d'identification de l'animal";
  } else if (
    pets.find((pet) => pet.uuid === values.uuid && pet.idx !== petIdx)
  ) {
    errors.uuid =
      "Vous ne pouvez pas utiliser le même numéro d'identification pour vos animaux";
  }
  return errors;
}

export function PetIdentification({
  pets,
  petIdx,
  name,
  sex,
  uuid,
  uuid_type,
  nextStep,
  backLink,
}: Pick<Pet, "name" | "sex"> & {
  pets: Pet[];
  petIdx: number;
  uuid: string;
  uuid_type: string;
  nextStep: (values: Pick<Pet, "uuid" | "uuid_type">) => Promise<void>;
  backLink: Location;
}) {
  return (
    <Container backLink={backLink} largePadding stepPercentage={37.5}>
      <Formik
        initialValues={{ uuid_type, uuid }}
        validate={(values) => validate(values, pets, petIdx)}
        validateOnMount
        onSubmit={nextStep}
      >
        {({ values, isValid, isSubmitting, errors }) => (
          <Form>
            <Stack>
              <DalmaText image>
                {`${petIdx > 0 ? "Et " : ""} ${name} est-${
                  sex === "male" ? "il" : "elle"
                } pucé${sex === "male" ? "" : "e"} ou tatoué${
                  sex === "male" ? "" : "e"
                } ?`}
              </DalmaText>
              <InputRadio
                name="uuid_type"
                value="chip"
                icon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.25 6c.414 0 .75.336.75.75v9.5c0 .414-.336.75-.75.75h-9.5c-.414 0-.75-.336-.75-.75v-9.5c0-.414.336-.75.75-.75h9.5zm2.75 0c0-1.104-.896-2-2-2h-11c-1.104 0-2 .896-2 2v11c0 1.104.896 2 2 2h11c1.104 0 2-.896 2-2v-11zm-11 14v3h-1v-3h1zm4 0v3h-1v-3h1zm2 0v3h-1v-3h1zm-4 0v3h-1v-3h1zm6 0v3h-1v-3h1zm-8-20v3h-1v-3h1zm4 0v3h-1v-3h1zm2 0v3h-1v-3h1zm-4 0v3h-1v-3h1zm6 0v3h-1v-3h1zm4 15h3v1h-3v-1zm0-4h3v1h-3v-1zm0-2h3v1h-3v-1zm0 4h3v1h-3v-1zm0-6h3v1h-3v-1zm-20 8h3v1h-3v-1zm0-4h3v1h-3v-1zm0-2h3v1h-3v-1zm0 4h3v1h-3v-1zm0-6h3v1h-3v-1z"
                      fill="currentColor"
                    />
                  </svg>
                }
                label={sex === "male" ? "Pucé" : "Pucée"}
              />
              <InputRadio
                name="uuid_type"
                value="tatoo"
                icon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 18v-12h2v12h-2zm4 0v-12h2v12h-2zm4 0v-12h1v12h-1zm8 0v-12h1v12h-1zm-5 0v-12h3v12h-3zm8 0v-12h2v12h-2zm4 0v-12h1v12h-1z"
                      fill="currentColor"
                    />
                  </svg>
                }
                label={sex === "male" ? "Tatoué" : "Tatouée"}
              />
              {(values.uuid_type === "chip" ||
                values.uuid_type === "tatoo") && (
                <>
                  <DalmaText image>
                    Quel est son numéro d’identification ?
                  </DalmaText>
                  <Input
                    value={values.uuid}
                    name="uuid"
                    label="Numéro d’identification"
                  />
                  {errors.uuid ===
                    "Vous ne pouvez pas utiliser le même numéro d'identification pour vos animaux" && (
                    <DalmaInfo error>{errors.uuid}</DalmaInfo>
                  )}
                  <DalmaInfo>
                    Un doute ? Ce numéro se trouve dans son carnet de santé.
                  </DalmaInfo>
                </>
              )}
            </Stack>

            <SubmitButton
              isLoading={isSubmitting}
              isValid={isValid}
              backLink={backLink}
            >
              Suivant
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
