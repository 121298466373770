import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getItem, setItem } from "src/utils/storage";

export function useStorage<T>(
  key: string,
  initialValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState(() => {
    try {
      const item = getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // console.log(error);
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log("error :: ", error);
    }
  }, [key, value]);

  return [value, setValue];
}
