import cat from "./cat.svg";
import dog from "./dog.svg";
import { useField } from "formik";
import { ComponentProps, ReactNode } from "react";
import { styled } from "@stitches/react";
import { theme } from "src/style/theme";

type _InputType = {
  children: ReactNode;
  // Name and value is required
  name: string;
  value: "cat" | "dog";
} & Omit<ComponentProps<"input">, "type">;

const Span = styled("span", {
  position: "absolute",
  right: "3px",
  top: "3px",
  color: theme.colors.electric,
});

const Text = styled("div", {
  position: "relative",
  height: "28px",
  fontFamily: "Roslindale",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "20px",
  lineHeight: "28px",
  textAlign: "center",
  color: theme.colors.textColor,
  top: "65px",

  variants: {
    value: {
      cat: {
        left: "25%",
      },
      dog: {
        left: "-25%",
      },
    },
    checked: {
      true: { color: theme.colors.electric },
      false: {},
    },
  },
});

const Img = styled("img", {
  position: "absolute",

  variants: {
    value: {
      cat: {
        width: "88px",
        height: "88px",
        left: "calc(30% - 30px)",
        top: "58px",
        objectFit: "cover",
      },
      dog: {
        width: "138px",
        height: "138px",
        right: "calc(20% - 40px)",
        top: "16px",
        objectFit: "cover",
      },
    },
  },
});

const Label = styled("label", {
  position: "relative",
  height: "146px",
  background: theme.colors.white,
  display: "block",
  boxShadow: "0 0 0 1px " + theme.colors.inputBlueBorder,
  overflow: "hidden",
  borderRadius: "16px",
  flex: "1 0 0",
  order: 0,
  cursor: "pointer",
  flexGrow: 1,
  variants: {
    checked: {
      true: {
        boxShadow: "0 0 0 3px " + theme.colors.electric,
        background: theme.colors.blueBackGround,
      },
    },
  },
});

export function InputType({ children, name, value, onChange }: _InputType) {
  const [field] = useField({ name, type: "radio", value });

  return (
    <Label checked={field.checked}>
      {field.checked && (
        <Span>
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.22 8.79L10.93 13.09L9.28 11.44C9.19036 11.3353 9.08004 11.2503 8.95597 11.1903C8.83191 11.1303 8.69678 11.0965 8.55906 11.0912C8.42134 11.0859 8.28401 11.1091 8.15568 11.1594C8.02736 11.2096 7.91081 11.2859 7.81335 11.3833C7.7159 11.4808 7.63964 11.5974 7.58937 11.7257C7.53909 11.854 7.51589 11.9913 7.52121 12.1291C7.52653 12.2668 7.56026 12.4019 7.62028 12.526C7.6803 12.65 7.76532 12.7604 7.87 12.85L10.22 15.21C10.3134 15.3027 10.4243 15.376 10.5461 15.4258C10.6679 15.4755 10.7984 15.5008 10.93 15.5C11.1923 15.4989 11.4437 15.3947 11.63 15.21L16.63 10.21C16.7237 10.117 16.7981 10.0064 16.8489 9.88458C16.8997 9.76272 16.9258 9.63201 16.9258 9.5C16.9258 9.36799 16.8997 9.23728 16.8489 9.11542C16.7981 8.99356 16.7237 8.88296 16.63 8.79C16.4426 8.60375 16.1892 8.49921 15.925 8.49921C15.6608 8.49921 15.4074 8.60375 15.22 8.79ZM12.5 2C10.5222 2 8.58879 2.58649 6.9443 3.6853C5.29981 4.78412 4.01809 6.3459 3.26121 8.17317C2.50433 10.0004 2.3063 12.0111 2.69215 13.9509C3.078 15.8907 4.03041 17.6725 5.42894 19.0711C6.82746 20.4696 8.60929 21.422 10.5491 21.8079C12.4889 22.1937 14.4996 21.9957 16.3268 21.2388C18.1541 20.4819 19.7159 19.2002 20.8147 17.5557C21.9135 15.9112 22.5 13.9778 22.5 12C22.5 10.6868 22.2413 9.38642 21.7388 8.17317C21.2363 6.95991 20.4997 5.85752 19.5711 4.92893C18.6425 4.00035 17.5401 3.26375 16.3268 2.7612C15.1136 2.25866 13.8132 2 12.5 2ZM12.5 20C10.9178 20 9.37104 19.5308 8.05544 18.6518C6.73985 17.7727 5.71447 16.5233 5.10897 15.0615C4.50347 13.5997 4.34504 11.9911 4.65372 10.4393C4.9624 8.88743 5.72433 7.46197 6.84315 6.34315C7.96197 5.22433 9.38743 4.4624 10.9393 4.15372C12.4911 3.84504 14.0997 4.00346 15.5615 4.60896C17.0233 5.21447 18.2727 6.23984 19.1518 7.55544C20.0308 8.87103 20.5 10.4177 20.5 12C20.5 14.1217 19.6572 16.1566 18.1569 17.6569C16.6566 19.1571 14.6217 20 12.5 20Z"
              fill="currentColor"
            />
          </svg>
        </Span>
      )}
      <div style={{ position: "relative" }}>
        <Img src={value === "cat" ? cat : dog} value={value} />
        <input
          type="radio"
          style={{
            display: "none",
          }}
          {...field}
          onChange={(e) => {
            field.onChange(e);
            if (typeof onChange === "function") {
              onChange(e);
            }
          }}
        />
        <Text value={value} checked={field.checked}>
          {children}
        </Text>
      </div>
    </Label>
  );
}
