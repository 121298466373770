import { Form, Formik } from "formik";
import { useEffect, useLayoutEffect } from "react";
import { Container } from "src/components/container";
import { DalmaInfo } from "src/components/dalma-info";
import { DalmaText } from "src/components/dalma-text";
import { Loading } from "src/components/loading";
import {
  AddPet,
  displayPrice,
  isPromotedPet,
  Pet,
  PetRow,
} from "src/components/pet";
import { Stack } from "src/components/stack";
import { SubmitButton } from "src/components/submit-button";

import { Pet as PetType } from "src/types";
import { Location } from "src/utils/url-builder";

export function getTotalPrice(pets: PetType[]) {
  let totalPrice: number | null = null;

  if (pets.every((p) => p.selectedFormula)) {
    totalPrice = pets.reduce((acc, p) => {
      return (
        acc +
        (isPromotedPet(p.idx, pets) ? 0.9 : 1) * p.selectedFormula!.totalPrice
      );
    }, 0);
  }
  return totalPrice;
}

export function TribeQuote({
  quoteId,
  pets,
  updatePrices,
  addPet,
  editPet,
  deletePet,
  nextStep,
  openPricing,
  dropDrafts,
  backLink,
}: {
  quoteId: string;
  pets: Array<PetType>;
  updatePrices: any;
  addPet: any;
  editPet: any;
  deletePet: any;
  nextStep: any;
  openPricing: (idx: number) => void;
  dropDrafts: () => void;
  backLink?: Location;
}) {
  useLayoutEffect(() => {
    if (pets.some((p) => p.draft)) {
      dropDrafts();
      return;
    }
  }, [pets, dropDrafts]);

  useEffect(() => {
    if (!pets.every((p) => p.pricing != null)) {
      updatePrices();
    }
  }, [pets, updatePrices]);

  if (
    pets.some((p) => p.draft) ||
    !pets.every((p) => p.selectedFormula != null)
  ) {
    return <Loading />;
  }

  const isDeferred = pets.some((p) => p.uuid_type?.includes("later"));

  const totalPrice = getTotalPrice(pets);

  return (
    <Container backLink={backLink} stepPercentage={37.5}>
      <Stack>
        <DalmaText image>
          Vous êtes en bonne compagnie 😊
          <br />
          Un autre animal à ajouter ?
        </DalmaText>
        <PetRow>
          {pets.map((pet) => {
            const isPromoted = isPromotedPet(pet.idx, pets);
            return (
              <Pet
                key={pet.idx}
                pet={pet}
                editPet={() => editPet(pet.idx)}
                deletePet={() => deletePet(pet.idx)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      margin: "8px 0px 16px 0px",
                      display: "flex",
                      alignItems: "center",
                      lineHeight: "24px",
                    }}
                  >
                    {isPromoted ? (
                      <span
                        style={{
                          fontSize: 12,
                          lineHeight: "12px",
                          textDecorationLine: "line-through",
                          /* Grey/600 */
                          color: "#757575",
                          fontWeight: 700,
                        }}
                      >
                        {displayPrice(pet.selectedFormula!.totalPrice)}€
                      </span>
                    ) : null}
                    <p
                      style={{
                        fontSize: 25,
                        margin: "0px 0px 0px 6px",
                        color: "#4E68F6",
                        fontWeight: "bold",
                      }}
                    >
                      {displayPrice(
                        pet.selectedFormula!.totalPrice * (isPromoted ? 0.9 : 1)
                      )}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        color: "#4E68F6",
                        fontWeight: "bold",
                        fontSize: 15,
                      }}
                    >
                      €
                    </p>
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        fontSize: 15,
                      }}
                    >
                      /mois
                    </p>
                    <span style={{ display: "inline-block", width: 8 }} />
                  </div>
                </div>
              </Pet>
            );
          })}
          {isDeferred ? null : <AddPet onClick={addPet} />}
        </PetRow>
        <div style={{ marginTop: "50px" }}>
          <DalmaInfo>
            Bénéficiez de 10% de réduction sur les abonnements des animaux
            supplémentaires
          </DalmaInfo>
        </div>

        <Formik initialValues={{}} onSubmit={nextStep}>
          {({ isSubmitting }) => (
            <Form>
              <SubmitButton
                isValid={totalPrice !== null && totalPrice !== 0}
                isLoading={isSubmitting}
                backLink={backLink}
              >
                <span style={{ fontWeight: "bold" }}>
                  {totalPrice === null ? (
                    "Continuer"
                  ) : (
                    <>Étape suivante • {displayPrice(totalPrice)} €/mois</>
                  )}
                </span>
              </SubmitButton>
            </Form>
          )}
        </Formik>
      </Stack>
    </Container>
  );
}
