import { useField } from "formik";
import { ComponentProps, ReactNode, useEffect } from "react";
import { styled } from "@stitches/react";
import { theme } from "src/style/theme";

type InputProps = {
  // Name is required
  name: string;
  label?: string;
  icon?: ReactNode;
  type?: string;
} & Omit<ComponentProps<"input">, "type">;

const Span = styled("span", {
  color: theme.colors.textColor,
  justifyContent: "center",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  variants: {
    variant: {
      icon: {
        color: theme.colors.blue800,
        marginRight: 8,
      },
    },
    active: {
      true: {
        color: theme.colors.electric,
      },
    },
    checkbox: {
      true: {
        fontSize: "15px",
      },
    },
  },
  compoundVariants: [
    {
      variant: "icon",
      active: true,
      css: {
        color: theme.colors.electric,
      },
    },
  ],
});

const Label = styled("label", {
  position: "static",
  display: "flex",
  alignItems: "center",
  height: "48px",
  left: "0px",
  top: "0px",
  cursor: "pointer",
  background: theme.colors.white,
  boxShadow: "0 0 0 1px " + theme.colors.inputBlueBorder,
  overflow: "hidden",
  borderRadius: "16px",
  padding: "0 16px",
  width: "100%",

  variants: {
    checked: {
      true: {
        boxShadow: "0 0 0 3px " + theme.colors.electric,
        background: theme.colors.blueBackGround,
      },
    },
    center: {
      true: {
        justifyContent: "center",
      },
    },
  },
});

export function InputRadio({
  name,
  value,
  label,
  icon,
  type = "radio",
  onChange,
}: InputProps) {
  const [field] = useField({ name, type: type, value });
  useEffect(() => {});
  return (
    <Label checked={field.checked} center={name === "sex"}>
      <input
        type={type}
        style={{
          display: "none",
        }}
        {...field}
        onChange={(e) => {
          field.onChange(e);
          if (typeof onChange === "function") {
            onChange(e);
          }
        }}
      />
      {icon ? (
        <Span active={field.checked} variant="icon">
          {icon}
        </Span>
      ) : null}
      <Span active={field.checked} checkbox={type === "checkbox"}>
        {label}
      </Span>
    </Label>
  );
}
