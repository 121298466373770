import { ReactNode } from "react";
import { VariantProps } from "@stitches/react";
import { theme, css, styled } from "src/style/theme";

import image from "./salome.png";

const text = css({
  variants: {
    image: {
      true: {},
    },
  },
});

const ImgWrapper = styled("div", {
  position: "relative",
  marginRight: 16,
});

const Img = styled("img", {
  width: 32,
  height: 32,
  borderRadius: 9999,
});

const Wrapper = styled("div", {
  padding: 16,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  border: `1px solid ${theme.colors.blueBackGround}`,
  background: theme.colors.blueBackGround,
  borderRadius: 16,
  boxSizing: "border-box",
  fontSize: 15,
  lineHeight: "21px",
  "@bp1": {
    fontSize: 16,
  },
});

const Dot = styled("span", {
  position: "absolute",
  top: 23,
  left: 23,
  width: 11,
  height: 11,
  border: `2px solid ${theme.colors.white}`,
  display: "inline-block",
  backgroundColor: "green",
  borderRadius: "50%",
});

export function DalmaText({
  children,
  ...props
}: { children: ReactNode } & VariantProps<typeof text>) {
  return (
    <Wrapper>
      {props.image ? (
        <ImgWrapper>
          <Dot />
          <Img src={image} />
        </ImgWrapper>
      ) : null}
      {children}
    </Wrapper>
  );
}
