import { format } from "date-fns";
import { toast } from "react-toastify";
import redaxios from "redaxios";
import { SANDBOX } from "src/config";
import { Data, Pet } from "src/types";
import { getPrice } from "./pricing";
import { redirectToCheckout } from "./stripe";
import { getUserId, toApi } from "./to-api";

const LATEST = "dalma-blue-latest";
const PROD = "dalma-blue";

const leadAPI = redaxios.create({
  baseURL: SANDBOX
    ? `https://lead.dalma.dev/${LATEST}`
    : `https://lead.dalma.tech/${PROD}`,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

const checkoutAPI = redaxios.create({
  baseURL: SANDBOX
    ? `https://checkout.dalma.dev/${LATEST}`
    : `https://checkout.dalma.tech/${PROD}`,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export function getQuoteId(quoteId = "", petIdx = 0) {
  return leadAPI.put("/quote", {
    step: "0",
    quote_id: quoteId,
    pet_idx: petIdx,
  });
}

export function sendUtmToBack(
  quoteId: string,
  utmParams: any,
  referrer: string
) {
  return leadAPI.post("/utm", {
    quote_id: quoteId,
    utm: utmParams,
    referrer: referrer,
  });
}

export function deletePet(quoteId: string, petIdx: number) {
  return leadAPI.delete("/pet", {
    data: {
      quote_id: quoteId,
      pet_idx: petIdx,
    },
  });
}

export function syncBack(data: any) {
  return leadAPI.post("/quote", toApi(data));
}

export async function fetchPrices(quoteId: string) {
  const { data } = await leadAPI.post("/pricing", {
    quote_id: quoteId,
    user_id: getUserId(),
  });
  return data.prices as {
    [key: number]: Required<Pet["pricing"]>;
  };
}

export function initDeferredSubscription(
  quoteId: string,
  email: string,
  paymentMethodId: string,
  customerId: string,
  petName: string
) {
  return checkoutAPI.post("/deferred-subscription", {
    quote_id: quoteId,
    email: email,
    payment_method: paymentMethodId,
    customer_id: customerId,
    pet_name: petName,
  });
}

export function createCustomerForDeferredSubscription(
  email: string,
  firstname: string,
  lastname: string,
  phoneNumber: string,
  paymentMethodId: string
) {
  return checkoutAPI.post("/create-customer", {
    type: "customer.create",
    email: email,
    firstname: firstname,
    lastname: lastname,
    phone: phoneNumber,
    payment_method: paymentMethodId,
  });
}

export function stripeCheckout(quoteId: string, pets: Pet[]) {
  const petIndexes = pets.map((p) => p.idx);
  return checkoutAPI
    .post("/create-checkout-session", {
      type: "checkout.create",
      quote_id: quoteId,
      metadata: {
        pets: petIndexes,
      },
    })
    .then((response: any) => {
      if (response.data.success) {
        redirectToCheckout({ sessionId: response.data.id });
      }
    });
}

export async function fetchQuoteInfo(quoteId: string) {
  const [
    {
      data: { success, data },
    },
    prices,
  ] = await Promise.all([
    leadAPI.get(`/quote?quote_id=${quoteId}`),
    fetchPrices(quoteId),
  ]);
  if (!success) {
    throw new Error("Error fetching quoteInfo");
  }
  const pets: Pet[] = [];
  let hasDeath = false;
  for (const pet of data) {
    const price = prices[pet.pet_idx]!;
    if (pet.options?.death === "yes") {
      hasDeath = true;
    }
    const options = {
      death: false,
      prevention: pet.options?.prevention === "yes",
    };
    const health_limit = pet.coverage ? pet.coverage.health_limit : "";
    const rate = pet.options ? pet.options.rate : "";
    const KEY = `${rate}-${health_limit}`;
    pets.push({
      name: pet.pet_name,
      type: pet.pet_type,
      breed: {
        type: "breed",
        breed: pet.pet_race,
      },
      uuid_type: pet.pet_uuid_type,
      uuid: pet.pet_uuid,
      draft: false,
      sex: pet.pet_sexe,
      birthdate: format(new Date(pet.pet_birthday), "yyyy-MM-dd"),
      idx: pet.pet_idx,
      subscriptionStartAt: pet.subscription_start_at || "asap",
      selectedFormula: price.prices[KEY]
        ? {
            health_limit,
            rate,
            ...options,
            price: price.prices[KEY].accident_sick,
            totalPrice: getPrice({
              price: price.prices[KEY].accident_sick,
              prices: price,
              death: options.death,
              prevention: options.prevention,
            }),
          }
        : undefined,
      pricing: price,
    });
  }
  if (hasDeath) {
    toast(
      "La garantie décès n’est plus disponible. Elle a été retirée de votre devis",
      { type: toast.TYPE.WARNING }
    );
  }
  const values: Data = {
    pets,
    owner: {
      firstName: data[0].owner_firstname,
      lastName: data[0].owner_lastname,
      phoneNumber: data[0].owner_phone,
      email: data[0].owner_email,
    },
    nextPetIdx: pets.reduce((acc, p) => (acc < p.idx ? p.idx : acc), 0) + 1,
    quoteId,
  };
  return values;
}
