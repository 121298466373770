import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { reportWebVitals } from "./reportWebVitals";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Quote } from "./pages/quote";
import { DalmaToastify } from "./components/dalma-toastify";
import {
  RedirectEmail,
  RedirectHyperassur,
  RedirectMarketing,
  RedirectMeilleurTaux,
} from "./pages/redirect";
import { Welcome } from "./pages/welcome";
import { DISABLE_BANNER, SANDBOX } from "./config";
import { Banner, Sandbox } from "./components/banner";

// Disable in local dev
Sentry.init({
  dsn: "https://bb2a9ced75344cd39df59957a479196b@o946967.ingest.sentry.io/6134624",
  release: "dalma-blue_signup@" + process.env.REACT_APP_SHA,
  integrations: [new Integrations.BrowserTracing()],
  enabled: process.env.NODE_ENV === "production",
  environment: SANDBOX ? "dev" : "production",

  // Set tracesSampleRate to capture 100% in production
  tracesSampleRate: SANDBOX ? 0 : 1,
});

function Fallback() {
  return null;
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<Fallback />}>
    <React.StrictMode>
      {SANDBOX ? <Sandbox /> : null}
      <Router>
        <Switch>
          <Route path="/quote">
            {DISABLE_BANNER ? null : <Banner />}
            <Quote />
          </Route>

          <Route exact path="/welcome/:quoteId">
            <Welcome />
          </Route>

          <Route exact path="/redirect/marketing">
            <RedirectMarketing />
          </Route>

          <Route exact path="/redirect/hyperassur">
            <RedirectHyperassur />
          </Route>

          <Route exact path="/redirect/meilleurtaux">
            <RedirectMeilleurTaux />
          </Route>

          <Route exact path="/redirect/email">
            <RedirectEmail />
          </Route>

          <Route>
            <Redirect to="/redirect/marketing" />
          </Route>
        </Switch>
      </Router>
      <DalmaToastify />
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
