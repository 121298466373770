import { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { css, theme } from "src/style/theme";
import { button, Button } from "./button";
import { Stack } from "./stack";
import dogSick from "./dogNotHealthy.png";

const error = css({
  padding: 20,
  background: theme.colors.lilac,
  height: "100%",
});

export function Error() {
  const [hasIntercom, setHasIntercom] = useState(false);

  useLayoutEffect(() => {
    // @ts-ignore
    if (window.Intercom) {
      setHasIntercom(true);
    }
  }, []);
  return (
    <div className={error()}>
      <Stack>
        <img
          width="500"
          height="500"
          src={dogSick}
          alt=""
          style={{ margin: "-10% auto", width: "50%", height: "auto" }}
        />

        <h1 style={{ fontFamily: "Roslindale", textAlign: "center" }}>
          Woops 404
        </h1>
        <p style={{ textAlign: "center" }}>
          Le devis renseigné n’existe pas ou a expiré
        </p>
        <div
          style={{
            flex: "none",
            display: "inline-block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Stack>
            <Link
              to="/redirect/marketing"
              className={button({ variant: "darkBlue" })}
            >
              Démarrer une nouvelle simulation
            </Link>
            {hasIntercom && (
              <Button
                type="button"
                onClick={() => {
                  // @ts-ignore
                  window.Intercom("show");
                }}
                variant="blue"
              >
                Chatter avec un agent
              </Button>
            )}
          </Stack>
        </div>
      </Stack>
    </div>
  );
}
