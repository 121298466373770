import { ComponentProps, forwardRef, ReactNode, Ref, useState } from "react";
import { useField } from "formik";
import { css, styled } from "@stitches/react";
import { theme } from "src/style/theme";

type SimpleInputProps = {
  label: string;
  rightIcon?: ReactNode;
  dropdown?: boolean;
} & ComponentProps<"input">;

type InputProps = {
  label: string;
  name: string;
} & ComponentProps<"input">;

const Container = styled("label", {
  display: "block",
  background: "#FFFFFF",
  border: "1px solid transparent",
  boxSizing: "border-box",
  width: "100%",
  borderRadius: "8px",
  position: "relative",
  padding: 15,
  zIndex: 0,

  "&::before": {
    position: "absolute",
    opacity: 1,
    transition: "opacity 0.3s ease",
    content: "",
    zIndex: -1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "inherit",
    boxShadow: "0 0 0 1px #E0E0E0",
  },

  "&::after": {
    position: "absolute",
    opacity: 0,
    transition: "opacity 0.3s ease",
    content: "",
    zIndex: -1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "inherit",
    boxShadow: `0 0 0 2px ${theme.colors.electric}`,
  },

  variants: {
    focus: {
      true: {
        "&::before": {
          opacity: 0,
        },
        "&::after": {
          opacity: 1,
        },
      },
    },
    dropdown: {
      true: {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    disabled: {
      true: {
        cursor: "not-allowed",
        color: "rgba(0, 0, 0, 0.38)",
      },
    },
  },
});

const Label = styled("div", {
  fontSize: "16px",
  lineHeight: "22px",
  position: "absolute",
  color: "#707070",
  transformOrigin: "top left",
  transition: "transform 0.3s ease",
  pointerEvents: "none",
  variants: {
    shifted: {
      true: {
        transform: "translateY(-12px) scale(calc(12 / 16))",
      },
    },
    disabled: {
      true: {
        color: "inherit",
      },
    },
  },
});

function webkitAutofillFix(color: string) {
  return {
    "&:-webkit-autofill": {
      WebkitTextFillColor: "inherit",
      boxShadow: `inset 0 0 0 999px ${color}`,
    },

    "&:-webkit-autofill::first-line": {
      fontSize: "16px",
    },

    "&:autofill": {
      WebkitTextFillColor: "inherit",
      boxShadow: `inset 0 0 0 999px ${color}`,
    },
  };
}

const input = css({
  border: "none",
  outline: 0,
  width: "100%",
  // Safari on iOS will zoom if input text is smaller than 16px
  fontSize: "16px",
  lineHeight: "22px",
  padding: 0,

  // HACK fix webkit browsers autofill overridding styles
  ...webkitAutofillFix("white"),

  "&:disabled": {
    color: "inherit",
  },

  variants: {
    rightIcon: {
      true: {
        paddingRight: 35,
      },
    },
    disabled: {
      true: {
        background: "none",
        cursor: "inherit",
        color: "inherit !important",
      },
    },
  },
});

export const select = css({
  appearance: "none",
  background: "none",
  border: "none",
  display: "block",
  width: "calc(100% + 30px)",
  margin: "-15px",
  padding: "15px",
  fontSize: "16px",
  lineHeight: "22px",
  color: "inherit",
  outline: "none",
  cursor: "pointer",
});

export const InputIcon = styled("div", {
  padding: 15,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
});

const RightInputIcon = styled("div", {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const SimpleInput = forwardRef(
  (
    {
      value = "",
      label,
      onFocus,
      onBlur,
      rightIcon,
      dropdown,
      ...props
    }: SimpleInputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    // Permet le focus (Affichage en bleu du cadre de l'input)
    const [focus, setFocus] = useState(false);

    // Permet de gérer l'affichage du label
    const empty = value === "" || value == null;

    return (
      <Container focus={focus} dropdown={dropdown} disabled={props.disabled}>
        <Label shifted={!empty || focus} disabled={props.disabled}>
          {label}
        </Label>
        <input
          ref={ref}
          {...props}
          className={`${input({
            rightIcon: rightIcon !== undefined,
            disabled: props.disabled,
          })} ${props.className ?? ""}`}
          value={value}
          onFocus={(e) => {
            setFocus(true);
            if (onFocus) {
              onFocus(e);
            }
          }}
          onBlur={(e) => {
            setFocus(false);
            if (onBlur) {
              onBlur(e);
            }
          }}
        />

        {rightIcon ? <RightInputIcon>{rightIcon}</RightInputIcon> : null}
      </Container>
    );
  }
);

export const Input = forwardRef(
  ({ name, label, type, ...props }: InputProps, ref: Ref<HTMLInputElement>) => {
    const [field] = useField({ name });
    return (
      <SimpleInput type={type} label={label} {...field} {...props} ref={ref} />
    );
  }
);

type SelectProps = {
  label: string;
  className?: string;
  name: string;
} & ComponentProps<"select">;

export function Select({
  label,
  className = "",
  children,
  name,
  onFocus,
  onBlur,
}: SelectProps) {
  const [field] = useField({ name });

  const value = field.value;

  const [focus, setFocus] = useState(false);

  // Permet de gérer l'affichage du label
  const empty = value.length === 0;

  return (
    <Container focus={focus}>
      <Label shifted={!empty || focus}>{label}</Label>
      <select
        className={`${select()} ${className}`}
        {...field}
        onFocus={(e) => {
          setFocus(true);
          if (onFocus) {
            onFocus(e);
          }
        }}
        onBlur={(e) => {
          setFocus(false);
          if (onBlur) {
            onBlur(e);
          }
        }}
      >
        <option value=""></option>
        {children}
      </select>
    </Container>
  );
}
