import { LocationState } from "src/types";

export const PET_NAME = "/quote/petName";
export const PET_INFOS = "/quote/petInformations";
export const PET_BIRTH = "/quote/petBirthdate";
export const PET_IDENTIFICATION = "/quote/petIdentification";

export const TRIBE_QUOTE = "/quote/tribeQuote";

export const USER_INFOS = "/quote/userInformations";
export const USER_ADDRESS = "/quote/userAddress";
export const USER_BIRTH = "/quote/userBirthInformations";

export const RECAP = "/quote/recap";

export type Location = {
  pathname: string;
  state?: LocationState;
};

export function petName(idx: number, fromTribeQuote?: boolean): Location {
  return {
    pathname: PET_NAME,
    state: {
      idx,
      fromTribeQuote,
    },
  };
}

export function petInfos(idx: number, fromTribeQuote?: boolean): Location {
  return {
    pathname: PET_INFOS,
    state: {
      idx,
      fromTribeQuote,
    },
  };
}

export function petBirth(idx: number, fromTribeQuote?: boolean): Location {
  return {
    pathname: PET_BIRTH,
    state: {
      idx,
      fromTribeQuote,
    },
  };
}

export function petIdentification(idx: number): Location {
  return { pathname: PET_IDENTIFICATION, state: { idx } };
}

export function tribeQuote(): Location {
  return { pathname: TRIBE_QUOTE };
}

export function userInfos(): Location {
  return { pathname: USER_INFOS };
}

export function userHomeAddress(): Location {
  return { pathname: USER_ADDRESS };
}

export function userBirthInfos(): Location {
  return { pathname: USER_BIRTH };
}

export function recap(): Location {
  return { pathname: RECAP };
}
