import { css, theme } from "src/style/theme";

const banner = css({
  color: theme.colors.white,
  background: "#4257CF",
  padding: "12px 24px",
  fontSize: 14,
  textAlign: "center",
  position: "relative",
  zIndex: 2,
});

export function Banner() {
  return (
    <a
      href="https://calendly.com/expert-dalma/tout-comprendre-sur-dalma-blue"
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: "none" }}
    >
      <div className={banner()}>
        Prenez rendez-vous avec nos experts pour comprendre si dalma.blue est
        adapté à votre situation {">"}{" "}
        <u style={{ textDecoration: "underline" }}>Je prends RDV</u> {"<"}
      </div>
    </a>
  );
}

const sandbox = css({
  position: "fixed",
  background: theme.colors.fire,
  color: theme.colors.white,
  padding: "8px 40px",
  transform: "translate(-47px, 15px) rotate(-45deg)",
  textTransform: "uppercase",
  zIndex: 10,
  opacity: 0.8,
});

export function Sandbox() {
  return <div className={sandbox()}>Sandbox</div>;
}
