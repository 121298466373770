import { styled, theme } from "src/style/theme";
import { displayPrice } from "./pet";

const Wrapper = styled("div", {
  borderRadius: 16,
  background: theme.colors.blueBackGround,
  display: "flex",
  justifyContent: "space-between",
  padding: "13px 16px",
  alignItems: "center",
  marginBottom: 10,
});

const Text = styled("div", {
  fontSize: 14,
  lineHeight: "22px",
});
const Pricing = styled("div", {
  fontFamily: "Roslindale",
  color: theme.colors.electric,
  fontSize: 18,
  lineHeight: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const PricingPeriod = styled("div", {
  fontSize: 10,
  lineHeight: "22px",
  fontFamily: "Grostesk",
  color: theme.colors.gray900,
  marginLeft: 4,
});

interface Props {
  totalPrice: number;
}
export function SubscriptionPricing({ totalPrice }: Props) {
  return (
    <Wrapper>
      <Text>Votre abonnement dalma.blue : </Text>
      <Pricing>
        {displayPrice(totalPrice)}€<PricingPeriod>/mois</PricingPeriod>
      </Pricing>
    </Wrapper>
  );
}
