import { Form, Formik, useField } from "formik";
import { SubmitButton } from "src/components/submit-button";
import { DalmaText } from "src/components/dalma-text";
import { InputRadio } from "src/components/input-radio";
import { InputType } from "src/components/input-type";
import { Pet } from "src/types";
import { Container } from "src/components/container";
import { Stack } from "src/components/stack";
import { Fragment } from "react";
import { SimpleInputCheckbox } from "src/components/input-checkbox";
import { Location } from "src/utils/url-builder";
import { Autocomplete, Item } from "src/components/autocomplete";
import { CAT_BREEDS, DOG_BREEDS } from "src/constants/breeds";

function BreedAutocomplete({
  name,
  values,
  label,
  disabled = false,
  onChange,
}: {
  name: string;
  values: any;
  label: string;
  disabled?: boolean;
  onChange?: (seletion: null | Item) => void;
}) {
  return (
    <Autocomplete
      name={name}
      items={values}
      label={label}
      disabled={disabled}
      onChange={onChange}
    />
  );
}

function DogBreed() {
  const [, meta, helpers] = useField("breed");

  return (
    <>
      <BreedAutocomplete
        name="breed.breed"
        values={[
          {
            key: "mixed_breed",
            label: "Croisé",
            accented: true,
          },
          ...DOG_BREEDS,
        ]}
        label="De quelle race ?"
        onChange={(e) => {
          if (e?.key === "mixed_breed") {
            helpers.setValue({ type: "mixed_breed", breed: "mixed_breed" });
          } else {
            helpers.setValue({ type: "breed", breed: e?.key ?? "" });
          }
        }}
        disabled={
          meta.value.type === "mixed_breed" || meta.value.type === "unknown"
        }
      />
      <div style={{ marginTop: 10 }}>
        <SimpleInputCheckbox
          checked={
            meta.value.type === "mixed_breed" || meta.value.type === "unknown"
          }
          onChange={(e) => {
            if (e.currentTarget.checked) {
              helpers.setValue({ type: "mixed_breed", breed: "mixed_breed" });
            } else {
              helpers.setValue({ type: "breed", breed: "" });
            }
          }}
        >
          C'est un croisé
        </SimpleInputCheckbox>
      </div>
      {["unknown", "mixed_breed"].includes(meta.value.type) ? (
        <>
          <DalmaText image>
            Pouvez-vous m'en dire plus sur ses parents ?
          </DalmaText>
          <BreedAutocomplete
            name="breed.mother_breed"
            values={DOG_BREEDS}
            label="Race de la mère"
            disabled={meta.value.type === "unknown"}
          />
          <BreedAutocomplete
            name="breed.father_breed"
            values={DOG_BREEDS}
            label="Race du père"
            disabled={meta.value.type === "unknown"}
          />
          <SimpleInputCheckbox
            checked={meta.value.type === "unknown"}
            onChange={(e) => {
              if (e.currentTarget.checked) {
                helpers.setValue({ type: "unknown", breed: "mixed_breed" });
              } else {
                helpers.setValue({ type: "mixed_breed", breed: "mixed_breed" });
              }
            }}
          >
            Je ne sais pas
          </SimpleInputCheckbox>
        </>
      ) : null}
      {meta.value.type === "unknown" ? (
        <>
          <DalmaText image>Quel est son poids ?</DalmaText>
          <InputRadio
            name="breed.weight"
            value="10kg"
            label="Petit (inférieur à 10kg)"
          />
          <InputRadio
            name="breed.weight"
            value="10-30kg"
            label="Moyen (entre 10 et 30kg)"
          />
          <InputRadio
            name="breed.weight"
            value="30-60kg"
            label="Grand (entre 30 et 60kg)"
          />
          <InputRadio
            name="breed.weight"
            value=">60kg"
            label="Très grand (supérieur à 60kg)"
          />
        </>
      ) : null}
    </>
  );
}

function CatBreed() {
  const [, meta, helpers] = useField("breed");
  return (
    <>
      <BreedAutocomplete
        name="breed.breed"
        values={[
          {
            key: "alley_cat",
            label: "Gouttière / inconnue",
          },
          ...CAT_BREEDS,
        ]}
        label="De quelle race ?"
        onChange={(e) => {
          helpers.setValue({ type: "breed", breed: e?.key ?? "" });
        }}
        disabled={meta.value.breed === "alley_cat"}
      />
      <div style={{ marginTop: 10 }}>
        <SimpleInputCheckbox
          checked={meta.value.breed === "alley_cat"}
          onChange={(e) => {
            if (e.currentTarget.checked) {
              helpers.setValue({ type: "breed", breed: "alley_cat" });
            } else {
              helpers.setValue({ type: "breed", breed: "" });
            }
          }}
        >
          Gouttière / inconnue
        </SimpleInputCheckbox>
      </div>
    </>
  );
}

export function PetBreed({ petType }: { petType: Pet["type"] }) {
  if (petType === "cat") {
    return <CatBreed />;
  }
  if (petType === "dog") {
    return <DogBreed />;
  }
  return null;
}

type Values = Pick<Pet, "type" | "breed" | "sex">;
type Errors = { type?: string; breed?: string; sex?: string };

function validateBreed(values: Values, errors: Errors) {
  // TODO: Handle unknown breed / weight
  if (values.type === "cat") {
    if (!values.breed?.breed) {
      errors.breed = "Veuillez selectionner la race de votre chat";
    }
  } else if (values.type === "dog") {
    const breed = values.breed;
    if (breed?.type === "mixed_breed") {
      // @ts-ignore TODO: We need to change ui types to make this better
      if (!values.breed?.father_breed || !values.breed?.mother_breed) {
        errors.breed =
          "Veuillez selectionner les races des parents de votre chien";
      }
    }
    if (breed?.type === "unknown" && !breed.weight) {
      errors.breed = "Veyillez indiquer le poid de votre animal";
    }
    if (!values.breed?.type) {
      errors.breed = "Veuillez selectionner la race de votre chien";
    }
  }
}

function validate(values: Values) {
  const errors: Errors = {};

  if (!values.type) {
    errors.type = "Veuillez selectionner un type d'animal";
  }

  validateBreed(values, errors);

  if (!values.sex) {
    errors.sex = "Veuillez selectionner le sexe de votre animal";
  }

  return errors;
}

type Props = Pick<Pet, "name" | "type" | "breed" | "sex"> & {
  nextStep: (values: Pick<Pet, "type" | "breed" | "sex">) => void;
  backLink: Location;
};

export function PetInformations({
  name,
  type,
  breed,
  sex,
  backLink,
  nextStep,
}: Props) {
  return (
    <Container backLink={backLink} stepPercentage={12.5}>
      <Formik
        initialValues={{ type, breed, sex }}
        validate={validate}
        validateOnMount
        onSubmit={nextStep}
      >
        {({ values, setFieldValue, isSubmitting, isValid }) => (
          <Form>
            <Stack direction="column">
              <DalmaText image>J’adore ce nom ! Et {name} est un...</DalmaText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  boxSizing: "border-box",
                  columnGap: "5%",
                }}
              >
                <InputType
                  onChange={() => {
                    setFieldValue("breed", {});
                  }}
                  name="type"
                  value="dog"
                >
                  Chien
                </InputType>
                <InputType
                  onChange={() => {
                    setFieldValue("breed", {});
                  }}
                  name="type"
                  value="cat"
                >
                  Chat
                </InputType>
              </div>
              {values.type ? (
                <Fragment key={values.type}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      boxSizing: "border-box",
                      columnGap: "5%",
                    }}
                  >
                    <InputRadio
                      name="sex"
                      value="female"
                      label="Femelle"
                      icon={
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.25 9.5C18.25 6.46 15.79 4 12.75 4C9.71 4 7.25 6.46 7.25 9.5C7.25 12.2 9.19 14.43 11.75 14.9V16.5C11.75 16.7761 11.5261 17 11.25 17H10.25C9.97386 17 9.75 17.2239 9.75 17.5V18.5C9.75 18.7761 9.97386 19 10.25 19H11.25C11.5261 19 11.75 19.2239 11.75 19.5V20.5C11.75 20.7761 11.9739 21 12.25 21H13.25C13.5261 21 13.75 20.7761 13.75 20.5V19.5C13.75 19.2239 13.9739 19 14.25 19H15.25C15.5261 19 15.75 18.7761 15.75 18.5V17.5C15.75 17.2239 15.5261 17 15.25 17H14.25C13.9739 17 13.75 16.7761 13.75 16.5V14.9C16.31 14.43 18.25 12.2 18.25 9.5ZM9.25 9.5C9.25 7.57 10.82 6 12.75 6C14.68 6 16.25 7.57 16.25 9.5C16.25 11.43 14.68 13 12.75 13C10.82 13 9.25 11.43 9.25 9.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      }
                    />
                    <InputRadio
                      name="sex"
                      value="male"
                      label="Mâle"
                      icon={
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.25 11C12.18 11 13.75 12.57 13.75 14.5C13.75 16.43 12.18 18 10.25 18C8.32 18 6.75 16.43 6.75 14.5C6.75 12.57 8.32 11 10.25 11ZM10.25 9C7.21 9 4.75 11.46 4.75 14.5C4.75 17.54 7.21 20 10.25 20C13.29 20 15.75 17.54 15.75 14.5C15.75 13.6373 15.5509 12.8243 15.1979 12.0982C14.9871 11.6647 15.0276 11.1331 15.3688 10.7927L18.75 7.42V10H20.75V5C20.75 4.44772 20.3023 4 19.75 4H14.75V6H17.33L13.9505 9.37951C13.6086 9.7214 13.0748 9.76012 12.6401 9.54842C11.92 9.19773 11.1097 9 10.25 9Z"
                            fill="currentColor"
                          />
                        </svg>
                      }
                    />
                  </div>
                  {values.sex ? <PetBreed petType={values.type} /> : null}
                  <SubmitButton
                    isLoading={isSubmitting}
                    isValid={isValid}
                    backLink={backLink}
                  >
                    Suivant
                  </SubmitButton>
                </Fragment>
              ) : null}
            </Stack>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
