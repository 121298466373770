import { createStitches } from "@stitches/react";

export const { theme, styled, css, keyframes } = createStitches({
  media: {
    bp1: "(min-width: 576px)",
    bp2: "(min-width: 1024px)",
  },
  theme: {
    colors: {
      white: "#FFFFFF",
      black: "#0B2214",

      lilac: "#B9C5FA",
      night500: "#030C6C",

      inputBlueBorder: "#C3D7F0",
      inputGreyBorder: "#DBEBFF",

      electric: "#4E68F6",
      electricOpacity: "rgba(78, 104, 246, 0.5)",
      fire: "#FE6F18",
      night: "#030C6C",

      blueBackGround: "#F1F7FF",
      greenBackGround: "#DDF0EA",
      orangeBackGround: "#FAE6E7",
      redBackGround: "#FAEBF7",
      yellowBackGround: "#FAEEE6",

      infoIconColor: "#688AB8",
      textColor: "#212121",

      green: "#00704F",

      blue200: "#DBEBFF",
      blue800: "#144283",

      gray400: "#BDBDBD",
      gray500: "#9E9E9E",
      gray600: "#757575",
      gray700: "#616161",
      gray800: "#424242",
      gray900: "#212121",

      pink: "#FEC6E3",
      red: "#e6787a",
    },
  },
});
