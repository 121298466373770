import { ReactElement, useCallback, useEffect, useLayoutEffect } from "react";
import { matchPath, Redirect, useHistory, useLocation } from "react-router";
import { useStorage } from "src/hooks/localstorage";
import {
  deletePet,
  fetchPrices,
  getQuoteId,
  syncBack,
} from "src/utils/backend";
import { PetBirthdate } from "./pet-birthdate";
import { PetInformations } from "./pet-informations";
import { PetName } from "./pet-name";
import * as urlBuilder from "src/utils/url-builder";

import { Data, Home, LocationState, Pet, User } from "src/types";
import { OwnerInfo } from "./owner-info";
import { TribeQuote } from "./tribe-quote";
import { PetIdentification } from "./pet-identification";
import { HomeAddress } from "./home-address";
import { Recap } from "./recap";
import { Loading } from "src/components/loading";
import { setTag } from "@sentry/minimal";

export const INIT: Data = {
  pets: [
    {
      idx: 0,
      draft: true,
    },
  ],
  nextPetIdx: 1,
  quoteId: "",
};

function dropPriceFields(
  pet: Pet,
  newPetData: Partial<Pet>,
  fields: Array<keyof Pet>
) {
  const { pricing, selectedFormula, ...filteredPet } = pet;
  for (const field of fields) {
    if (field in newPetData) {
      if (newPetData[field] !== pet[field]) {
        return filteredPet;
      }
    }
  }
  return pet;
}

function mergePetData(newPetData: Partial<Pet>, petIdx: number) {
  return (data: Data) => {
    const ret = {
      ...data,
      pets: data.pets.map((p) => {
        if (p.idx !== petIdx) {
          return p;
        }
        // if type or birthdate changed, drop selectedFormula and pricing
        const filteredPet = dropPriceFields(p, newPetData, [
          "type",
          "birthdate",
        ]);
        return { ...filteredPet, ...newPetData };
      }),
    };
    return ret;
  };
}

type PetWrapperProps = {
  pets: Pet[];
  idx: number;
  children: (pet: Pet, index: number) => ReactElement;
  fromTribeQuote?: boolean;
};

function PetWrapper({ pets, idx, children }: PetWrapperProps) {
  const petIndex = pets.findIndex((p) => p.idx === idx);
  const pet = pets[petIndex];
  const history = useHistory();
  useLayoutEffect(() => {
    if (!pet) {
      history.push(urlBuilder.tribeQuote());
    }
  }, [pet, history]);
  if (pet) {
    return children(pet, petIndex);
  }
  return null;
}

export function Quote() {
  const [data, setData] = useStorage<Data>("quote", INIT);
  const location = useLocation<LocationState>();
  const history = useHistory();
  const quoteId = data.quoteId;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const updatePrices = useCallback(() => {
    fetchPrices(quoteId).then((newPrices) => {
      setData((data) => ({
        ...data,
        pets: data.pets.map((p) => {
          if (newPrices[p.idx]) {
            return {
              ...p,
              pricing: newPrices[p.idx],
              selectedFormula: {
                rate: "100%",
                health_limit: "2000",
                price: newPrices[p.idx]!.prices["100%-2000"].accident_sick,
                totalPrice: newPrices[p.idx]!.prices["100%-2000"].accident_sick,
                prevention: false,
                death: false,
              },
            };
          }
          return p;
        }),
      }));
    });
  }, [quoteId, setData]);

  // Get quoteId
  useEffect(() => {
    getQuoteId(quoteId).then((response: any) => {
      const dealId = response.data.data.quote_id;
      setTag("quote_id", dealId);
      if (dealId !== quoteId) {
        setData((data) => ({ ...data, quoteId: dealId }));
      }
    });
  }, [quoteId, setData]);

  if (!quoteId) {
    return <Loading />;
  }

  if (matchPath(location.pathname, urlBuilder.PET_NAME)) {
    const { idx = 0, fromTribeQuote = false }: LocationState =
      location.state ?? {};
    return (
      <PetWrapper pets={data.pets} idx={idx} fromTribeQuote={fromTribeQuote}>
        {(currentPet, currentPetIndex) => (
          <PetName
            name={currentPet.name}
            index={currentPetIndex}
            nextStep={async (name) => {
              const newData = mergePetData({ name }, idx)(data);
              setData(newData);
              if (!currentPet.draft) {
                await syncBack(newData);
              }
              history.push(urlBuilder.petInfos(idx, fromTribeQuote));
            }}
          />
        )}
      </PetWrapper>
    );
  }

  if (matchPath(location.pathname, urlBuilder.PET_INFOS)) {
    const { idx = 0, fromTribeQuote = false }: LocationState =
      location.state ?? {};
    return (
      <PetWrapper idx={idx} pets={data.pets} fromTribeQuote={fromTribeQuote}>
        {(currentPet) => (
          <PetInformations
            backLink={urlBuilder.petName(idx, fromTribeQuote)}
            name={currentPet.name}
            type={currentPet.type}
            breed={currentPet.breed}
            sex={currentPet.sex}
            nextStep={async ({ type, breed, sex }: any) => {
              const newData = mergePetData({ type, breed, sex }, idx)(data);
              setData(newData);
              if (!currentPet.draft) {
                await syncBack(newData);
              }
              history.push(urlBuilder.petBirth(idx, fromTribeQuote));
            }}
          />
        )}
      </PetWrapper>
    );
  }

  if (matchPath(location.pathname, urlBuilder.PET_BIRTH)) {
    const { idx = 0, fromTribeQuote = false }: LocationState =
      location.state ?? {};
    return (
      <PetWrapper idx={idx} pets={data.pets} fromTribeQuote={fromTribeQuote}>
        {(currentPet) => (
          <PetBirthdate
            backLink={urlBuilder.petInfos(idx, fromTribeQuote)}
            petIdx={idx}
            type={currentPet.type}
            name={currentPet.name}
            birthdate={currentPet.birthdate}
            sex={currentPet.sex}
            nextStep={async (birthdate) => {
              const newData = mergePetData(
                { birthdate, draft: false },
                idx
              )(data);
              setData(newData);
              await getQuoteId(quoteId, idx);
              await syncBack(newData);
              history.push(urlBuilder.petIdentification(idx));
            }}
          />
        )}
      </PetWrapper>
    );
  }
  if (matchPath(location.pathname, urlBuilder.PET_IDENTIFICATION)) {
    const { idx = 0 }: LocationState = location.state ?? {};

    return (
      <PetWrapper idx={idx} pets={data.pets} fromTribeQuote>
        {(currentPet, currentPetIdx) => (
          <PetIdentification
            backLink={urlBuilder.petBirth(idx, false)}
            pets={data.pets}
            petIdx={idx}
            name={currentPet.name}
            sex={currentPet.sex}
            uuid={currentPet.uuid ? currentPet.uuid : ""}
            uuid_type={currentPet.uuid_type ? currentPet.uuid_type : ""}
            nextStep={async ({ uuid, uuid_type }) => {
              const newData = mergePetData({ uuid, uuid_type }, idx)(data);
              setData(newData);
              await syncBack(newData);
              history.push(urlBuilder.tribeQuote());
            }}
          />
        )}
      </PetWrapper>
    );
  }
  if (matchPath(location.pathname, urlBuilder.TRIBE_QUOTE)) {
    if (!data.pets.some((p) => !p.draft)) {
      return <Redirect to="/" />;
    }
    return (
      <TribeQuote
        backLink={
          data.pets.length
            ? urlBuilder.petIdentification(data.pets[data.pets.length - 1].idx)
            : undefined
        }
        quoteId={data.quoteId}
        pets={data.pets}
        updatePrices={updatePrices}
        editPet={(petIdx: number) => {
          history.push(urlBuilder.petName(petIdx, true));
        }}
        dropDrafts={() =>
          setData((data) => ({
            ...data,
            pets: data.pets.filter((p) => !p.draft),
          }))
        }
        deletePet={(petIdx: number) => {
          setData((data) => ({
            ...data,
            pets: data.pets.filter((pet) => pet.idx !== petIdx),
          }));
          deletePet(data.quoteId, petIdx);
        }}
        addPet={() => {
          setData((data) => ({
            ...data,
            pets: [...data.pets, { idx: data.nextPetIdx, draft: true }],
            nextPetIdx: data.nextPetIdx + 1,
          }));
          history.push(urlBuilder.petName(data.nextPetIdx, true));
        }}
        openPricing={(idx) => {}}
        nextStep={() => {
          history.push(urlBuilder.userInfos());
        }}
      />
    );
  }
  if (matchPath(location.pathname, urlBuilder.USER_INFOS)) {
    if (!data.pets.some((p) => !p.draft)) {
      return <Redirect to="/" />;
    }
    const petsName = data.pets.map((pet) => pet.name);

    return (
      <OwnerInfo
        backLink={urlBuilder.tribeQuote()}
        petsName={petsName}
        firstName={data.owner?.firstName}
        lastName={data.owner?.lastName}
        email={data.owner?.email}
        phoneNumber={data.owner?.phoneNumber}
        userCondition={data.owner?.userCondition}
        nextStep={async (ownerInfo: User) => {
          const newData: Data = {
            ...data,
            owner: { ...data.owner, ...ownerInfo },
          };
          setData(newData);
          await syncBack(newData);
          history.push(urlBuilder.userHomeAddress());
        }}
      />
    );
  }
  if (matchPath(location.pathname, urlBuilder.USER_ADDRESS)) {
    if (!data.pets.some((p) => !p.draft)) {
      return <Redirect to="/" />;
    }
    return (
      <HomeAddress
        backLink={urlBuilder.userInfos()}
        pets={data.pets}
        number={data.owner?.home?.number}
        address={data.owner?.home?.address}
        zipcode={data.owner?.home?.zipcode}
        city={data.owner?.home?.city}
        country={data.owner?.home?.country}
        nextStep={async (home: Home) => {
          const newOwner = { ...data.owner, home: home };
          const newData = { ...data, owner: newOwner };
          setData(newData);
          await syncBack(newData);
          history.push(urlBuilder.recap());
        }}
      />
    );
  }

  if (matchPath(location.pathname, urlBuilder.RECAP)) {
    if (!data.pets.some((p) => !p.draft)) {
      return <Redirect to="/" />;
    }
    return (
      <Recap
        backLink={urlBuilder.userHomeAddress()}
        data={data}
        editPet={(petIdx: number) => {
          history.push(urlBuilder.petName(petIdx, true));
        }}
        deletePet={(petIdx: number) => {
          setData((data) => ({
            ...data,
            pets: data.pets.filter((pet) => pet.idx !== petIdx),
          }));
          deletePet(data.quoteId, petIdx);
        }}
      />
    );
  }
  if (data.pets.length) {
    return <Redirect to={urlBuilder.petName(data.pets[0].idx)} />;
  } else {
    return <Redirect to={urlBuilder.tribeQuote()} />;
  }
}
