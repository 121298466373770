import { VariantProps } from "@stitches/react";
import { ReactNode, ComponentProps } from "react";
import { theme, keyframes, css, styled } from "src/style/theme";

export const rotate = keyframes({
  from: { transform: "translate3d(-50%, -50%, 0) rotate(0)" },
  to: { transform: "translate3d(-50%, -50%, 0) rotate(360deg)" },
});

export const button = css({
  appearance: "none",
  background: "transparent",
  position: "relative",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "15px 52px",
  cursor: "pointer",
  textDecoration: "none",

  /* Accent/Orange */

  color: "white",
  fontFamily: "inherit",

  /* Elevation 01 */
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",
  overflow: "hidden",

  variants: {
    smallPadding: {
      true: {
        paddingRight: 15,
        paddingLeft: 15,
      },
    },
    variant: {
      primary: {
        $$color: theme.colors.electric,
        background: "$$color",
        border: "1px solid $$color",
        color: theme.colors.white,
        fontSmooth: "never",
      },
      blue: {
        $$color: theme.colors.blue200,
        background: "$$color",
        border: "1px solid $$color",
        color: theme.colors.black,
        fontSmooth: "never",
        boxShadow: "none",
      },
      darkBlue: {
        $$color: theme.colors.electric,
        background: "$$color",
        border: "1px solid $$color",
        color: theme.colors.white,
        fontSmooth: "never",
        boxShadow: "none",
      },
      outlined: {
        background: "transparent",
        color: "#000000",
        border: "1px solid #E0E0E0",
        boxShadow: "none",
      },
    },
    fullWidth: {
      true: {
        width: "100%",
        "@bp1": {
          width: "unset",
        },
      },
    },
    isBold: {
      true: {
        fontWeight: 700,
      },
    },
    disabled: {
      true: {
        cursor: "not-allowed",
        $$color: "#EEEEEE",
        color: "#9E9E9E",
      },
    },

    isLoading: {
      true: {
        "&::after": {
          fontSize: 8,
          boxSizing: "border-box",
          content: "",
          $$size: "1em",
          display: "inline-block",
          width: "calc(2 * $$size)",
          height: "calc(2 * $$size)",
          borderRadius: "$$size",
          border: "2px solid currentColor",
          borderTopColor: "transparent",
          animation: `${rotate} 1s linear infinite`,
          transform: `translate3d(-50%, -50%, 0)`,
          willChange: "transform",
          transformOrigin: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
        },
      },
    },
  },
  defaultVariants: {
    variant: "primary",
  },

  compoundVariants: [
    {
      disabled: true,
      variant: "primary",
      css: {
        cursor: "not-allowed",
        $$color: "#EEEEEE",
        color: "#9E9E9E",
      },
    },
  ],
});

const ButtonText = styled("span", {
  fontSize: 14,
  lineHeight: "22px",
  variants: {
    isLoading: {
      true: {
        color: "transparent",
      },
    },
  },
});

type ButtonProps = {
  children: ReactNode;
  hasProgress?: boolean;
} & ComponentProps<"button"> &
  VariantProps<typeof button>;

export function Button({
  children,
  variant,
  fullWidth,
  isBold,
  isLoading,
  disabled,
  hasProgress,
  ...props
}: ButtonProps) {
  return (
    <button
      disabled={disabled}
      {...props}
      className={button({ variant, isLoading, fullWidth, disabled, isBold })}
    >
      {hasProgress ? <div className={loadingClass({ isLoading })} /> : null}
      <ButtonText isLoading={isLoading}>{children}</ButtonText>
    </button>
  );
}

const loadingBar = keyframes({
  "0%": {
    transform: "translateX(-100%)",
  },

  "100%": {
    transform: "translateX(-10%)",
  },
});

const loaded = keyframes({
  "1%": {
    transform: "translateX(-10%)",
    opacity: 1,
  },

  "50%": {
    transform: "translateX(0)",
    opacity: 1,
  },

  "100%": {
    transform: "translateX(0)",
    opacity: 0,
  },
});

const loadingClass = css({
  position: "absolute",
  height: "100%",
  left: 0,
  top: 0,
  borderRadius: "inherit",
  width: "calc(100%)",

  animation: `${loaded} 0.5s 1 ease-in-out`,
  animationFillMode: "both",
  background: "rgba(255,255,255,0.3)",
  transition: "transform 0.2s ease",
  variants: {
    isLoading: {
      true: {
        animation: `${loadingBar} 10s 1 ease-out`,
        animationFillMode: "",
      },
    },
  },
});

export const buttonIcon = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  lineHeight: "22px",
});

export const iconClass = css({
  marginRight: 17,
  "& > svg": {
    display: "block",
  },
});

export const contentClass = css({
  variants: {
    header: {
      true: {
        display: "none",
        "@bp1": {
          marginLeft: 10,
          display: "block",
        },
      },
    },
  },
});

export function ButtonIcon({
  icon,
  children,
  header = false,
}: {
  icon: ReactNode;
  children: ReactNode;
  header?: boolean;
}) {
  return (
    <div className={buttonIcon()}>
      <div className={iconClass({ header })}>{icon}</div>
      <div className={contentClass({ header })}>{children}</div>
    </div>
  );
}
