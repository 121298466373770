/**
 * LocalStorage utils
 *
 * // TODO in memory fallback
 */

export function setItem(key: string, value: string) {
  try {
    window.localStorage.setItem(key, value);
  } catch (error) {}
}

export function getItem(key: string) {
  try {
    return window.localStorage.getItem(key);
  } catch (error) {}
}

export function clear() {
  try {
    window.localStorage.clear();
  } catch (error) {}
}

export function removeItem(key: string) {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {}
}
