import { Container } from "src/components/container";
import { DalmaText } from "src/components/dalma-text";
import { Data, Pet as PetType, User } from "src/types";
import { LegalCard, LegalForm } from "src/components/StripeCard";
import { displayPrice, isPromotedPet, Pet, PetRow } from "src/components/pet";
import { stripeCheckout } from "src/utils/backend";
import { Form, Formik } from "formik";
import { Stack } from "src/components/stack";
import { Location } from "src/utils/url-builder";
import { getTotalPrice } from "./tribe-quote";
import { SubmitButton } from "src/components/submit-button";
import { DalmaInfo } from "src/components/dalma-info";

type Props = {
  data: Data;
  backLink: Location;
  editPet: any;
  deletePet: any;
};

function validateNonDeferred(values: LegalForm) {
  const errors: { contractAccepted?: string; contractDownloaded?: string } = {};
  if (!values.contractAccepted) {
    errors.contractAccepted = "Il faut cocher cette case";
  }
  if (!values.contractDownloaded) {
    errors.contractAccepted = "Il faut cocher cette case";
  }
  return errors;
}

function StripeCheckout({
  pets,
  quoteId,
  owner,
  backLink,
}: {
  pets: PetType[];
  quoteId: string;
  owner?: User;
  backLink: Location;
}) {
  return (
    <Formik
      initialValues={{ contractAccepted: false, contractDownloaded: false }}
      validate={validateNonDeferred}
      validateOnMount
      onSubmit={async () => await stripeCheckout(quoteId, pets)}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <Stack direction="column">
            <LegalCard />
            <SubmitButton
              isLoading={isSubmitting}
              isValid={isValid}
              backLink={backLink}
              subText="Sans engagement"
              hasProgress
            >
              Passer au paiement • {displayPrice(getTotalPrice(pets)!)}€
            </SubmitButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

export function Recap({ data, backLink, editPet, deletePet }: Props) {
  return (
    <Container backLink={backLink} stepPercentage={100}>
      <Stack direction="column">
        <DalmaText image>Tout est en place ! Voici un récapitulatif.</DalmaText>
        {data.pets.length === 1 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {data.pets.map((pet) => {
              const isPromoted = isPromotedPet(pet.idx, data.pets);
              return (
                <Pet
                  key={pet.idx}
                  pet={pet}
                  editPet={() => editPet(pet.idx)}
                  deletePet={() => deletePet(pet.idx)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        margin: "8px 0px 16px 0px",
                        display: "flex",
                        alignItems: "center",
                        lineHeight: "24px",
                      }}
                    >
                      {isPromoted ? (
                        <span
                          style={{
                            fontSize: 12,
                            lineHeight: "12px",
                            textDecorationLine: "line-through",
                            /* Grey/600 */
                            color: "#757575",
                            fontWeight: 700,
                          }}
                        >
                          {displayPrice(pet.selectedFormula!.totalPrice)}€
                        </span>
                      ) : null}
                      <p
                        style={{
                          fontSize: 25,
                          margin: "0px 0px 0px 6px",
                          color: "#4E68F6",
                          fontWeight: "bold",
                        }}
                      >
                        {displayPrice(
                          pet.selectedFormula!.totalPrice *
                            (isPromoted ? 0.9 : 1)
                        )}
                      </p>
                      <p
                        style={{
                          margin: 0,
                          color: "#4E68F6",
                          fontWeight: "bold",
                          fontSize: 15,
                        }}
                      >
                        €
                      </p>
                      <p
                        style={{
                          margin: 0,
                          fontWeight: "bold",
                          fontSize: 15,
                        }}
                      >
                        /mois
                      </p>
                      <span style={{ display: "inline-block", width: 8 }} />
                    </div>
                  </div>
                </Pet>
              );
            })}
          </div>
        ) : (
          <PetRow>
            {data.pets.map((pet) => {
              const isPromoted = isPromotedPet(pet.idx, data.pets);
              return (
                <Pet
                  key={pet.idx}
                  pet={pet}
                  editPet={() => editPet(pet.idx)}
                  deletePet={() => deletePet(pet.idx)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        margin: "8px 0px 16px 0px",
                        display: "flex",
                        alignItems: "center",
                        lineHeight: "24px",
                      }}
                    >
                      {isPromoted ? (
                        <span
                          style={{
                            fontSize: 12,
                            lineHeight: "12px",
                            textDecorationLine: "line-through",
                            /* Grey/600 */
                            color: "#757575",
                            fontWeight: 700,
                          }}
                        >
                          {displayPrice(pet.selectedFormula!.totalPrice)}€
                        </span>
                      ) : null}
                      <p
                        style={{
                          fontSize: 25,
                          margin: "0px 0px 0px 6px",
                          color: "#4E68F6",
                          fontWeight: "bold",
                        }}
                      >
                        {displayPrice(
                          pet.selectedFormula!.totalPrice *
                            (isPromoted ? 0.9 : 1)
                        )}
                      </p>
                      <p
                        style={{
                          margin: 0,
                          color: "#4E68F6",
                          fontWeight: "bold",
                          fontSize: 15,
                        }}
                      >
                        €
                      </p>
                      <p
                        style={{
                          margin: 0,
                          fontWeight: "bold",
                          fontSize: 15,
                        }}
                      >
                        /mois
                      </p>
                      <span style={{ display: "inline-block", width: 8 }} />
                    </div>
                  </div>
                </Pet>
              );
            })}
          </PetRow>
        )}
        <DalmaInfo>
          {data.pets[0].uuid_type === "later"
            ? `L'ajout de code promotion n'est pas disponible. Renseignez le n° d'identification de ${data.pets[0].name} pour bénéficier de la fonctionnalité.`
            : "Si vous disposez d'un code promotion, vous pourrez l'ajouter à l'étape suivante."}
        </DalmaInfo>

        <StripeCheckout
          pets={data.pets}
          quoteId={data.quoteId}
          owner={data.owner}
          backLink={backLink}
        />
      </Stack>
    </Container>
  );
}
