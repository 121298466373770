import { useState, useRef } from "react";
import { styled } from "@stitches/react";
import Downshift from "downshift";
import { useField } from "formik";
import { matchSorter } from "match-sorter";
import { InputIcon, SimpleInput } from "./input";
import { theme } from "src/style/theme";

function CrossIcon({ size = 20 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1746 10L16.4246 4.75834C16.5815 4.60142 16.6697 4.38859 16.6697 4.16667C16.6697 3.94475 16.5815 3.73192 16.4246 3.575C16.2677 3.41808 16.0549 3.32993 15.833 3.32993C15.611 3.32993 15.3982 3.41808 15.2413 3.575L9.99962 8.825L4.75796 3.575C4.60104 3.41808 4.38821 3.32993 4.16629 3.32993C3.94437 3.32993 3.73154 3.41808 3.57462 3.575C3.4177 3.73192 3.32955 3.94475 3.32955 4.16667C3.32955 4.38859 3.4177 4.60142 3.57462 4.75834L8.82462 10L3.57462 15.2417C3.49652 15.3191 3.43452 15.4113 3.39221 15.5129C3.34991 15.6144 3.32812 15.7233 3.32812 15.8333C3.32812 15.9433 3.34991 16.0523 3.39221 16.1538C3.43452 16.2554 3.49652 16.3475 3.57462 16.425C3.65209 16.5031 3.74426 16.5651 3.84581 16.6074C3.94736 16.6497 4.05628 16.6715 4.16629 16.6715C4.2763 16.6715 4.38522 16.6497 4.48677 16.6074C4.58832 16.5651 4.68049 16.5031 4.75796 16.425L9.99962 11.175L15.2413 16.425C15.3188 16.5031 15.4109 16.5651 15.5125 16.6074C15.614 16.6497 15.7229 16.6715 15.833 16.6715C15.943 16.6715 16.0519 16.6497 16.1534 16.6074C16.255 16.5651 16.3472 16.5031 16.4246 16.425C16.5027 16.3475 16.5647 16.2554 16.607 16.1538C16.6493 16.0523 16.6711 15.9433 16.6711 15.8333C16.6711 15.7233 16.6493 15.6144 16.607 15.5129C16.5647 15.4113 16.5027 15.3191 16.4246 15.2417L11.1746 10Z"
        fill="#9E9E9E"
      />
    </svg>
  );
}

export type Item = {
  key: string;
  label: string;
  accented?: boolean;
};

type Props = {
  items: Item[];
  label: string;
  name: string;
  disabled?: boolean;
  onChange?: (selection: Item | null) => void;
};

const Ul = styled("ul", {
  position: "absolute",
  zIndex: 1,
  listStyleType: "none",
  background: "white",
  top: "100%",
  left: -1,
  right: -1,
  margin: 0,
  padding: 0,
  border: `2px solid ${theme.colors.electric}`,
  borderTopWidth: 1,
  borderRadius: "0px 0px 8px 8px",
  maxHeight: 200,
  overflowY: "auto",
  scrollbarColor: `${theme.colors.electric} transparent`,
  display: "none",

  variants: {
    isOpen: {
      true: {
        display: "initial",
      },
    },
  },

  "&::-webkit-scrollbar": {
    width: 15,
  },
  "&::-webkit-scrollbar-track": {
    borderBottomRightRadius: 8,
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.colors.electricOpacity,
    borderRadius: 9999,
    border: "5px solid rgba(255,0,0,0)",
    minHeight: 30,
    backgroundClip: "content-box",
  },
});

const Li = styled("li", {
  backgroundColor: "#fff",
  lineHeight: "22px",
  borderRadius: "0px 0px 8px 8px",
  display: "flex",
  alignItems: "center",
  padding: "5px 10px",

  variants: {
    isHighlighted: {
      true: {
        backgroundColor: theme.colors.blueBackGround,
      },
    },
    isAccented: {
      true: {
        color: theme.colors.electric,
      },
    },
    isSelected: {
      true: {
        fontWeight: "bold",
      },
    },
    isPointer: {
      true: {
        cursor: "pointer",
      },
    },
  },
});

export function Autocomplete(props: Props) {
  const [searchList, setSearchList] = useState<Item[]>(props.items);
  const [, meta, helper] = useField({ name: props.name });
  const inputRef = useRef<HTMLInputElement>(null);

  const { value } = meta;
  const { setValue } = helper;

  const onSearch = (value: string) => {
    setSearchList(matchSorter<Item>(props.items, value, { keys: ["label"] }));
  };

  const selectedItem = props.items.find((item) => item.key === value) ?? null;

  return (
    <Downshift
      selectedItem={selectedItem}
      onChange={(selection) => {
        setValue(selection && selection.key);
        if (typeof props.onChange === "function") {
          props.onChange(selection);
        }
        // HACK: Blur input when item selected
        const input = inputRef.current;
        if (selection !== null && input) {
          input.blur();
        }
      }}
      itemToString={(item) => (item ? item.label : "")}
      onInputValueChange={onSearch}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        selectedItem,
        clearSelection,
        inputValue,
        openMenu,
      }) => {
        return (
          <div style={{ position: "relative" }}>
            <SimpleInput
              {...getInputProps({
                ref: inputRef,
                label: props.label,
                dropdown: isOpen,
                disabled: props.disabled,
                onFocus() {
                  openMenu();
                },

                rightIcon: inputValue ? (
                  <InputIcon onClick={() => clearSelection()}>
                    <CrossIcon />
                  </InputIcon>
                ) : null,
              })}
            />

            <Ul {...getMenuProps()} isOpen={isOpen}>
              {isOpen ? (
                searchList.length === 0 ? (
                  <Li>Aucun résultat</Li>
                ) : (
                  searchList.map((item, index) => (
                    <Li
                      isHighlighted={highlightedIndex === index}
                      isAccented={item.accented}
                      isSelected={selectedItem === item}
                      isPointer={true}
                      {...getItemProps({
                        key: item.key,
                        index,
                        item,
                      })}
                    >
                      {item.label}
                    </Li>
                  ))
                )
              ) : null}
            </Ul>
          </div>
        );
      }}
    </Downshift>
  );
}
