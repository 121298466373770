import { legal } from "src/config";
import { InputCheckbox } from "./input-checkbox";

import { Stack } from "./stack";

export type LegalForm = {
  contractAccepted: false;
  contractDownloaded: false;
};

export function LegalCard() {
  return (
    <div
      style={{
        padding: 20,
        backgroundColor: "#F5F5F5",
        borderRadius: 8,
      }}
    >
      <Stack>
        <InputCheckbox name="contractAccepted">
          <p style={{ color: "#616161", fontSize: 12, margin: 0 }}>
            En réglant par carte bancaire, vous acceptez que dalma.blue et
            Stripe traitent vos données bancaires à des fins de gestion de votre
            contrat, tel que défini dans notre{" "}
            <a
              href={legal.v2.policy}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#4D68F6",
                textDecoration: "underline",
              }}
            >
              Politique de confidentialité et de cookies
            </a>
            {" et vous acceptez les "}
            <a
              href={legal.v1.cgu}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#4D68F6",
                textDecoration: "underline",
              }}
            >
              CGU de dalma.blue
            </a>{" "}
            et de{" "}
            <a
              href="https://stripe.com/fr/privacy"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#4D68F6",
                textDecoration: "underline",
              }}
            >
              Stripe
            </a>{" "}
            *
          </p>
        </InputCheckbox>
        <InputCheckbox name="contractDownloaded">
          <p style={{ color: "#616161", fontSize: 12, margin: 0 }}>
            J’ai bien pris connaissances et téléchargé les{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={legal.v1.cgu}
              style={{
                color: "#4D68F6",
                textDecoration: "underline",
              }}
            >
              conditions générales
            </a>{" "}
            et la fiche{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={legal.v2.ipid}
              style={{
                color: "#4D68F6",
                textDecoration: "underline",
              }}
            >
              IPID
            </a>
            . *
          </p>
        </InputCheckbox>
      </Stack>
    </div>
  );
}
