import React from "react";

type Props = {
  href: string;
  title: string;
  description: string;
};

export const DownloadButton = ({ href, title, description }: Props) => {
  return (
    <a
      download
      rel="noopener noreferrer"
      target="_blank"
      href={href}
      style={{ textDecoration: "none", margin: 5 }}
    >
      <div
        style={{
          border: "1px solid #E0E0E0",
          padding: 5,
          borderRadius: 5,
          width: 360,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{ padding: 15, backgroundColor: "#DBEBFF", borderRadius: 5 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="#4E68F6"
            >
              <path d="M11.362 2c4.156 0 2.638 6 2.638 6s6-1.65 6 2.457v11.543h-16v-20h7.362zm.827-2h-10.189v24h20v-14.386c0-2.391-6.648-9.614-9.811-9.614zm4.811 13h-3v-1h3v1zm0 2h-3v1h3v-1zm0 3h-10v1h10v-1zm-5-6h-5v4h5v-4z" />
            </svg>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginLeft: 15,
            }}
          >
            <p
              style={{
                margin: 0,
                color: "#000000",
                fontSize: 14,
                marginBottom: 4,
              }}
            >
              {title}
            </p>
            <p style={{ margin: 0, color: "gray", fontSize: 12 }}>
              {description}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0px 10px 0px 5px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#616161"
          >
            <path d="M8.29,13.29a1,1,0,0,0,0,1.42l3,3a1,1,0,0,0,1.42,0l3-3a1,1,0,0,0-1.42-1.42L13,14.59V3a1,1,0,0,0-2,0V14.59l-1.29-1.3A1,1,0,0,0,8.29,13.29ZM18,9H16a1,1,0,0,0,0,2h2a1,1,0,0,1,1,1v7a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H8A1,1,0,0,0,8,9H6a3,3,0,0,0-3,3v7a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V12A3,3,0,0,0,18,9Z"></path>
          </svg>
        </div>
      </div>
    </a>
  );
};
