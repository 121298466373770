import { css, theme } from "src/style/theme";
import { rotate } from "./button";

const loading = css({
  fontSize: 30,
  boxSizing: "border-box",
  content: "",
  $$size: "1em",
  display: "inline-block",
  width: "calc(2 * $$size)",
  height: "calc(2 * $$size)",
  borderRadius: "$$size",
  color: theme.colors.electric,
  border: "5px solid currentColor",
  borderTopColor: "transparent",
  animation: `${rotate} 1s linear infinite`,
  transform: `translate3d(-50%, -50%, 0)`,
  willChange: "transform",
  transformOrigin: "center",
  position: "fixed",
  top: "50%",
  left: "50%",
});

export function Loading() {
  return <div className={loading()} />;
}
