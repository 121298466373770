import { setUser } from "@sentry/minimal";
import { Data, Home, User, Breed } from "src/types";

function isValidAddress(address: Home | undefined): address is Home {
  if (!address) {
    return false;
  }
  return [address.number, address.address, address.zipcode, address.city].every(
    Boolean
  );
}

function isValidOwner(owner: User | undefined): owner is User {
  if (!owner) {
    return false;
  }
  return [
    owner.email,
    owner.firstName,
    owner.lastName,
    owner.phoneNumber,
  ].every(Boolean);
}

function breedToBack(breed: Breed) {
  if (breed.type === "breed") {
    return {
      race: breed.breed,
    };
  }
  if (breed.type === "mixed_breed") {
    return {
      race: "mixed_breed",
      father_race: breed.father_breed,
      mother_race: breed.mother_breed,
    };
  }
  if (breed.type === "unknown") {
    return {
      race: "mixed_breed",
      father_race: "unknown",
      mother_race: "unknown",
      weight: breed.weight,
    };
  }
}

export function getUserId() {
  let userId = localStorage.getItem("userId");
  setUser({ id: userId ?? undefined });
  if (!userId) {
    userId = "DAL_" + Math.random().toString(36).substr(2, 4);
    userId += "_" + Date.now();
    localStorage.setItem("userId", userId);
  }
  return userId;
}

export function toApi({ quoteId, owner, pets, subscriptionStartAt }: Data) {
  // console.log("user_id :: ", getUserId());
  return {
    meta: {
      quote_id: quoteId,
      user_id: getUserId(),
      referrer: "",
    },
    pets: pets.map((p) => ({
      name: p.name,
      type: p.type ? p.type : undefined,
      sexe: p.sex ? p.sex.toUpperCase() : undefined,
      idx: p.idx,
      birthday: p.birthdate ? p.birthdate : undefined,
      breed: p.breed ? breedToBack(p.breed) : undefined,
      health: p.health
        ? {
            status: p.health,
            old_diseases:
              p.health === "not_healthy"
                ? (p.oldDiseases || [])
                    .map((disease: any) =>
                      disease === "Autre" ? p.otherDiseases : disease
                    )
                    .filter(Boolean)
                : undefined,
          }
        : undefined,

      uuid: p.uuid || undefined,
      uuid_type: (p.uuid_type || "").includes("later")
        ? "later"
        : p.uuid_type || undefined,

      meta: {
        live_with_other:
          p.otherPets === "yes" ? true : !p.otherPets ? undefined : false,
        subscription_start_at: subscriptionStartAt,
      },
      pricing: p.selectedFormula
        ? {
            accepted_formula: "formula_dalmaBlue",
            options: {
              // @todo : add in formik
              pack: "accident_sick",
              rate: p.selectedFormula.rate,
              liability: "",
              prevention: p.selectedFormula.prevention,
              death: p.selectedFormula.death,
            },
            coverage: {
              health_limit: p.selectedFormula.health_limit,
            },
          }
        : undefined,
    })),
    owner: isValidOwner(owner)
      ? {
          email: owner.email || undefined,
          firstname: owner.firstName || undefined,
          lastname: owner.lastName || undefined,
          phone: owner.phoneNumber || undefined,

          birthday: owner.birthInfos?.date || undefined,
          birthcity: owner.birthInfos?.city || undefined,
          address: isValidAddress(owner.home)
            ? {
                street_nb: owner.home?.number || undefined,
                street_name: owner.home?.address || undefined,
                zipcode: owner.home?.zipcode || undefined,
                city: owner.home?.city || undefined,
                country: "FRANCE",
              }
            : undefined,
        }
      : undefined,
  };
}
